import { Box, Card, CardContent, Divider, Grid, Link, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

import {
  CustomSaveButton,
  DialingCodeAdaper,
  FormLayout,
  PasswordSafetyIndicator,
  TextFieldAdapter,
} from '..'
import { ROUTES } from '../../config'
import { usePrevious } from '../../lib/miscellaneous'
import { useTranslate } from '../../lib/translate'
import useSnackbar from '../../lib/useSnackbar'
import {
  required,
  validateConfirmPassword,
  validatePassword,
  validatePhoneNumber,
} from '../../lib/validation'
import { fetchFinishRegisterActions } from '../../redux/actions'
import {
  getBackendFinishRegisterError,
  getBackendUserData,
  isBackendFinishRegisterFetching,
} from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  card: {
    margin: `${theme.spacing(3.75)}px auto`,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  alignButtonRight: {
    textAlign: 'right',
  },
  passwordSafetyContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '25% auto',
    marginBottom: theme.spacing(2),
  },
}))

const FinishRegisterNewCustomerPage = ({ finishRegister, isFetching, error, userData }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const showSnackbar = useSnackbar()
  const wasPreviouslyFetching = usePrevious(isFetching)
  const isPhoneValidated = userData?.X2FA_validationTimestamp ?? false

  const onSubmit = ({ dialCode, telNumber, password }) => {
    const phone = get(dialCode, 'dial_code').concat(telNumber)
    finishRegister({
      phone,
      history,
      registerNewCustomer: true,
      password,
    })
  }

  useEffect(() => {
    if (wasPreviouslyFetching && !isFetching && error) {
      showSnackbar(error, 'error')
    }
  }, [isFetching, error, wasPreviouslyFetching, showSnackbar])

  return (
    <FormLayout>
      <Box ml={5}>
        <Typography variant="h6" gutterBottom className={classes.textBold}>
          {translate('authNumberPage.title')}
        </Typography>
      </Box>
      {!isPhoneValidated ? (
        <Form
          onSubmit={onSubmit}
          validate={userData?.createdByClerk && validateConfirmPassword}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant="subtitle2" paragraph>
                {translate('authNumberPage.subtitle')}
              </Typography>
              <Card className={classes.card}>
                <CardContent className={classes.cardContentPadding}>
                  <Typography variant="body2" paragraph>
                    {translate('authNumberPage.body')}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Field
                        name="dialCode"
                        component={DialingCodeAdaper}
                        validate={required}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Field
                        name="telNumber"
                        component={TextFieldAdapter}
                        validate={validatePhoneNumber}
                        type="tel"
                        label={translate('formFields.phone')}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {userData?.createdByClerk && (
                <Card className={classes.card}>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="body2" paragraph>
                      {translate('authNumberPage.setPwInfo')}
                    </Typography>
                    <Field
                      name="password"
                      component={TextFieldAdapter}
                      validate={validatePassword}
                      fullWidth
                      type="password"
                      autoComplete="new-password"
                      label={translate('formFields.pass')}
                    />
                    <Field
                      name="confirmPassword"
                      component={TextFieldAdapter}
                      fullWidth
                      type="password"
                      autoComplete="new-password"
                      label={translate('formFields.confirmPassword')}
                    />
                    <Box className={classes.passwordSafetyContainer}>
                      <Typography variant="subtitle2">{translate('register.security')}</Typography>
                      <PasswordSafetyIndicator password={get(values, 'password')} />
                    </Box>
                    <Typography variant="caption" color="textSecondary">
                      {translate('register.passwordHint')}
                    </Typography>
                  </CardContent>
                </Card>
              )}
              <Box className={classes.alignButtonRight}>
                <CustomSaveButton loading={isFetching}>
                  {translate('actions.save')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      ) : (
        <>
          <Card className={classes.card}>
            <CardContent className={classes.cardContentPadding}>
              <Typography variant="body2" paragraph>
                {translate('authNumberPage.alreadyVerified')}
              </Typography>
            </CardContent>
          </Card>
          <Box className={classes.bottomMargin}>
            <Button color="primary" component={RouterLink} to={ROUTES.LOGIN}>
              {translate('finishRegistration.backToLogin')}
            </Button>
          </Box>
        </>
      )}
      <Box my={2}>
        <Divider />
        <Box mt={1} ml={1}>
          <Link color="textSecondary" href={ROUTES.IMPRESSUM} target="__blank">
            {translate('drawer.drawerLink.legalInfo')}
          </Link>
        </Box>
      </Box>
    </FormLayout>
  )
}

FinishRegisterNewCustomerPage.propTypes = {
  finishRegister: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => ({
  isFetching: isBackendFinishRegisterFetching(state),
  error: getBackendFinishRegisterError(state),
  userData: getBackendUserData(state),
})

const mapDispatchToProps = {
  finishRegister: fetchFinishRegisterActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishRegisterNewCustomerPage)
