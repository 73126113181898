import get from 'lodash/get'

/**
 * Create Plan Selectors
 */
export const getFormCreatePlanPageData = page => state => get(state, `form.createPlan.${page}`, {})
export const getFormCreatePlanData = state => get(state, 'form.createPlan', {})

/**
 * Cancel Plan Selectors
 */
export const getFormCancelPlanPageData = page => state => get(state, `form.cancelPlan.${page}`)
export const getFormCancelPlanData = state => get(state, 'form.cancelPlan', {})

/**
 * Request Payout Selectors
 */
export const getFormRequestPayoutPageData = page => state =>
  get(state, `form.requestPayout.${page}`)
export const getFormRequestPayoutData = state => get(state, 'form.requestPayout', {})

/**
 * Create Deposit Selectors
 */
export const getFormCreateDepositPageData = page => state =>
  get(state, `form.createDeposit.${page}`, {})
export const getFormCreateDepositData = state => get(state, 'form.createDeposit', {})

/**
 * Plan Adjustment Selectors
 */
export const getFormPlanAdjustmentPageData = page => state =>
  get(state, `form.planAdjustment.${page}`, {})
export const getFormPlanAdjustmentData = state => get(state, 'form.planAdjustment')
