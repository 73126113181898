import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  AddressAutocompleteAdapter,
  CheckboxAdapter,
  CreatePlanRequestControlDialog,
  CustomSaveButton,
  DatePickerAdapter,
  FormLayout,
  NationalitiesAdapter,
  RadioAdapter,
  TextFieldAdapter,
  VoucherCard,
} from '../..'
import { BOOLEANS, GOOGLE_TAG_MANAGER, ROUTES } from '../../../config'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import useDialog from '../../../lib/customHooks/useDialog'
import { transformCreateConsentRequest } from '../../../lib/transform'
import { useTranslate } from '../../../lib/translate'
import { required, validateCountry, validateDate, validateEmail } from '../../../lib/validation'
import {
  fetchCreateConsentSavingsPlanActions,
  fetchSendSecurityCodeActions,
  storeCreatePlanValues,
} from '../../../redux/actions'
import {
  getBackendCountryDepartmentData,
  getBackendUserData,
  getFormCreatePlanData,
  isBackendCreatePlanFetching,
} from '../../../redux/selectors'
import InfoDialog from '../../common/InfoDialog'
import LegalLinksNoLoginForm from '../../common/LegaLinksNoLoginForm'

export const WORKFLOW_PAGE = 'pageFour'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  infoText: {
    marginLeft: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  infoIcon: {
    fontSize: 18,
  },
  bgbLoadingIndicator: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}))

const PersonalDataForm = ({ autofillAddress }) => {
  const translate = useTranslate()

  return (
    <>
      <Field
        name="firstName"
        component={TextFieldAdapter}
        validate={required}
        fullWidth
        label={translate('formFields.firstName')}
      />

      <Field
        name="lastName"
        component={TextFieldAdapter}
        validate={required}
        fullWidth
        label={translate('formFields.lastName')}
      />

      <Field
        name="dateOfBirth"
        component={DatePickerAdapter}
        openTo="year"
        validate={validateDate}
        fullWidth
        label={translate('formFields.dateOfBirth')}
      />

      <Field name="nationality" component={NationalitiesAdapter} validate={required} fullWidth />

      <Field
        name="street"
        component={AddressAutocompleteAdapter}
        formMutator={autofillAddress}
        validate={required}
        fullWidth
      />

      <Field
        name="postalCode"
        component={TextFieldAdapter}
        validate={required}
        fullWidth
        label={translate('formFields.postalCodeShort')}
      />

      <Field
        name="city"
        component={TextFieldAdapter}
        validate={required}
        fullWidth
        label={translate('formFields.place')}
      />

      <Field
        name="country"
        component={NationalitiesAdapter}
        isCountryPicker
        validate={validateCountry}
        fullWidth
        label={translate('formFields.country')}
      />

      <Field
        name="email"
        component={TextFieldAdapter}
        validate={validateEmail}
        fullWidth
        autoComplete="username"
        label={translate('formFields.mail')}
      />
    </>
  )
}

const ContractorFormCH = ({ noLogin, autofillAddress, beneficialOwner, isPoliticallyExpose }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const countryDepartment = useSelector(getBackendCountryDepartmentData)

  const [infoDialogContent, setInfoDialogContent] = useState()

  const handleOpenInfoDialog = (title, infoText) => {
    setInfoDialogContent({ title, infoText })
  }

  const handleCloseInfoDialog = () => {
    setInfoDialogContent(null)
  }

  const CopyReceivedLabel = (
    <Typography>
      {translate('formFields.checkboxes.copyReceived1')}
      {noLogin && (
        <>
          <Link to={`${ROUTES.TOS}?country=${countryDepartment?.countryCode}`} target="_blank">
            {translate('formFields.checkboxes.tos')}
          </Link>
          {', '}
        </>
      )}
      <Link to={`${ROUTES.BGB}?country=${countryDepartment?.countryCode}`} target="_blank">
        {translate('formFields.checkboxes.bgb')}
      </Link>
      {', '}
      <Link
        to={`${ROUTES.PRIVACY_POLICY}?country=${countryDepartment?.countryCode}`}
        target="_blank"
      >
        {translate('formFields.checkboxes.privacyPolicy')}
      </Link>
      {` ${translate('formFields.checkboxes.copyReceived2')}`}
    </Typography>
  )

  return (
    <Box mb={3}>
      <Card>
        <CardContent className={classes.cardContentPadding}>
          <Box mb={3}>
            <FormControl>
              <Typography variant="body1" className={classes.boldText}>
                {translate('createSavingsPlan.isBeneficialOwner')}
              </Typography>
              <Field name="beneficialOwner" component={RadioAdapter} validate={required}>
                <FormControlLabel
                  value={BOOLEANS.TRUE}
                  control={<Radio />}
                  label={
                    <Typography variant="body1" className={classes.boldText}>
                      {translate('common.yes')}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={BOOLEANS.FALSE}
                  control={<Radio />}
                  label={
                    <Typography variant="body1" className={classes.boldText}>
                      {translate('common.no')}
                    </Typography>
                  }
                />
              </Field>
            </FormControl>
            <Collapse in={beneficialOwner === BOOLEANS.FALSE} unmountOnExit>
              <Box mb={3}>
                <Typography gutterBottom>
                  {translate('createSavingsPlan.completeSecondPersonData')}
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleOpenInfoDialog(
                        translate('createSavingsPlan.additionalBeneficialOwnersTitle'),
                        translate('createSavingsPlan.additionalBeneficialOwnersInfo')
                      )
                    }
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
                  </IconButton>
                </Typography>
                <PersonalDataForm autofillAddress={autofillAddress} />
              </Box>
            </Collapse>
          </Box>
          <Box mb={3}>
            <FormControl>
              <Typography variant="body1" className={classes.boldText}>
                {translate('formFields.checkboxes.noPoliticallyExpose')}
                <IconButton
                  size="small"
                  onClick={() =>
                    handleOpenInfoDialog(
                      translate('createSavingsPlan.pepInfoTitle'),
                      translate('createSavingsPlan.pepInfo')
                    )
                  }
                >
                  <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
                </IconButton>
              </Typography>
              <Field name="isPoliticallyExpose" component={RadioAdapter} validate={required}>
                <FormControlLabel
                  value={BOOLEANS.TRUE}
                  control={<Radio />}
                  label={
                    <Typography variant="body1" className={classes.boldText}>
                      {translate('common.yes')}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={BOOLEANS.FALSE}
                  control={<Radio />}
                  label={
                    <Typography variant="body1" className={classes.boldText}>
                      {translate('common.no')}
                    </Typography>
                  }
                />
              </Field>
            </FormControl>
          </Box>
          <Collapse in={isPoliticallyExpose === BOOLEANS.TRUE} unmountOnExit>
            <Box mb={3} display="flex" flexDirection="column">
              <>
                <Typography variant="body2" className={classes.red} paragraph>
                  {translate('createSavingsPlan.politicallyExponatedPersonDialogText')}
                </Typography>
                <Field
                  name="pepOffice"
                  fullWidth
                  component={TextFieldAdapter}
                  validate={required}
                  label="Politisches Amt"
                  variant="outlined"
                />
              </>
            </Box>
          </Collapse>
          <Box mb={3} display="flex" flexDirection="column">
            <Field
              name="copyReceived"
              component={CheckboxAdapter}
              validate={required}
              label={CopyReceivedLabel}
            />
            <Box ml={4}>
              <Typography variant="caption" color="textSecondary">
                {translate('createSavingsPlan.receiveCopyInfo')}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <InfoDialog
        open={!!infoDialogContent}
        onClose={handleCloseInfoDialog}
        {...infoDialogContent}
      />
    </Box>
  )
}

const CreateSavingsPlanPageFour = ({
  createPlanValues,
  createConsentSavingsPlan,
  isFetching,
  storeValues,
  noLogin,
  sendSecurityCode,
  userData,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const pushToDataLayer = useDataLayer()

  const [showControlDialog, { openDialog: openControlDialog, closeDialog: closeControlDialog }] =
    useDialog()

  useEffect(() => {
    pushToDataLayer(
      {
        event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
        funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.AGREEMENT,
      },
      noLogin
    )
  }, [pushToDataLayer, noLogin])

  const onSubmit = ({ email2, ...values }) => {
    const transformConsentData = transformCreateConsentRequest(values)
    createConsentSavingsPlan(transformConsentData)
    storeValues(values)
    openControlDialog()
  }

  const handleGoBack = values => {
    storeValues(values)
    history.goBack()
  }

  const handleSubmit = () => {
    sendSecurityCode()
    if (noLogin) {
      history.push(ROUTES.NO_LOGIN_PLAN_FIVE)
    } else {
      history.push(ROUTES.CREATE_SAVINGS_PLAN_STEP_FIVE)
    }
  }

  return (
    <FormLayout
      title={translate('createSavingsPlan.title')}
      subTitle={`${translate('createSavingsPlan.acceptance')}`}
      pageCount={userData.isVerified ? '3/3' : '4/4'}
      wideLayout
      showClose={!noLogin}
      isSignedIn={!noLogin}
    >
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={createPlanValues?.pageThree}
          mutators={{
            resetSelectedVoucherMetal: ([name], state, utils) => {
              utils.changeValue(state, name, () => undefined)
            },
            autofillAddress: ([addressObj], state, utils) => {
              if (addressObj) {
                Object.entries(addressObj).forEach(([key, val]) => {
                  if (!val) {
                    return
                  }
                  utils.changeValue(state, key, () => val)
                })
              }
            },
          }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" align="center" paragraph>
                {translate('createSavingsPlan.necessaryDOC')}
              </Typography>
              <ContractorFormCH
                noLogin={noLogin}
                autofillAddress={form.mutators.autofillAddress}
                beneficialOwner={values.beneficialOwner}
                isPoliticallyExpose={values.isPoliticallyExpose}
              />
              <VoucherCard noLogin={noLogin} />
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => handleGoBack(values)}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton>{translate('actions.submit')}</CustomSaveButton>
              </Box>
            </form>
          )}
        />
        {noLogin && <LegalLinksNoLoginForm />}
      </Box>
      <CreatePlanRequestControlDialog
        onSubmit={handleSubmit}
        open={showControlDialog}
        onClose={closeControlDialog}
        isFetching={isFetching}
        noLogin
      />
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  userData: getBackendUserData(state),
  createPlanValues: getFormCreatePlanData(state),
  isFetching: isBackendCreatePlanFetching(state),
})

const mapDispatchToProps = dispatch => ({
  createConsentSavingsPlan: payload =>
    dispatch(fetchCreateConsentSavingsPlanActions.requestAction(payload)),
  storeValues: values => dispatch(storeCreatePlanValues(WORKFLOW_PAGE)(values)),
  sendSecurityCode: () => dispatch(fetchSendSecurityCodeActions.requestAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavingsPlanPageFour)
