import { convertExchangeRateRangeToDate } from '../../lib/util'
import { createBackendActions } from '../createAction'

export const fetchLoginActions = createBackendActions('FETCH_LOGIN')

export const fetchRefreshTokenActions = createBackendActions('FETCH_REFRESH_TOKEN')

export const fetchStartRegisterActions = createBackendActions('FETCH_START_REGISTER')

export const fetchFinishRegisterActions = createBackendActions('FETCH_FINISH_REGISTER')

export const fetchVerifyMailActions = createBackendActions('FETCH_VERIFY_MAIL')

export const fetchResendVerifyMailActions = createBackendActions('FETCH_RESEND_VERIFY_MAIL')

export const fetchSendSecurityCodeActions = createBackendActions('FETCH_SEND_SECURITY_CODE')

export const fetchVerifyPhoneActions = createBackendActions('FETCH_VERIFY_PHONE')

export const fetchVerifyCodeActions = createBackendActions('FETCH_VERIFY_CODE')

export const fetchResetPasswordActions = createBackendActions('FETCH_RESET_PASSWORD')

export const fetchUpdatePasswordActions = createBackendActions('FETCH_UPDATE_PASSWORD')

export const fetchUserDataActions = createBackendActions('FETCH_USER_DATA')

export const fetchUploadVerificationDocumentActions = createBackendActions(
  'FETCH_UPLOAD_VERIFICATION_DOCUMENT'
)

export const fetchCustomFilesActions = createBackendActions('FETCH_CUSTOM_FILES')

export const fetchSavingsPlansActions = createBackendActions('FETCH_SAVINGS_PLANS')

export const fetchCreateSavingsPlanRequestActions = createBackendActions(
  'FETCH_CREATE_SAVINGS_PLAN_REQUEST'
)

export const fetchInitiateOnlineIdentActions = createBackendActions('FETCH_INITIATE_ONLINE_IDENT')

export const fetchRegisterAndInitiateOnlineIdentActions = createBackendActions(
  'FETCH_REGISTER_AND_INITIATE_ONLINE_IDENT'
)

export const fetchDownloadFileActions = createBackendActions('FETCH_DOWNLOAD_FILE')

export const fetchUploadDocumentActions = createBackendActions('FETCH_UPLOAD_DOCUMENT')

export const fetchSendViaOtherActions = createBackendActions('FETCH_SEND_VIA_OTHER')

export const fetchPatchUserActions = createBackendActions('FETCH_PATCH_USER')

export const fetchProductsActions = createBackendActions('FETCH_PRODUCTS')

export const fetchCreatePayoutRequestActions = createBackendActions('FETCH_CREATE_PAYOUT_REQUEST')

export const fetchCancelPlanRequestActions = createBackendActions('FETCH_CANCEL_PLAN_REQUEST')

export const fetchAdjustPlanActions = createBackendActions('FETCH_ADJUST_PLAN')

export const fetchRequestsActions = createBackendActions('FETCH_REQUESTS')

export const fetchChangePlanNameActions = createBackendActions('FETCH_CHANGE_PLAN_NAME')

export const fetchExchangeRatesActions = createBackendActions(
  'FETCH_EXCHANGE_RATES',
  ({ range }) => ({ range, date: convertExchangeRateRangeToDate(range) })
)

export const fetchChangeSecurityDetailsActions = createBackendActions(
  'FETCH_CHANGE_SECURITY_DETAILS'
)

export const fetchConfirmNewMailActions = createBackendActions('FETCH_CONFIRM_NEW_MAIL')

export const fetchConfirmNewPhoneActions = createBackendActions('FETCH_CONFIRM_NEW_PHONE')

export const fetchInvoicesActions = createBackendActions('FETCH_INVOICES')

export const fetchVerifyRequest2FAActions = createBackendActions('FETCH_VERIFY_REQUEST_2FA')

export const fetchCustomerMessagesActions = createBackendActions('FETCH_CUSTOMER_MESSAGES')

export const fetchSetCustomerMessagesActions = createBackendActions('FETCH_SET_CUSTOMER_MESSAGES')

export const fetchRequestCallbackActions = createBackendActions('FETCH_REQUEST_CALLBACK')

export const fetchCreateCustomerMessageActions = createBackendActions(
  'FETCH_CREATE_CUSTOMER_MESSAGE'
)

export const fetchCountryDepartmentActions = createBackendActions('FETCH_COUNTRY_DEPARTMENT')

export const fetchCountryDepartmentByCountryCodeActions = createBackendActions('FETCH_COUNTRY_DEPARTMENT_BY_COUNTRY_CODE')

export const fetchDeleteCacheActions = createBackendActions('FETCH_DELETE_CACHE')

export const fetchVerifyVoucherActions = createBackendActions('FETCH_VERIFY_VOUCHER')

export const fetchMetalsActions = createBackendActions('FETCH_METALS')

export const fetchDeleteRequestActions = createBackendActions('FETCH_DELETE_REQUEST')

export const fetchGooglePlacesApiActions = createBackendActions('FETCH_GOOGLE_PLACES_API')

export const fetchMigrateActions = createBackendActions('FETCH_MIGRATE')

export const fetchValidateIbanActions = createBackendActions('FETCH_VALIDATE_IBAN')

export const fetchAccountBrandActions = createBackendActions('FETCH_ACCOUNT_BRAND')

export const fetchCreateConsentSavingsPlanActions = createBackendActions(
  'FETCH_CREATE_CONSENT_SAVINGS_PLAN'
)

export const fetchSavingPlanLogsActions = createBackendActions('FETCH_SAVING_PLAN_LOGS')

export const fetchPerformancesActions = createBackendActions('FETCH_PERFORMANCES')

export const fetchDeleteAdjustmentActions = createBackendActions('FETCH_DELETE_ADJUSTMENT')

export const fetchConditionsActions = createBackendActions('FETCH_CONDITIONS')

export const fetchApplyVoucherActions = createBackendActions('FETCH_APPLY_VOUCHER')

export const fetchDocumentVersionId = createBackendActions('FETCH_DOCUMENT_VERSION_ID')
