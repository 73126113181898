import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CheckboxAdapter, CustomSaveButton, FormLayout, SliderAdapter } from '../..'
import { MIN_ADJUSTMENT_DATE, PAUSE_MAX_MONTHS, PAUSE_MONTHLY_FEE, ROUTES } from '../../../config'
import useCurrency from '../../../lib/customHooks/useCurrency'
import { useTranslate } from '../../../lib/translate'
import useSnackbar from '../../../lib/useSnackbar'
import {
  formatIbanForInput,
  parseDateToStartOfMonth,
  parseIbanForInput,
  parseToUpperCase,
} from '../../../lib/util'
import {
  required,
  validateDateToBeAfterDate,
  validateNewSavingsRate,
} from '../../../lib/validation'
import { fetchValidateIbanActions, storePlanAdjustmentValues } from '../../../redux/actions'
import {
  getBackendMetalsData,
  getBackendSavingsPlansData,
  getBackendUserData,
  getBackendValidateIbanData,
  getFormPlanAdjustmentPageData,
  isBackendValiadateIbanFetching,
} from '../../../redux/selectors'
import { DatePickerAdapter, TextFieldAdapter } from '../../common/FormFields'
import { WORKFLOW_PAGE_1 } from './PlanAdjustmentPage1'

export const WORKFLOW_PAGE_2 = 'pageTwo'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  gridContainer: {
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  datePicker: {
    marginBottom: 0,
  },
  inlineButton: {
    marginBottom: '1.25rem',
    marginLeft: '1rem',
  },
}))

const PlanAdjustmentPageTwo = ({
  formValues,
  storeValues,
  plan,
  userData,
  metal,
  isFetching,
  validateIban,
  ibanValidationData,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const showSnackbar = useSnackbar()
  const displayCurrency = useCurrency()
  const minRate = plan?.minRate ?? 50
  const defaultRate = plan?.savingsRate || 50
  const canChangeBankAccount = userData?.id === plan?.userAccount1

  const onSubmit = values => {
    storeValues(values)
    history.push(ROUTES.PLAN_ADJUSTMENT_STEP_THREE)
  }

  const handleIbanValidationResponse = ([response], state, tools) => {
    const { bic, bank, result } = response.data.validateIban ?? {}

    if (!result) {
      showSnackbar('notification.invalidIban', 'error')
      tools.changeValue(state, 'bic', () => '')
      tools.changeValue(state, 'bank', () => '')
    } else {
      tools.changeValue(state, 'bic', () => bic)
      tools.changeValue(state, 'bank', () => bank)
    }
  }

  return (
    <FormLayout title={translate('planAdjustment.title')} wideLayout showClose isSignedIn>
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={formValues}
          mutators={{
            toggleBreak: ([next], state, utils) => {
              utils.changeValue(state, 'newSavingsRate', () => (next ? 0 : defaultRate))
            },
            handleIbanValidationResponse,
          }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Collapse in={values?.newSavingsRate !== 0}>
                <Box mb={3}>
                  <Card>
                    <CardContent className={classes.cardContentPadding}>
                      <Typography variant="h6" className={classes.boldText}>
                        {translate('planAdjustment.changeRate')}
                      </Typography>
                      <Typography variant="caption" paragraph color="textSecondary">
                        {plan?.metal &&
                          `${translate(`metals.${plan.metalName?.toLowerCase()}`)} ${translate(
                            'savingsPlans.plan'
                          )}`}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        mb={4}
                        pr={1.5}
                        alignItems="center"
                        gridGap="2rem"
                      >
                        <Typography gutterBottom variant="body1">
                          {translate('planAdjustment.actualSavingsRate')}
                        </Typography>
                        <Box minWidth="fit-content">
                          <Typography gutterBottom variant="body1">
                            {displayCurrency(plan?.savingsRate)}
                          </Typography>
                        </Box>
                      </Box>
                      <Grid container spacing={2} className={classes.gridContainer}>
                        <Grid item xs={7}>
                          <Typography>{translate('planAdjustment.start')}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Field
                            name="startMonth"
                            component={DatePickerAdapter}
                            disablePast
                            minDate={MIN_ADJUSTMENT_DATE}
                            openTo="month"
                            views={['month', 'year']}
                            defaultValue={MIN_ADJUSTMENT_DATE}
                            validate={validateDateToBeAfterDate(MIN_ADJUSTMENT_DATE)}
                            parse={parseDateToStartOfMonth}
                            className={classes.datePicker}
                          />
                        </Grid>
                      </Grid>
                      <Field
                        name="newSavingsRate"
                        component={SliderAdapter}
                        validate={validateNewSavingsRate(minRate)}
                        sliderLabel={translate('planAdjustment.newSavingsRate')}
                        sliderHelper={translate('cancelPlans.minimumSavingRate', {
                          minRate,
                        })}
                        format={parseInt}
                        formatOnBlur
                        min={minRate}
                        max={1000}
                        defaultValue={defaultRate}
                        mb={5}
                      />
                    </CardContent>
                  </Card>
                </Box>
              </Collapse>
              <Box mb={3}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="h6" paragraph className={classes.boldText}>
                      {translate('planAdjustment.requestPause')}
                    </Typography>
                    <FormControlLabel
                      label={translate('planAdjustment.confirmPause')}
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.checkbox}
                          checked={values?.newSavingsRate === 0 || false}
                          onChange={event => form.mutators.toggleBreak(event.target.checked)}
                        />
                      }
                    />
                    <Collapse in={values?.newSavingsRate === 0}>
                      <Box mt={2}>
                        <Grid container spacing={2} className={classes.gridContainer}>
                          <Grid item xs={7}>
                            <Typography>{translate('planAdjustment.startPause')}</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Field
                              name="startMonth"
                              component={DatePickerAdapter}
                              disablePast
                              minDate={MIN_ADJUSTMENT_DATE}
                              openTo="month"
                              views={['month', 'year']}
                              defaultValue={MIN_ADJUSTMENT_DATE}
                              validate={validateDateToBeAfterDate(MIN_ADJUSTMENT_DATE)}
                              parse={parseDateToStartOfMonth}
                              className={classes.datePicker}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Collapse>
                    <Typography variant="caption" color="textSecondary" paragraph>
                      {translate('planAdjustment.pauseRemainingMonths', {
                        monthsLeft: Math.max(0, 3 - (plan?.pausedMonth ?? 0)),
                      })}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" paragraph>
                      {translate('planAdjustment.pauseRemainingMonthsExplanation', {
                        monthlyFee: displayCurrency(PAUSE_MONTHLY_FEE),
                        maxMonthsLeft: PAUSE_MAX_MONTHS,
                      })}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {translate('planAdjustment.pauseInfo2')}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
              {canChangeBankAccount && (
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="h6" paragraph className={classes.boldText}>
                      {translate('planAdjustment.changeBankAccountTitle')}
                    </Typography>
                    <Field
                      name="changeBankAccount"
                      component={CheckboxAdapter}
                      label={translate('planAdjustment.changeBankAccountFiekd')}
                    />
                    <Collapse in={values.changeBankAccount} unmountOnExit>
                      <Box mt={3}>
                        <Field
                          name="bankAccountOwner"
                          component={TextFieldAdapter}
                          validate={required}
                          initialValue={`${userData?.firstName} ${userData?.lastName}`}
                          fullWidth
                          disabled
                          label={translate('formFields.accountOwner')}
                        />
                        <Box display="flex" flexDirection="row">
                          <Field
                            name="iban"
                            component={TextFieldAdapter}
                            format={formatIbanForInput}
                            parse={parseIbanForInput}
                            fullWidth
                            label={translate('formFields.iban')}
                          />
                          <CustomSaveButton
                            disabled={!values?.iban}
                            onClick={() =>
                              validateIban(values?.iban, form.mutators.handleIbanValidationResponse)
                            }
                            loading={isFetching}
                            className={classes.inlineButton}
                          >
                            {translate('actions.verify')}
                          </CustomSaveButton>
                        </Box>
                        <Field
                          name="bank"
                          component={TextFieldAdapter}
                          fullWidth
                          label={translate('formFields.bankName')}
                        />
                        <Field
                          name="bic"
                          component={TextFieldAdapter}
                          parse={parseToUpperCase}
                          fullWidth
                          label={translate('formFields.bic')}
                        />
                      </Box>
                    </Collapse>
                  </CardContent>
                </Card>
              )}
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton
                  disabled={values.changeBankAccount && !ibanValidationData?.result}
                >
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => {
  const savingsPlanId = get(getFormPlanAdjustmentPageData(WORKFLOW_PAGE_1)(state), 'savingsPlanId')
  const plan = find(getBackendSavingsPlansData(state), { id: savingsPlanId })

  return {
    userData: getBackendUserData(state),
    formValues: getFormPlanAdjustmentPageData(WORKFLOW_PAGE_2)(state),
    plan,
    metal: find(getBackendMetalsData(state), { id: plan?.metal }),
    isFetching: isBackendValiadateIbanFetching(state),
    ibanValidationData: getBackendValidateIbanData(state),
  }
}

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storePlanAdjustmentValues(WORKFLOW_PAGE_2)(values)),
  validateIban: (iban, handleIbanValidationResponse) =>
    dispatch(fetchValidateIbanActions.requestAction({ iban, handleIbanValidationResponse })),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanAdjustmentPageTwo)
