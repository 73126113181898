import { Box, Button, Card, CardContent, Collapse, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  AutocompleteAdapter,
  CheckboxAdapter,
  CustomSaveButton,
  FormLayout,
  TextFieldAdapter,
} from '../..'
import {
  availableMonths,
  AVAILABLE_METALS,
  METAL_MIN_RATE_NAME,
  METAL_TYPE,
  METAL_TYPE_TO_CONDITION_NAME_MAP,
  ROUTES,
  GOOGLE_TAG_MANAGER,
} from '../../../config'
import useCurrency from '../../../lib/customHooks/useCurrency'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import { useTranslate } from '../../../lib/translate'
import useSnackbar from '../../../lib/useSnackbar'
import { required, validateMin } from '../../../lib/validation'
import {
  fetchConditionsActions,
  fetchRefreshTokenActions,
  storeCreatePlanValues,
} from '../../../redux/actions'
import {
  getBackendConditionsData,
  getBackendUserData,
  getFormCreatePlanPageData,
} from '../../../redux/selectors'
import LegalLinksNoLoginForm from '../../common/LegaLinksNoLoginForm'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_ONE } from './CreateSavingsPlanPage1'

export const WORKFLOW_PAGE = 'pageOne'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  textHint: {
    color: theme.palette.text.hint,
  },
  noBottomMargin: {
    marginBottom: 0,
  },
}))

const PlanTypeCard = ({ planType, minAmount }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box mb={3}>
      <Card>
        <CardContent className={classes.cardContentPadding}>
          <Typography variant="body1" gutterBottom component="span">
            <Box fontWeight="fontWeightBold" mb={3}>
              {translate(`metals.${planType}`)}
            </Box>
          </Typography>
          <Grid container alignItems="center" justify="space-between" spacing={2}>
            <Grid item xs={8}>
              <Typography variant="body2">{translate('formFields.monthlyPerAsset')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                name={`${planType}Monthly`}
                component={TextFieldAdapter}
                validate={validateMin(minAmount)}
                className={classes.noBottomMargin}
                format={parseInt}
                formatOnBlur
                min={minAmount}
                type="number"
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{translate('formFields.startingPeriod')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                name={`${planType}StartMonth`}
                component={AutocompleteAdapter}
                options={availableMonths().map(month => ({
                  label: month.format('MMMM'),
                  value: month.format('YYYY-MM-DD'),
                }))}
                translateLabel={false}
                className={classes.noBottomMargin}
                validate={required}
              />
            </Grid>
          </Grid>
          <Box mt="1.2rem">
            <Typography variant="body1" paragraph className={classes.textHint}>
              {translate('createSavingsPlan.minSavingsAmount', { min: minAmount })}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

const CreateSavingsPlanPageOne = ({
  formData,
  initialSelectedAssets,
  userData,
  storeValues,
  noLogin,
  conditions,
  fetchConditions,
  refreshToken,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const displayCurrency = useCurrency()
  const showSnackbar = useSnackbar()
  const pushToDataLayer = useDataLayer()

  const getMinAmount = noLogin
    ? metalType =>
        conditions?.find(
          condition => condition.name === METAL_TYPE_TO_CONDITION_NAME_MAP[metalType],
        )?.minAmount
    : metalType => userData[METAL_MIN_RATE_NAME[metalType]]

  const onSubmit = values => {
    if (!some(Object.values(METAL_TYPE), type => !!values[type])) {
      showSnackbar('notification.atLeastOneAsset', 'info')
    } else {
      storeValues(values)
      pushToDataLayer(
        {
          event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
          funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.SAVINGS_PLAN,
        },
        noLogin,
        values,
      )
      history.push(noLogin ? ROUTES.NO_LOGIN_PLAN_TWO : ROUTES.CREATE_SAVINGS_PLAN_STEP_TWO)
    }
  }

  useEffect(() => {
    fetchConditions()
    refreshToken()
  }, [fetchConditions, refreshToken])

  useEffect(() => {
    pushToDataLayer(
      {
        event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
        funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.SAVINGS_PLAN,
      },
      noLogin,
    )
  }, [noLogin, pushToDataLayer])
  return (
    <FormLayout
      title={translate('createSavingsPlan.title')}
      subTitle={translate('createSavingsPlan.monthlyPlan')}
      pageCount={userData.isVerified ? '1/3' : '1/4'}
      wideLayout
      showClose={!noLogin}
      isSignedIn={!noLogin}
    >
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          mutators={{
            reset: ([metalType], state, utils) => {
              utils.changeValue(state, `${metalType}Monthly`, () => null)
              utils.changeValue(state, `${metalType}StartMonth`, () => null)
            },
          }}
          initialValues={isEmpty(formData) ? initialSelectedAssets : formData}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Typography variant="h6" align="center" paragraph>
                  {translate('createSavingsPlan.selectMetalTitle')}
                </Typography>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="body1" gutterBottom component="span">
                      <Box fontWeight="fontWeightBold" mb={1}>
                        {translate('createSavingsPlan.openUp')}
                      </Box>
                    </Typography>
                    <Box>
                      {AVAILABLE_METALS.map(({ metalType }) => (
                        <Field
                          name={metalType}
                          component={CheckboxAdapter}
                          onChange={next => {
                            if (!next) {
                              setTimeout(() => form.mutators.reset(metalType), 1000)
                            }
                          }}
                          label={translate(`metals.${metalType}`)}
                          key={metalType}
                        />
                      ))}
                    </Box>
                  </CardContent>
                </Card>
                <Collapse in={values.gold || values.silver} unmountOnExit>
                  <Box mt={3}>
                    <Typography variant="h6" align="center" paragraph>
                      {translate('createSavingsPlan.assetDistributionInfo')}
                    </Typography>
                  </Box>
                </Collapse>
              </Box>
              {AVAILABLE_METALS.map(({ metalType }) => (
                <Collapse in={values[metalType] || false} unmountOnExit key={metalType}>
                  <PlanTypeCard planType={metalType} minAmount={getMinAmount(metalType)} />
                </Collapse>
              ))}
              <Box mb={3}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="body1" gutterBottom component="span">
                      <Box fontWeight="fontWeightBold" mb={3}>
                        {translate('createSavingsPlan.savingsRate')}
                      </Box>
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <Typography variant="body2" gutterBottom>
                          {translate('formFields.monthlyTotal')}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {displayCurrency(
                            Object.entries(values).reduce(
                              (sum, [key, value]) =>
                                (
                                  // eslint-disable-next-line no-sequences
                                  key.includes('Monthly') ? (sum += +value) : sum, sum
                                ),
                              0,
                            ),
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton>{translate('finishRegistration.continue')}</CustomSaveButton>
              </Box>
            </form>
          )}
        />
        {noLogin && <LegalLinksNoLoginForm />}
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  initialSelectedAssets: getFormCreatePlanPageData(WORKFLOW_PAGE_ONE)(state),
  formData: getFormCreatePlanPageData(WORKFLOW_PAGE)(state),
  userData: getBackendUserData(state),
  conditions: getBackendConditionsData(state),
})

const mapDispatchToProps = dispatch => ({
  fetchConditions: () => dispatch(fetchConditionsActions.requestAction()),
  storeValues: payload => dispatch(storeCreatePlanValues(WORKFLOW_PAGE)(payload)),
  refreshToken: () => dispatch(fetchRefreshTokenActions.requestAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavingsPlanPageOne)
