import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React, { useState } from 'react'

import useCalcRemainingGram from '../../lib/customHooks/useCalcRemainingGram'
import useDialog from '../../lib/customHooks/useDialog'
import { formatGram } from '../../lib/miscellaneous'
import { useTranslate } from '../../lib/translate'

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  container: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row-reverse',
    right: 0,
    bottom: 50,
  },
  notCollapsedCard: {
    maxWidth: '100%',
    transition: 'all 0.6s linear',
  },
  notCollapsedContent: {
    maxWidth: '100%',
    opacity: 1,
    transition: 'all 0.8s linear',
  },
  collapsedCard: {
    maxWidth: 50,
    transition: 'all 0.8s ease-in',
  },
  collapsedContent: {
    maxWidth: 0,
    opacity: 0,
    transition: 'all 0.8s linear',
  },
  cardContent: {
    padding: theme.spacing(4),
    paddingLeft: 0,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    transition: 'all 0.8s linear',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      paddingLeft: 0,
      '&:last-child': {
        paddingBottom: theme.spacing(),
      },
    },
  },
  cardContentCollapse: {
    paddingRight: 0,
  },
  rotateIcon: {
    transform: 'rotate(180deg)',
  },
  iconButton: {
    padding: theme.spacing(),
    transition: 'all 0.8s',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  redText: {
    color: theme.palette.error.main,
  },
  helpIconButton: {
    position: 'absolute',
    top: 6,
    left: 6,
  },
}))

const LabeledValue = ({ label, value, unit }) => {
  const classes = useStyles({ value })
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="body1" gutterBottom noWrap>
        {label}
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={`${classes.boldText}  ${value && parseInt(value) < 0 ? classes.redText : ''}`}
        noWrap
      >
        {value}
      </Typography>
    </Box>
  )
}

const HelpDialog = ({ open, onClose }) => {
  const translate = useTranslate()

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h6" gutterBottom align="center">
          {translate('depotOverviewCard.helpTitle')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom align="justify">
          {translate('depotOverviewCard.helpText')}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

const DepositOverviewCard = ({ planId, formValues }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const [expanded, setExpanded] = useState(true)
  const [dialogState, { openDialog, closeDialog }] = useDialog()

  const [remainingGram, totalGram] = useCalcRemainingGram(formValues, planId)

  return (
    <Box className={classes.container}>
      <Card className={`${expanded ? classes.notCollapsedCard : classes.collapsedCard}`}>
        <CardContent
          className={`${classes.cardContent} ${expanded ? '' : classes.cardContentCollapse}`}
        >
          <Box className={`${expanded ? classes.notCollapsedContent : classes.collapsedContent}`}>
            <Typography variant="h6" paragraph className={classes.boldText} noWrap>
              {translate('cancelPlans.depositValue')}
            </Typography>
            <LabeledValue
              label={translate('cancelPlans.availableGold')}
              value={formatGram(totalGram)}
            />
            <LabeledValue
              label={translate('cancelPlans.available')}
              value={formatGram(remainingGram)}
            />
            <IconButton
              size="small"
              color="secondary"
              className={classes.helpIconButton}
              onClick={openDialog}
            >
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => setExpanded(!expanded)}
              className={`${classes.iconButton} ${expanded ? '' : classes.rotateIcon}`}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
      <HelpDialog open={dialogState} onClose={closeDialog} />
    </Box>
  )
}

export default DepositOverviewCard
