import React from 'react'
import { Switch } from 'react-router-dom'

import {
  CustomRoute,
  CompleteRequestPage,
  CompleteProfile,
  CompleteRequestPageSavingsPlan
} from '..'
import CreatePlanNavigation from './formFlows/CreatePlanNavigation'
import CancelPlanNavigation from './formFlows/CancelPlanNavigation'
import RequestPayoutNavigation from './formFlows/RequestPayoutNavigation'
import CreateDepositNavigation from './formFlows/CreateDepositNavigation'
import PlanAdjustmentNavigation from './formFlows/PlanAdjustmentNavigation'
import { ROUTES } from '../../config'
import { useTranslate } from '../../lib/translate'
import MainLayout from '../layout/MainLayout'

/**
 * Protected Form Navigation
 *
 * add here all signed-in routes which have the header with title, subtitle and counting page
 */
const NestedFormNavigation = () => {
  const translate = useTranslate()
  return (
    <MainLayout>
      <Switch>
        <CustomRoute path={ROUTES.CREATE_PLAN_FLOW} title={translate('documentTitle.createPlan')}>
          <CreatePlanNavigation />
        </CustomRoute>
        <CustomRoute path={ROUTES.CANCEL_PLAN_FLOW} title={translate('documentTitle.cancelPlan')}>
          <CancelPlanNavigation />
        </CustomRoute>
        <CustomRoute
          path={ROUTES.REQUEST_PAYOUT_FLOW}
          title={translate('documentTitle.requestPayout')}
        >
          <RequestPayoutNavigation />
        </CustomRoute>
        <CustomRoute
          path={ROUTES.CREATE_DEPOSIT_FLOW}
          title={translate('documentTitle.createDeposit')}
        >
          <CreateDepositNavigation />
        </CustomRoute>
        <CustomRoute
          path={ROUTES.PLAN_ADJUSTMENT_FLOW}
          title={translate('documentTitle.planAdjustment')}
        >
          <PlanAdjustmentNavigation />
        </CustomRoute>
        <CustomRoute path={`${ROUTES.COMPLETE_REQUEST}/:requestId`}>
          <CompleteRequestPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.COMPLETE_REQUEST_SAVINGS_PLAN}>
          <CompleteRequestPageSavingsPlan />
        </CustomRoute>
        <CustomRoute path={ROUTES.COMPLETE_PROFILE}>
          <CompleteProfile />
        </CustomRoute>
      </Switch>
    </MainLayout>
  )
}

export default NestedFormNavigation
