import moment from 'moment'
import goldIcon from './../assets/images/gold.svg'
import palladiumIcon from './../assets/images/palladium.svg'
import platinumIcon from './../assets/images/platinum.svg'
import silverIcon from './../assets/images/silver.svg'

export const ROUTES = {
  /**
   * LOGIN AND REGISTER ROUTES
   */
  LOGIN: '/login',
  TWO_FA: '/two_factor_authentication',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password/:token',
  CONFIRMATION_SENT: '/confirmation_sent',
  VERIFY_MAIL: '/verify_mail/:token',
  FINISH_REGISTRATION: '/finish_registration',
  SET_UP_TWO_FA: '/set_up_auth',
  CONFIRM_NEW_MAIL: '/confirm_new_mail/:token',
  IMPRESSUM: '/impressum',
  TOS: '/terms_of_service',
  PRIVACY_POLICY: '/privacy_policy',
  PRIVACY_INFO: '/privacy_info',
  BGB: '/bgb',
  NO_LOGIN_PLAN: '/no_login/form',
  NO_LOGIN_PLAN_ONE: '/no_login/form/create_plan/choose_assets',
  NO_LOGIN_PLAN_TWO: '/no_login/form/create_plan/personal_data',
  NO_LOGIN_PLAN_THREE: '/no_login/form/create_plan/online_ident',
  NO_LOGIN_PLAN_FOUR: '/no_login/form/create_plan/user_acceptance',
  NO_LOGIN_PLAN_FIVE: '/no_login/form/create_plan/2fa',
  NO_LOGIN_COMPLETE_REQUEST_SAVINGS_PLAN: '/no_login/form/complete_savings_plan',
  /**
   * HOME ROUTES
   */
  HOME: '/home',
  DASHBOARD: '/home/dashboard',
  SAVINGSPLANS_OVERVIEW: '/home/plans_overview',
  REQUESTS: '/home/plan_requests',
  PLAN_DETAILS: '/home/plan',
  PAYMENTS: '/home/payments',
  CANCEL_SAVINGS_PLAN: '/home/plan_cancellation',
  MIGRATION: '/home/migration',
  PROFILE: '/home/profile',
  SECURITY: '/home/security',
  CONFIRM_NEW_PHONE: '/home/confirm_new_phone',
  /**
   * FORM WORKFLOW ROUTES
   */
  FORM: '/form',
  // create plan workflow
  CREATE_PLAN_FLOW: '/form/create_plan',
  CREATE_SAVINGS_PLAN_STEP_ONE: '/form/create_plan/choose_assets',
  CREATE_SAVINGS_PLAN_STEP_TWO: '/form/create_plan/personal_data',
  CREATE_SAVINGS_PLAN_STEP_THREE: '/form/create_plan/online_ident',
  CREATE_SAVINGS_PLAN_STEP_FOUR: '/form/create_plan/asset_distribution',
  CREATE_SAVINGS_PLAN_STEP_FIVE: '/form/create_plan/2fa',
  COMPLETE_REQUEST_SAVINGS_PLAN: '/form/complete_savings_plan',
  // cancel plan workflow
  CANCEL_PLAN_FLOW: '/form/cancel_plan',
  CANCEL_SAVINGS_PLAN_STEP_ONE: '/form/cancel_plan/choose_plan',
  CANCEL_SAVINGS_PLAN_STEP_TWO: '/form/cancel_plan/select_assets',
  CANCEL_SAVINGS_PLAN_STEP_THREE: '/form/cancel_plan/select_physical_amount',
  CANCEL_SAVINGS_PLAN_STEP_FOUR: '/form/cancel_plan/delivery',
  // request payout workflow
  REQUEST_PAYOUT_FLOW: '/form/request_payout',
  REQUEST_PAYOUT_STEP_ONE: '/form/request_payout/select_plan',
  REQUEST_PAYOUT_STEP_TWO: '/form/request_payout/method',
  REQUEST_PAYOUT_STEP_THREE: '/form/request_payout/in_euro',
  REQUEST_PAYOUT_STEP_FOUR: '/form/request_payout/select_physical_amount',
  REQUEST_PAYOUT_STEP_FIVE: '/form/request_payout/delivery',
  // create deposit workflow
  CREATE_DEPOSIT_FLOW: '/form/create_deposit',
  CREATE_DEPOSIT_STEP_ONE: '/form/create_deposit/select_plan',
  CREATE_DEPOSIT_STEP_TWO: '/form/create_deposit/bank_information',
  // plan adjustment workflow
  PLAN_ADJUSTMENT_FLOW: '/form/plan_adjustment',
  PLAN_ADJUSTMENT_STEP_ONE: '/form/plan_adjustment/choose_plan',
  PLAN_ADJUSTMENT_STEP_TWO: '/form/plan_adjustment/update_plan',
  PLAN_ADJUSTMENT_STEP_THREE: '/form/plan_adjustment/update_overview',
  // change profile
  COMPLETE_PROFILE: '/form/complete_profile',
  // complete a request
  COMPLETE_REQUEST: '/form/complete_request',
}

export const KEY_CODES = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ENTER: 13,
}

export const POST_AUTH_ACTION = {
  COMPLETE: 'COMPLETE',
  EMAIL: 'EMAIL',
  '2FA': '2FA',
  REDIRECT: 'REDIRECT',
}

export const METAL_TYPE = {
  GOLD: 'gold',
  SILVER: 'silber',
  PLATINUM: 'platin',
  PALLADIUM: 'palladium',
}

export const METAL_ICON = {
  [METAL_TYPE.GOLD]: goldIcon,
  [METAL_TYPE.SILVER]: silverIcon,
  [METAL_TYPE.PLATINUM]: platinumIcon,
  [METAL_TYPE.PALLADIUM]: palladiumIcon,
}

export const VERIFICATION_DOCUMENT_TYPE = {
  IDENTITY_CARD: {
    key: 'verificationDocument.identityCard',
    value: 'IDENTITY_CARD',
  },
  PASSPORT: {
    key: 'verificationDocument.passport',
    value: 'PASSPORT',
  },
  PERMIT_B: {
    key: 'verificationDocument.permitB',
    value: 'PERMIT_B',
  },
  PERMIT_C: {
    key: 'verificationDocument.permitC',
    value: 'PERMIT_C',
  },
}

export const API_ERROR_KEYS = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  FORBIDDEN: 'FORBIDDEN',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  SALESFORCE_ERROR: 'SALESFORCE_ERROR',
  CONTRACT_NOT_FOUND: 'CONTRACT_NOT_FOUND',
  ALREADY_VERIFIED: 'ALREADY_VERIFIED',
  ALREADY_REGISTERED: 'ALREADY_REGISTERED',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  CUSTOM_VALIDATION_EXCEPTION: 'FIELD_CUSTOM_VALIDATION_EXCEPTION',
  GOOGLE_AUTOCOMPLETE_ERROR: 'GOOGLE_AUTOCOMPLETE_ERROR',
  USER_DEACTIVATED: 'USER_DEACTIVATED',
}

export const LOGLEVEL = {
  debug: 'debug',
  info: 'info',
  warn: 'warn',
  error: 'error',
}

export const ERROR_SOURCE = {
  SAGA_MIDDLEWARE: 'SAGA_MIDDLEWARE',
}

export const SALUTATION_OPTIONS = {
  MALE: {
    key: 'salutationOptions.male',
    value: 'Mr.',
  },
  FEMALE: {
    key: 'salutationOptions.female',
    value: 'Mrs.',
  },
}

export const REQUEST_RECORD_TYPE = {
  CREATE: {
    type: 'createPlan',
    id: '012090000001IlKAAU',
    label: 'requests.createPlan',
  },
  PAYOUT: {
    type: 'payoutPlan',
    id: '012090000001RRdAAM',
    label: 'requests.payoutPlan',
  },
  CANCEL: {
    type: 'cancelPlan',
    id: '012090000001ZPiAAM',
    label: 'requests.cancelPlan',
  },
}

export const REQUEST_RECORD_TYPE_MAPPING = {
  '012090000001IlKAAU': 'requests.createPlan',
  '012090000001RRdAAM': 'requests.payoutPlan',
  '012090000001ZPdAAM': 'requests.adjustPlan',
  '012090000001ZPiAAM': 'requests.cancelPlan',
}

export const PRODUCT_RECORD_TYPE = {
  EQUIPMENT: {
    id: '012090000001U64AAE',
    label: 'equipment',
  },
  GIFT_ITEM: {
    id: '012090000001U69AAE',
    label: 'giftItem',
  },
  GOLD_BAR: {
    id: '012090000001U5fAAE',
    label: 'goldBar',
  },
  GOLD_COIN: {
    id: '012090000001U5aAAE',
    label: 'goldCoin',
  },
  NUMISMATIK: {
    id: '012090000001U5zAAE',
    label: 'numismatik',
  },
  PLATIN_PALLADIUM: {
    id: '012090000001U5uAAE',
    label: 'platinPalladium',
  },
  SILVER_BAR: {
    id: '012090000001U5kAAE',
    label: 'silverBar',
  },
  SILVER_COIN: {
    id: '012090000001U5pAAE',
    label: 'silverCoin',
  },
}

export const REQUEST_STATUS = {
  DRAFT: 'Draft',
  CREATED: 'Request Created',
  WAITING: 'Waiting For Customer',
  REVIEW: 'In Review',
  DECLINED: 'Declined',
  APPROVED: 'Approved',
  DELETED: 'Deleted by Customer',
}

export const REQUEST_STATUS_PENDING = [
  REQUEST_STATUS.DRAFT,
  REQUEST_STATUS.CREATED,
  REQUEST_STATUS.WAITING,
  REQUEST_STATUS.REVIEW,
]

export const REQUEST_STATUS_USER_ACTION_REQUIRED = [
  REQUEST_STATUS.DRAFT,
  REQUEST_STATUS.CREATED,
  REQUEST_STATUS.WAITING,
]

export const REQUEST_STATUS_LABEL_MAPPING = {
  [REQUEST_STATUS.DRAFT]: 'requests.draft',
  [REQUEST_STATUS.CREATED]: 'requests.created',
  [REQUEST_STATUS.WAITING]: 'requests.waiting',
  [REQUEST_STATUS.REVIEW]: 'requests.review',
  [REQUEST_STATUS.DECLINED]: 'requests.declined',
  [REQUEST_STATUS.APPROVED]: 'requests.approved',
}

export const SHIPPING_PAYMENT_MAPPING = {
  STORE: {
    key: 'STORE',
    label: 'confirmHowToPay',
  },
  STORE_PLAN: {
    key: 'STORE_PLAN',
    label: 'confirmToPayWithMetalsStore',
  },
  STORE_TRANSFER: {
    key: 'STORE_TRANSFER',
    label: 'confirmToPayWithTransfer',
  },
  INVOICE: {
    key: 'INVOICE',
    label: 'confirmTransfer',
  },
  SAVINGS_PLAN: {
    key: 'SAVINGS_PLAN',
    label: 'confirmToPayWithMetals',
  },
}

export const EXCHANGE_RATE_RANGES = {
  '1W': '1W',
  '1M': '1M',
  '3M': '3M',
  '6M': '6M',
  '12M': '12M',
  '5Y': '5Y',
  '10Y': '10Y',
  '20Y': '20Y',
}

export const SHIPPING_METHODS = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
}

export const SAVINGS_PLAN_STATUS = {
  ACTIVE: 'Active',
  ACTIVE_NO_FEE: 'ActiveNoCustodyFee',
  PAUSED: 'Paused',
  PAUSED_NO_FEE: 'Pausiert (ohne Gebühren)',
  INACTIVE: 'Inactive',
  IN_TERMINATION: 'In Termination',
  TERMINATED: 'Terminated',
}

export const SAVINGS_PLAN_STATUS_ORDER = [
  SAVINGS_PLAN_STATUS.ACTIVE,
  SAVINGS_PLAN_STATUS.ACTIVE_NO_FEE,
  SAVINGS_PLAN_STATUS.PAUSED,
  SAVINGS_PLAN_STATUS.PAUSED_NO_FEE,
  SAVINGS_PLAN_STATUS.INACTIVE,
  SAVINGS_PLAN_STATUS.IN_TERMINATION,
  SAVINGS_PLAN_STATUS.TERMINATED,
]

export const SAVINGS_PLAN_METAL_ORDER = [
  METAL_TYPE.GOLD,
  METAL_TYPE.SILVER,
  METAL_TYPE.PLATINUM,
  METAL_TYPE.PALLADIUM,
]

export const PLAN_INACTIVE_STATUS = [
  SAVINGS_PLAN_STATUS.PAUSED,
  SAVINGS_PLAN_STATUS.PAUSED_NO_FEE,
  SAVINGS_PLAN_STATUS.INACTIVE,
  SAVINGS_PLAN_STATUS.IN_TERMINATION,
  SAVINGS_PLAN_STATUS.TERMINATED,
]

export const PLAN_ACTIONS_DISABLED_STATUS = [
  SAVINGS_PLAN_STATUS.INACTIVE,
  SAVINGS_PLAN_STATUS.IN_TERMINATION,
  SAVINGS_PLAN_STATUS.TERMINATED,
]

export const COUNTRY_DEPARTMENT = {
  GERMANY: {
    key: 'countryDepartment.germany',
    value: 'DE',
    tld: 'de',
    domain: 'https://edelmetallsparer.de/',
  },
  AUSTRIA: {
    key: 'countryDepartment.austria',
    value: 'AT',
    tld: 'at',
    domain: 'https://edelmetallsparer.at/',
  },
  SWITZERLAND: {
    key: 'countryDepartment.switzerland',
    value: 'CH',
    tld: 'ch',
    domain: 'https://edelmetallsparer.ch/',
  },
}

export const INVOICE_STATUS = {
  NEW: 'New',
  INVOICE_CREATED: 'Invoice Created',
}

export const INVOICE_STATUS_LABEL_MAPPING = {
  [INVOICE_STATUS.NEW]: 'invoice.new',
  [INVOICE_STATUS.INVOICE_CREATED]: 'invoice.created',
}

export const INVOICE_TYPE = {
  DEPOSIT: 'Einzahlung',
  WITHDRAW: 'Auszahlung',
}

export const CUSTOMER_MESSAGE_STATUS = {
  NEW: 'New',
  READ: 'Read',
}

// Following countries have to be sorted to top of autocomplete components
export const COUNTRIES_TO_DISPLAY_FIRST = ['AT', 'DE', 'CH']

export const PICKUP_STORES = {
  ZUERICH: {
    name: 'Zürich, Pelikanstraße 6/8',
    value: 'ZUERICH',
  },
  WITTENBACH: {
    name: 'Wittenbach, St. Gallerstraße 7',
    value: 'WITTENBACH',
  },
}

export const CUSTOMER_GROUP = {
  PRIVATE: 'Privat',
  EMPLOYEE: 'Mitarbeiter',
  SALES: 'Vertrieb',
}

export const METAL_MIN_RATE_NAME = {
  [METAL_TYPE.GOLD]: 'minRateGold',
  [METAL_TYPE.SILVER]: 'minRateSilver',
  [METAL_TYPE.PALLADIUM]: 'minRatePalladium',
  [METAL_TYPE.PLATINUM]: 'minRatePlatinum',
}

export const AVAILABLE_METALS = [
  { metalType: METAL_TYPE.GOLD, metal: METAL_TYPE.GOLD },
  { metalType: METAL_TYPE.SILVER, metal: METAL_TYPE.SILVER },
  { metalType: METAL_TYPE.PLATINUM, metal: METAL_TYPE.PLATINUM },
  { metalType: METAL_TYPE.PALLADIUM, metal: METAL_TYPE.PALLADIUM },
]

export const CURRENCY = {
  EUR: 'EUR',
  CHF: 'CHF',
}

export const ISO_CODE_TO_CURRENCY_MAPPING = {
  [CURRENCY.EUR]: '€',
  [CURRENCY.CHF]: 'CHF',
}

export const DAY_OF_MONTH = {
  COLLECTION: 3,
  BUY: 10,
}

export const SEND_OPTION = {
  TOPOST: 'TOPOST',
  FROMPOST: 'FROMPOST',
  FROMEMAIL: 'FROMEMAIL',
}

export const MIN_ADJUSTMENT_DATE = moment().add(1, 'month').add(5, 'days').startOf('month')

export const SAVING_PLAN_LOG_STATUS = {
  OPEN: 'offen',
  DECLINED: 'abgelehnt',
  PROCESSED: 'verarbeitet',
}

export const METAL_TYPE_TO_CONDITION_NAME_MAP = {
  [METAL_TYPE.GOLD]: 'CH Privat Standard Gold',
  [METAL_TYPE.SILVER]: 'CH Privat Standard Silber',
}

export const PAUSE_MONTHLY_FEE = 10
export const PAUSE_MAX_MONTHS = 3

export const VOUCHER_SCREEN = {
  CREATE_PLAN: 'CREATE_PLAN',
  PLAN_DETAILS: 'PLAN_DETAILS',
}

export const GOOGLE_TAG_MANAGER = {
  EVENT: {
    REGISTRATION_OPEN: 'registration-open',
    REGISTRATION_START: 'registration-start',
    REGISTRATION_VERIFIED: 'registration-verified',
    REGISTRATION_COMPLETE: 'registration-complete',
    SAVINGS_PLAN_STEP: 'sparplan-step',
    SAVINGS_PLAN_COMPLETE: 'sparplan-complete',
  },
  FUNNEL_STEP: {
    REGISTRATION: 'Registrierung',
    VERIFICATION: 'Verifizierung',
    SAVINGS_PLAN: 'Sparplan',
    PERSONAL_DATA: 'Personendaten',
    PROOF_OF_IDENTITY: 'Identitätsnachweis',
    AGREEMENT: 'Einverständnis',
    CONFIRMATION: 'Bestätigung',
    COMPLETION: 'Abschluss',
  },
}

export const BOOLEANS = {
  TRUE: 'true',
  FALSE: 'false',
}
