import find from 'lodash/find'
import get from 'lodash/get'
import isString from 'lodash/isString'
import moment from 'moment'

import {
  METAL_TYPE,
  METAL_TYPE_TO_CONDITION_NAME_MAP,
  PLAN_ACTIONS_DISABLED_STATUS,
  PRODUCT_RECORD_TYPE,
  SAVINGS_PLAN_METAL_ORDER,
  SAVINGS_PLAN_STATUS_ORDER,
  SEND_OPTION,
} from '../config'

export const reduceProducts = products => {
  const coinRecordTypes = [PRODUCT_RECORD_TYPE.SILVER_COIN.id, PRODUCT_RECORD_TYPE.GOLD_COIN.id]
  const barRecordTypes = [PRODUCT_RECORD_TYPE.SILVER_BAR.id, PRODUCT_RECORD_TYPE.GOLD_BAR.id]

  const result = { coins: [], bars: [] }
  if (products.length) {
    result.coins = products.filter(({ recordTypeId }) => coinRecordTypes.includes(recordTypeId))
    result.bars = products.filter(({ recordTypeId }) => barRecordTypes.includes(recordTypeId))
  }

  return result
}

export const autocompleteParse = (text, matches) => {
  var result = []

  if (matches.length === 0) {
    result.push({
      text: text,
      highlight: false,
    })
  } else {
    if (matches[0][0] > 0) {
      result.push({
        text: text.slice(0, matches[0][0]),
        highlight: false,
      })
    }
  }

  matches.forEach(function (match, i) {
    var startIndex = match[0]
    var endIndex = match[1]

    result.push({
      text: text.slice(startIndex, endIndex),
      highlight: true,
    })

    if (i === matches.length - 1) {
      if (endIndex < text.length) {
        result.push({
          text: text.slice(endIndex, text.length),
          highlight: false,
        })
      }
    } else if (endIndex < matches[i + 1][0]) {
      result.push({
        text: text.slice(endIndex, matches[i + 1][0]),
        highlight: false,
      })
    }
  })

  return result
}

/**
 * Following functions are transforming the result of Google APIs PlacesService getDetails request into an usable address object
 */
const addressComponentsTypesMappingCH = {
  street: ['route', 'street_number'],
  postalCode: 'postal_code',
  city: 'locality',
  country: 'country',
}
// Takes the address_components array and the wanted type, returns the so called long_name of the correct component
const getLongNameByType = (arr, type) => {
  const component = find(arr, itm => itm.types.includes(type))
  if (type === 'country') {
    return get(component, 'short_name', '')
  }
  return get(component, 'long_name', '')
}
// Concats 2 or more of the address_components into one string
const concatComponents = (arr, typeArr) =>
  typeArr.reduce((str, val) => {
    const name = getLongNameByType(arr, val)
    return !!name ? str.concat(!!str ? ` ${name}` : name) : str
  }, '')
/**
 * Main function
 * Takes the PlacesService response and maps it to an address object
 */
export const mapPlacesServiceDetailsResponse = addressComponents => {
  if (!addressComponents) {
    return undefined
  }
  return Object.entries(addressComponentsTypesMappingCH).reduce(
    (obj, [k, v]) => (
      (obj[k] = Array.isArray(v)
        ? concatComponents(addressComponents, v)
        : // eslint-disable-next-line no-sequences
          getLongNameByType(addressComponents, v)),
      obj
    ),
    {}
  )
}

export const convertExchangeRateRangeToDate = range =>
  moment().subtract(parseInt(range.slice(0, range.length - 1)), range.slice(-1))

export const parseDateToStartOfMonth = value =>
  value && moment(value).isValid() ? moment(value).startOf('month') : value

export const parseDateToEndOfMonth = value =>
  value && moment(value).isValid() ? moment(value).endOf('month') : value

// Format IBAN from formstate with spacing every 4 letters for better user exp
export const formatIbanForInput = value =>
  value
    ? value
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
    : ''

// Store iban without any whitespaces or lowercase characters in formstate
export const parseIbanForInput = value => (value ? value.replaceAll(/[^\dA-Z]/g, '').trim() : '')

export const formatCurrencyForInput = value => parseFloat(value).toFixed(2)

export const parseToUpperCase = value => (value ? value.toUpperCase().trim() : '')

export const getSendOption = (sendViaPost, sendMeViaPost, sendMeViaEmail) => {
  if (sendViaPost) {
    return SEND_OPTION.TOPOST
  } else if (sendMeViaPost) {
    return SEND_OPTION.FROMPOST
  } else if (sendMeViaEmail) {
    return SEND_OPTION.FROMEMAIL
  }

  return undefined
}

const isTranslateableRegExp = /^[\w.]+$/
export const isTranslateable = value => {
  return isString(value) && isTranslateableRegExp.test(value)
}

const groupPlansReducer = (prev, current) => {
  const metalName = current.metalName.toLowerCase()

  if (prev[current.status]) {
    if (prev[current.status][metalName]) {
      prev[current.status][metalName].push(current)
    } else {
      prev[current.status][metalName] = [current]
    }
  } else {
    prev[current.status] = { [metalName]: [current] }
  }

  return prev
}

export const sortPlans = plans => {
  const result = []
  const groupedPlans = plans.reduce(groupPlansReducer, {})

  for (const status of SAVINGS_PLAN_STATUS_ORDER) {
    for (const metalName of SAVINGS_PLAN_METAL_ORDER) {
      if (groupedPlans[status]?.[metalName.toLowerCase()]) {
        result.push(
          ...groupedPlans[status][metalName.toLowerCase()].sort(
            (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
          )
        )
      }
    }
  }

  return result
}

export const performanceFormatter = new Intl.NumberFormat('de-DE', {
  style: 'percent',
  signDisplay: 'always',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const getAvailablePlans = plans =>
  Array.isArray(plans) ? plans.filter(it => !PLAN_ACTIONS_DISABLED_STATUS.includes(it.status)) : []

// undefined performance should always be last
export const sortPaymentsTableData = (orderCol, order) => value =>
  orderCol === 'performance' && order === 'desc'
    ? value[orderCol]
      ? value[orderCol]
      : -1
    : value[orderCol]

export const getRegistrationFee = (
  user = {},
  goldMonthly = 0,
  silverMonthly = 0,
  platinumMonthly = 0,
  palladiumMonthly = 0,
  conditions = []
) => {
  const maxMonthlySaving = Math.max(goldMonthly, silverMonthly, platinumMonthly, palladiumMonthly)
  if (goldMonthly === maxMonthlySaving) {
    return (
      user.entryFeeGold ??
      conditions?.find(
        condition => condition.name === METAL_TYPE_TO_CONDITION_NAME_MAP[METAL_TYPE.GOLD]
      )?.entryFee
    )
  }
  if (silverMonthly === maxMonthlySaving) {
    return (
      user.entryFeeSilver ??
      conditions?.find(
        condition => condition.name === METAL_TYPE_TO_CONDITION_NAME_MAP[METAL_TYPE.SILVER]
      )?.entryFee
    )
  }
  if (platinumMonthly === maxMonthlySaving) {
    return user.entryFeePlatinum
  }
  if (palladiumMonthly === maxMonthlySaving) {
    return user.entryFeePalladium
  }
}

export const getReferralFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('referral')
}

export const storeReferralInSessionStorage = referral => {
  sessionStorage.setItem('referral', referral)
}

export const getReferralFromSessionStorage = () => {
  return sessionStorage.getItem('referral')
}