import { pickBy } from 'lodash'
import get from 'lodash/get'
import some from 'lodash/some'

// LOGIN
export const getBackendToken = state => get(state, 'app.auth.token')
export const getBackendLoginUserData = state => get(state, 'backend.mutation.login.data.user', {})
export const isBackendLoginFetching = state =>
  get(state, 'backend.mutation.login.isFetching', false)
export const getBackendLoginError = state => get(state, 'backend.mutation.login.error', null)
export const getTwoFactorAuthMode = state => get(state, 'backend.mutation.login.data.twoFactorAuthMode', 'sms')

// VERIFY CODE
export const getBackendVerifyCodeData = state => get(state, 'backend.mutation.verifyCode.data')
export const isBackendVerifyCodeFetching = state =>
  get(state, 'backend.mutation.verifyCode.isFetching', false)
export const getBackendVerifyCodeError = state => get(state, 'backend.login.verifyCode.error', null)
export const getBackendVerifyCodeTimeFetched = state =>
  get(state, 'backend.mutation.verifyCode.timeFetched', false)

// START REGISTER
export const getBackendStartRegisterData = state =>
  get(state, 'backend.mutation.startRegister.data')
export const isBackendStartRegisterFetching = state =>
  get(state, 'backend.mutation.startRegister.isFetching', false)
export const getBackendStartRegisterError = state =>
  get(state, 'backend.mutation.startRegister.error', null)
export const getBackendStartRegisterTimeFetched = state =>
  get(state, 'backend.mutation.startRegister.timeFetched', false)

// VERIFY MAIL
export const getBackendVerifyEmailData = state => get(state, 'backend.mutation.verifyMail.data')
export const isBackendVerifyEmailFetching = state =>
  get(state, 'backend.mutation.verifyMail.isFetching', false)
export const getBackendVerifyEmailError = state =>
  get(state, 'backend.mutation.verifyMail.error', null)
export const getBackendVerifyEmailTimeFetched = state =>
  get(state, 'backend.mutation.verifyMail.timeFetched', false)

// RESEND VERIFY MAIL
export const getBackendResendVerifyMailData = state =>
  get(state, 'backend.mutation.resendMail.data')
export const isBackendResendVerifyMailFetching = state =>
  get(state, 'backend.mutation.resendMail.isFetching', false)
export const getBackendResendVerifyMailError = state =>
  get(state, 'backend.mutation.resendMail.error', null)
export const getBackendResendVerifyMailTimeFetched = state =>
  get(state, 'backend.mutation.resendMail.timeFetched', false)

// FINISH REGISTER
export const getBackendFinishRegisterData = state =>
  get(state, 'backend.mutation.finishRegister.data')
export const isBackendFinishRegisterFetching = state =>
  get(state, 'backend.mutation.finishRegister.isFetching', false)
export const getBackendFinishRegisterError = state =>
  get(state, 'backend.mutation.finishRegister.error', null)
export const getBackendFinishRegisterTimeFetched = state =>
  get(state, 'backend.mutation.finishRegister.timeFetched', false)

// RESEND VERIFY CODE
export const getBackendSendCodeData = state => get(state, 'backend.mutation.sendCode.data')
export const isBackendSendCodeFetching = state =>
  get(state, 'backend.mutation.sendCode.isFetching', false)
export const getBackendSendCodeError = state => get(state, 'backend.mutation.sendCode.error', null)

// VERIFY PHONE FOR 2FA
export const getBackendVerifyPhoneData = state => get(state, 'backend.mutation.verifyPhone.data')
export const isBackendVerifyPhoneFetching = state =>
  get(state, 'backend.mutation.verifyPhone.isFetching', false)
export const getBackendVerifyPhoneError = state =>
  get(state, 'backend.mutation.verifyPhone.error', null)
export const getBackendVerifyPhoneTimeFetched = state =>
  get(state, 'backend.mutation.verifyPhone.timeFetched', false)

// USER DATA
export const getBackendUserData = state => get(state, 'backend.query.user.data') ?? {}
export const isBackendUserFetching = state => get(state, 'backend.query.user.isFetching', false)
export const getBackendUserError = state => get(state, 'backend.query.user.error', null)
export const getBackendUserTimeFetched = state =>
  get(state, 'backend.query.user.timeFetched', false)
export const getBackendUserTimeFetchedSuccess = state =>
  get(state, 'backend.query.user.timeFetchedSuccess', false)
export const isBackendPatchUserFetching = state =>
  get(state, 'backend.mutation.patchUser.isFetching', false)

// RESET PASSWORD
export const getBackendResetPasswordData = state =>
  get(state, 'backend.mutation.resetPassword.data')
export const isBackendResetPasswordFetching = state =>
  get(state, 'backend.mutation.resetPassword.isFetching', false)
export const getBackendResetPasswordError = state =>
  get(state, 'backend.mutation.resetPassword.error', null)
export const getBackendResetPasswordTimeFetched = state =>
  get(state, 'backend.mutation.resetPassword.timeFetched')

// UPDATE PASSWORD
export const getBackendUpdatePasswordData = state =>
  get(state, 'backend.mutation.updatePassword.data')
export const isBackendUpdatePasswordFetching = state =>
  get(state, 'backend.mutation.updatePassword.isFetching', false)
export const getBackendUpdatePasswordError = state =>
  get(state, 'backend.mutation.updatePassword.error', null)
export const getBackendUpdatePasswordTimeFetched = state =>
  get(state, 'backend.mutation.updatePassword.timeFetched')

// CUSTOM FILES
export const getBackendCustomFilesData = state => get(state, 'backend.query.customFiles.data')
export const isBackendCustomFilesFetching = state =>
  get(state, 'backend.query.customFiles.isFetching', false)
export const getBackendCustomFilesError = state =>
  get(state, 'backend.query.customFiles.error', null)
export const getBackendCustomFilesTimeFetched = state =>
  get(state, 'backend.query.customFiles.timeFetched')

// SAVINGSPLANS
export const getBackendSavingsPlansData = state => get(state, 'backend.query.plans.data')
export const isBackendSavingsPlansFetching = state =>
  get(state, 'backend.query.plans.isFetching', false)
export const getBackendSavingsPlansError = state => get(state, 'backend.query.plans.error', null)
export const getBackendSavingsPlansTimeFetched = state =>
  get(state, 'backend.query.plans.timeFetched')
export const getBackendSavingsPlansTimeFetchedSuccess = state =>
  get(state, 'backend.query.plans.timeFetchedSuccess')

// CREATE PLAN REQUEST
export const getBackendCreatePlanData = state => get(state, 'backend.mutation.createPlan.data')
export const isBackendCreatePlanFetching = state =>
  get(state, 'backend.mutation.createPlan.isFetching', false)
export const getBackendCreatePlanError = state =>
  get(state, 'backend.mutation.createPlan.error', null)
export const getBackendCreatePlanTimeFetched = state =>
  get(state, 'backend.mutation.createPlan.timeFetched')

// FILE DOWNLOAD
export const getBackendDownloadFileData = state => get(state, 'backend.mutation.downloadFile.data')
export const isBackendDownloadFileFetching = state =>
  get(state, 'backend.mutation.downloadFile.isFetching', false)
export const getBackendDownloadFileError = state =>
  get(state, 'backend.mutation.downloadFile.error', null)
export const getBackendDownloadFileTimeFetched = state =>
  get(state, 'backend.mutation.downloadFile.timeFetched')

// UPLOAD DOCUMENT
export const getBackendUploadDocumentData = state =>
  get(state, 'backend.mutation.uploadDocument.data')
export const isBackendUploadDocumentFetching = state =>
  get(state, 'backend.mutation.uploadDocument.isFetching', false)
export const getBackendUploadDocumentError = state =>
  get(state, 'backend.mutation.uploadDocument.error', null)
export const getBackendUploadDocumentTimeFetched = state =>
  get(state, 'backend.mutation.uploadDocument.timeFetched')

// SEND VIA POST
export const getBackendSendViaOtherData = state => get(state, 'backend.mutation.sendViaOther.data')
export const isBackendSendViaOtherFetching = state =>
  get(state, 'backend.mutation.sendViaOther.isFetching', false)
export const getBackendSendViaOtherError = state =>
  get(state, 'backend.mutation.sendViaOther.error', null)
export const getBackendSendViaOtherTimeFetched = state =>
  get(state, 'backend.mutation.sendViaOther.timeFetched')

// PRODUCTS
export const getBackendProductsData = state => get(state, 'backend.query.products.data')
export const isBackendProductsFetching = state =>
  get(state, 'backend.query.products.isFetching', false)
export const getBackendProductsError = state => get(state, 'backend.query.products.error', null)
export const getBackendProductsTimeFetched = state =>
  get(state, 'backend.query.products.timeFetched')

// CREATE PAYOUT REQUEST
export const getBackendCreatePayoutData = state => get(state, 'backend.mutation.createPayout.data')
export const isBackendCreatePayoutFetching = state =>
  get(state, 'backend.mutation.createPayout.isFetching', false)
export const getBackendCreatePayoutError = state =>
  get(state, 'backend.mutation.createPayout.error', null)
export const getBackendCreatePayoutTimeFetched = state =>
  get(state, 'backend.mutation.createPayout.timeFetched')

// CANCEL PLAN REQUEST
export const getBackendCancelPlanData = state => get(state, 'backend.mutation.cancelPlan.data')
export const isBackendCancelPlanFetching = state =>
  get(state, 'backend.mutation.cancelPlan.isFetching', false)
export const getBackendCancelPlanError = state =>
  get(state, 'backend.mutation.cancelPlan.error', null)
export const getBackendCancelPlanTimeFetched = state =>
  get(state, 'backend.mutation.cancelPlan.timeFetched')

// UPLOAD VERIFICATION DOCUMENT
export const getBackendUploadVerificationDocumentData = state =>
  get(state, 'backend.mutation.uploadVerificationDocument.data')
export const isBackendUploadVerificationDocumentFetching = state =>
  get(state, 'backend.mutation.uploadVerificationDocument.isFetching', false)
export const getBackendUploadVerificationDocumentError = state =>
  get(state, 'backend.mutation.uploadVerificationDocument.error', null)

// ADJUST PLAN
export const getBackendAdjustPlanData = state => get(state, 'backend.mutation.adjustPlan.data')
export const isBackendAdjustPlanFetching = state =>
  get(state, 'backend.mutation.adjustPlan.isFetching', false)
export const getBackendAdjustPlanError = state =>
  get(state, 'backend.mutation.adjustPlan.error', null)
export const getBackendAdjustPlanTimeFetched = state =>
  get(state, 'backend.mutation.adjustPlan.timeFetched')

// REQUESTS
export const getBackendRequestsData = state => get(state, 'backend.query.requests.data')
export const isBackendRequestsFetching = state =>
  get(state, 'backend.query.requests.isFetching', false)
export const getBackendRequestsError = state => get(state, 'backend.query.requests.error', null)
export const getBackendRequestsTimeFetched = state =>
  get(state, 'backend.query.requests.timeFetched')

// CHANGE PLAN NAME
export const getBackendChangePlanNameData = state =>
  get(state, 'backend.mutation.changePlanName.data')
export const isBackendChangePlanNameFetching = state =>
  get(state, 'backend.mutation.changePlanName.isFetching', false)
export const getBackendChangePlanNameError = state =>
  get(state, 'backend.mutation.changePlanName.error', null)
export const getBackendChangePlanNameTimeFetched = state =>
  get(state, 'backend.mutation.changePlanName.timeFetched')

// EXCHANGE RATES
export const getBackendExchangeRatesData = state =>
  get(state, 'backend.query.exchangeRates.data') ?? []
export const isBackendExchangeRatesFetching = state =>
  get(state, 'backend.query.exchangeRates.isFetching', false)
export const getBackendExchangeRatesError = state =>
  get(state, 'backend.query.exchangeRates.error', null)
export const getBackendExchangeRatesTimeFetched = state =>
  get(state, 'backend.query.exchangeRates.timeFetched')

// CHANGE SECURITY DETAILS
export const getBackendChangeSecurityDetailsData = state =>
  get(state, 'backend.mutation.changeSecurityDetails.data')
export const isBackendChangeSecurityDetailsFetching = state =>
  get(state, 'backend.mutation.changeSecurityDetails.isFetching', false)
export const getBackendChangeSecurityDetailsError = state =>
  get(state, 'backend.mutation.changeSecurityDetails.error', null)
export const getBackendChangeSecurityDetailsTimeFetched = state =>
  get(state, 'backend.mutation.changeSecurityDetails.timeFetched')

// CONFIRM NEW MAIL
export const getBackendConfirmNewMailData = state =>
  get(state, 'backend.mutation.confirmNewMail.data')
export const isBackendConfirmNewMailFetching = state =>
  get(state, 'backend.mutation.confirmNewMail.isFetching', false)
export const getBackendConfirmNewMailError = state =>
  get(state, 'backend.mutation.confirmNewMail.error', null)

// CONFIRM NEW PHONE
export const getBackendConfirmNewPhoneData = state =>
  get(state, 'backend.mutation.confirmNewPhone.data')
export const isBackendConfirmNewPhoneFetching = state =>
  get(state, 'backend.mutation.confirmNewPhone.isFetching', false)
export const getBackendConfirmNewPhoneError = state =>
  get(state, 'backend.mutation.confirmNewPhone.error', null)

// INVOICES
export const getBackendInvoicesData = state => get(state, 'backend.query.invoices.data') ?? []
export const isBackendInvoicesFetching = state =>
  get(state, 'backend.query.invoices.isFetching', false)
export const getBackendInvoicesError = state => get(state, 'backend.query.invoices.error', null)

// VERIFY REQUEST 2FA
export const isBackendVerifyRequest2FAFetching = state =>
  get(state, 'backend.mutation.verifyRequest2FA.isFetching')

// CUSTOMER NOTIFICATIONS
export const getBackendCustomerMessagesData = state =>
  get(state, 'backend.query.customerMessages.data')

// SET CUSTOMER NOTIFICATIONS
export const getBackendSetCustomerMessagesDate = state =>
  get(state, 'backend.mutation.setCustomerMessages.data')
export const isBackendSetCustomerMessagesFetching = state =>
  get(state, 'backend.mutation.setCustomerMessages.isFetching', false)

// REQUEST CALLBACK
export const isBackendRequestCallbackFetching = state =>
  get(state, 'backend.mutation.requestCallback.isFetching', false)

// CREATE CUSTOMER MESSAGE
export const getBackendCreateCustomerMessageData = state =>
  get(state, 'backend.mutation.createCustomerMessage.data')
export const isBackendCreateCustomerMessageFetching = state =>
  get(state, 'backend.mutation.createCustomerMessage.isFetching', false)

// COUNTRY DEPARTMENT
export const getBackendCountryDepartmentData = state =>
  get(state, 'backend.query.countryDepartment.data')

  // COUNTRY DEPARTMENT
export const getBackendCountryDepartmentByCountryCodeData = state =>
  get(state, 'backend.query.countryDepartmentByCountryCode.data')

// VERIFY VOUCHER
export const getBackendVoucherData = state => code =>
  get(state, `backend.mutation.verifyVoucher.${code.toLowerCase()}.data`)
export const getBackendVoucherDataAllSuccess = state =>
  pickBy(get(state, 'backend.mutation.verifyVoucher'), voucher => voucher.error === null)
export const isBackendVoucherFetching = state => code =>
  get(state, `backend.mutation.verifyVoucher.${code.toLowerCase()}.isFetching`)

// METALS
export const getBackendMetalsData = state => get(state, 'backend.query.metals.data')

// DELETE REQUEST
export const isBackendDeleteRequestFetching = state =>
  get(state, 'backend.mutation.deleteRequest.isFetching')

// IS SOMETHING FETCHING?
export const isBackendFetching = state =>
  some(
    state.backend,
    backendState =>
      some(backendState.query, obj => obj?.isFetching) ||
      some(backendState.mutation, obj => obj?.isFetching)
  )

// GOOGLE PLACES API
export const getGooglePlacesApiData = state => get(state, 'backend.query.googlePlaces.data')

// MIGRATE
export const getBackendMigrateData = state => get(state, 'backend.mutation.migrate.data')
export const isBackendMigrateFetching = state => get(state, 'backend.mutation.migrate.isFetching')
export const getBackendMigrateError = state => get(state, 'backend.mutation.migrate.error')

// VALIDATE IBAN
export const isBackendValiadateIbanFetching = state =>
  get(state, 'backend.query.validateIban.isFetching')
export const getBackendValidateIbanData = state => get(state, 'backend.query.validateIban.data')

// ACCOUNT BRAND
export const getBackendAccountBrandData = state => get(state, 'backend.query.accountBrand.data')

// SAVING PLAN LOGS
export const getBackendSavingPlanLogsData = state => get(state, 'backend.query.savingPlanLogs.data')
export const isBackendSavingPlanLogsFetching = state =>
  get(state, 'backend.query.savingPlanLogs.isFetching', false)
export const getBackendSavingPlanLogsError = state =>
  get(state, 'backend.query.savingPlanLogs.error', null)
export const getBackendSavingPlanLogsTimeFetched = state =>
  get(state, 'backend.query.savingPlanLogs.timeFetched')
export const getBackendSavingPlanLogsTimeFetchedSuccess = state =>
  get(state, 'backend.query.savingPlanLogs.timeFetchedSuccess')

// PERFORMANCES
export const getBackendPerformancesData = state => get(state, 'backend.query.performances.data')
export const isBackendPerformancesFetching = state =>
  get(state, 'backend.query.performances.isFetching', false)
export const getBackendPerformancesError = state =>
  get(state, 'backend.query.performances.error', null)
export const getBackendPerformancesTimeFetched = state =>
  get(state, 'backend.query.performances.timeFetched')
export const getBackendPerformancesTimeFetchedSuccess = state =>
  get(state, 'backend.query.performances.timeFetchedSuccess')

// DELETE ADJUSTMENT
export const isBackendDeleteAdjustmentFetching = state =>
  get(state, 'backend.mutation.deleteAdjustment.isFetching', false)

// Conditions
export const getBackendConditionsData = state => get(state, 'backend.query.conditions.data')

// INITIATE ONLINE IDENT (LOGGED IN FLOW)
export const getBackendInitiateOnlineIdentData = state =>
  get(state, 'backend.mutation.initiateOnlineIdent.data')
export const isBackendInitiateOnlineIdentFetching = state =>
  get(state, 'backend.mutation.initiateOnlineIdent.isFetching', false)

// REGISTER AND INITIATE ONLINE IDENT (NO LOGIN FLOW)
export const getBackendRegisterAndInitiateOnlineIdentData = state =>
  get(state, 'backend.mutation.registerAndInitiateOnlineIdent.data')
export const isBackendRegisterAndInitiateOnlineIdentFetching = state =>
  get(state, 'backend.mutation.registerAndInitiateOnlineIdent.isFetching', false)

// CREATE CONSENT SAVINGS PLAN
export const getDocumentIdConsentSavingsPlan = state =>
  get(state, 'backend.mutation.createConsentSavingsPlan.data')

// GET CUSTOM FILE DOCUMENT VERSION ID
export const isBackendDocumentVersionIdFetching = state =>
  get(state, 'backend.query.documentVersionId.isFetching')
export const getBackendDocumentVersionIdData = state =>
  get(state, 'backend.query.documentVersionId.data')
