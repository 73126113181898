import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import find from 'lodash/find'
import moment from 'moment'
import { useState } from 'react'
import { connect } from 'react-redux'

import { CompleteProfile } from '..'
import { SALUTATION_OPTIONS } from '../../config'
import { useTranslate } from '../../lib/translate'
import { getBackendUserData } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },

  card: {
    height: '100%',
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
}))

export const DisplayField = ({ value, ...rest }) => (
  <Box mb={3}>
    <TextField
      disabled
      fullWidth
      InputProps={{
        disableUnderline: true,
        style: {
          color: 'black',
        },
      }}
      InputLabelProps={{ shrink: true }}
      value={value || ''}
      {...rest}
    />
  </Box>
)

const UserProfilePage = ({ user }) => {
  const translate = useTranslate()
  const classes = useStyles()

  const [showEdit, setShowEdit] = useState(false)

  const {
    salutation,
    title,
    firstName,
    lastName,
    dateOfBirth,
    nationality,
    street,
    postalCode,
    city,
    country,
  } = user || {}

  const salutationKey = find(SALUTATION_OPTIONS, { value: salutation })?.key

  return (
    <>
      <Collapse in={!showEdit} unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => setShowEdit(true)}
              className={classes.editButton}
            >
              {translate('userProfile.updateButton')}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <CardHeader title={translate('userProfile.personalInfo')} />
              <CardContent>
                <DisplayField
                  label={translate('formFields.salutation')}
                  value={salutationKey && translate(salutationKey)}
                />
                <DisplayField label={translate('formFields.title')} value={title} />
                <DisplayField label={translate('formFields.firstName')} value={firstName} />
                <DisplayField label={translate('formFields.lastName')} value={lastName} />
                <DisplayField
                  label={translate('formFields.dateOfBirth')}
                  value={
                    dateOfBirth
                      ? moment(dateOfBirth).format(translate('formFields.dateFormat'))
                      : ''
                  }
                />
                <DisplayField label={translate('formFields.nationality')} value={nationality} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <CardHeader title={translate('userProfile.address')} />
              <CardContent>
                <DisplayField label={translate('formFields.street')} value={street} />
                <DisplayField label={translate('formFields.postCode')} value={postalCode} />
                <DisplayField label={translate('formFields.city')} value={city} />
                <DisplayField label={translate('formFields.country')} value={country} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={showEdit} unmountOnExit>
        <CompleteProfile isUpdate onEndUpdate={() => setShowEdit(false)} />
      </Collapse>
    </>
  )
}

const mapStateToProps = state => ({
  user: getBackendUserData(state),
})

export default connect(mapStateToProps)(UserProfilePage)
