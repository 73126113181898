import MomentUtils from '@date-io/moment'
import { CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'
import { React } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import {
  CustomRoute,
  CustomSnackbar,
  EmailConfirmationSentPage,
  EmailVerificationPage,
  FinishRegistrationPage,
  ForgotPasswordPage,
  LandingPageRedirect,
  LoginPage,
  NestedFormNavigation,
  NestedHomeNavigation,
  NotFoundPage,
  PrivateRoute,
  RegisterPage,
  ScrollToTop,
  TwoFactorAuthenticationPage,
  UpdatePasswordPage,
  VerifyPhoneForAuthPage,
} from './component'
import BgbPage from './component/page/BgbPage'
import { EMAIL_VERIFICATION_TYPES } from './component/page/EmailVerificationPage'
import ImpressumPage from './component/page/ImpressumPage'
import PrivacyInfoPage from './component/page/PrivacyInfoPage'
import PrivacyPolicyPage from './component/page/PrivacyPolicyPage'
import TOSPage from './component/page/TOSPage'
import { ROUTES } from './config'
import { TranslationProvider, useTranslate } from './lib/translate'
import createStore from './redux'
import CustomThemeProvider from './theme/CustomThemeProvider'
import NoLoginCreatePlanNavigation from './component/navigation/formFlows/NoLoginCreatePlanNavigation'

const AppContent = () => {
  const translate = useTranslate()
  return (
    <>
      <ScrollToTop />
      <Helmet
        titleTemplate={`%s | ${translate('documentTitle.default')}`}
        defaultTitle={translate('documentTitle.default')}
      />
      <Switch>
        <CustomRoute exact path="/">
          <LandingPageRedirect />
        </CustomRoute>
        <CustomRoute path={ROUTES.LOGIN} title={translate('documentTitle.login')}>
          <LoginPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.TWO_FA}>
          <TwoFactorAuthenticationPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.REGISTER} title={translate('documentTitle.register')}>
          <RegisterPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.CONFIRMATION_SENT} title={translate('documentTitle.register')}>
          <EmailConfirmationSentPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.VERIFY_MAIL} title={translate('documentTitle.register')}>
          <EmailVerificationPage type={EMAIL_VERIFICATION_TYPES.REGISTER} />
        </CustomRoute>
        <CustomRoute path={ROUTES.FINISH_REGISTRATION} title={translate('documentTitle.register')}>
          <FinishRegistrationPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.SET_UP_TWO_FA} title={translate('documentTitle.register')}>
          <VerifyPhoneForAuthPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.FORGOT_PASSWORD}>
          <ForgotPasswordPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.RESET_PASSWORD}>
          <UpdatePasswordPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.CONFIRM_NEW_MAIL}>
          <EmailVerificationPage type={EMAIL_VERIFICATION_TYPES.CONFIRM_NEW_MAIL} />
        </CustomRoute>
        <CustomRoute path={ROUTES.IMPRESSUM} title={translate('documentTitle.impressum')}>
          <ImpressumPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.TOS} title={translate('documentTitle.tos')}>
          <TOSPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.PRIVACY_POLICY} title={translate('documentTitle.privacyPolicy')}>
          <PrivacyPolicyPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.PRIVACY_INFO} title={translate('documentTitle.privacyInfo')}>
          <PrivacyInfoPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.BGB} title={translate('documentTitle.bgb')}>
          <BgbPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.NO_LOGIN_PLAN} title={translate('documentTitle.createAbo')}>
          <NoLoginCreatePlanNavigation />
        </CustomRoute>
        {/*
         *  ADD NON-PRIVATE ROUTES ABOVE,
         *  FOR PRIVATE ROUTES USE OUR CUSTOM COMPONENTS
         *  AND OUR NESTED NAVIGATION!
         */}
        <PrivateRoute path={ROUTES.HOME}>
          <NestedHomeNavigation />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.FORM}>
          <NestedFormNavigation />
        </PrivateRoute>
        {/**
         * IF THE USER TRIES TO REACH A NON-EXISTING ROUTE
         */}
        <CustomRoute path="*">
          <NotFoundPage />
        </CustomRoute>
      </Switch>
      {/**
       * Custom snackbar for our Notification handling
       * use our useSnackbar hook to display notifications
       * see LoginPage for an example
       */}
      <CustomSnackbar />
    </>
  )
}

const { store, persistor } = createStore()

const App = () => {
  return (
    <Provider store={store}>
      <TranslationProvider>
        <HelmetProvider>
          <CustomThemeProvider>
            <PersistGate loading={null} persistor={persistor}>
              <CssBaseline />
              <BrowserRouter>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <SnackbarProvider maxSnack={3}>
                    <AppContent />
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </BrowserRouter>
            </PersistGate>
          </CustomThemeProvider>
        </HelmetProvider>
      </TranslationProvider>
    </Provider>
  )
}

export default App
