import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AddIcon from '@material-ui/icons/Add'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import RemoveIcon from '@material-ui/icons/Remove'
import filter from 'lodash/filter'
import find from 'lodash/find'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { ActivatePlanDialog, InfoDialog, PaymentsTable } from '..'
import {
  PLAN_ACTIONS_DISABLED_STATUS,
  PLAN_INACTIVE_STATUS,
  ROUTES,
  SAVINGS_PLAN_STATUS,
  SAVING_PLAN_LOG_STATUS,
} from '../../config'
import useCurrency from '../../lib/customHooks/useCurrency'
import useDialog from '../../lib/customHooks/useDialog'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'
import { useSpecialSumStyles } from '../../lib/customHooks/useSpecialSumStyles'
import { formatGram } from '../../lib/miscellaneous'
import { useTranslate } from '../../lib/translate'
import { performanceFormatter } from '../../lib/util'
import {
  fetchChangePlanNameActions,
  storeCreateDepositValues,
  storePlanAdjustmentValues,
  storeRequestPayoutValues,
} from '../../redux/actions'
import {
  getBackendChangePlanNameData,
  getBackendInvoicesData,
  getBackendMetalsData,
  getBackendSavingPlanLogsData,
  getBackendSavingsPlansData,
  isBackendChangePlanNameFetching,
  isBackendInvoicesFetching,
  isBackendSavingsPlansFetching,
} from '../../redux/selectors'
import ApplyVoucherToSavingsPlanDialog from '../common/ApplyVoucherToSavingsPlanDialog'
import ChartContainer from '../common/ChartContainer'
import DeleteAdjustmentDialog from '../common/DeleteAdjustmentDialog'
import { TextFieldAdapter } from '../common/FormFields'
import { SkeletonPlanDetailsOverview } from '../common/SkeletonComponents'
import { WORKFLOW_PAGE as DEPOSIT_FLOW_PAGE_1 } from './createDeposit/CreateDepositPage1'
import { WORKFLOW_PAGE_1 as ADJUST_FLOW_PAGE_1 } from './planAdjustment/PlanAdjustmentPage1'
import { WORKFLOW_PAGE as PAYOUT_FLOW_PAGE_1 } from './requestPayout/RequestPayoutPage1'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  overviewText: {
    fontWeight: theme.typography.fontWeightBold,
    borderBottomStyle: 'solid',
    borderWidth: 2,
    paddingBottom: theme.spacing(),
    borderColor: theme.palette.secondary.main,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  totalPerformance: {
    color: props =>
      props?.totalPerformance > 0
        ? theme.palette.success.main
        : props?.totalPerformance < 0
        ? theme.palette.error.main
        : theme.palette.text,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  planInactiveStatus: {
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(),
    },
  },
  planName: {
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 100,
    },
  },
  errorButton: {
    color: theme.palette.error.main,
  },
}))

const _PlanOverviewSumsGrid = ({ plan, isFetching, savingPlanLog }) => {
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const { currentValue, totalPerformance, depositAmount, gram, savingsRate } = plan || {}
  const { newSavingPlanRate, changeDate, id: savingPlanLogId } = savingPlanLog || {}
  const formattedChangeDate = moment(changeDate).format(translate('formFields.dateFormat'))
  const [showDialogSumDepot, { openDialog: openDialogSumDepot, closeDialog: closeDialogSumDepot }] =
    useDialog()
  const [
    showDialogSumDeposit,
    { openDialog: openDialogSumDeposit, closeDialog: closeDialogSumDeposit },
  ] = useDialog()
  const [
    showDialogDeleteAdjustment,
    { openDialog: openDialogDeleteAdjustment, closeDialog: closeDialogDeleteAdjustment },
  ] = useDialog()

  const totalValue = displayCurrency(currentValue)
  const specialClasses = useSpecialSumStyles({
    amountLength: totalValue.replace('.', '').length,
  })
  const classes = { ...useStyles({ totalPerformance }), ...specialClasses }
  const changePlanInfo =
    newSavingPlanRate !== undefined
      ? newSavingPlanRate === 0
        ? translate('dashboard.pausePlanned')
        : translate('dashboard.newRateValue', {
            value: displayCurrency(newSavingPlanRate),
          })
      : undefined

  if (isFetching) {
    return <SkeletonPlanDetailsOverview />
  }

  return (
    <>
      <Grid item xs={12} container spacing={1} alignItems="flex-start">
        <Grid item xs={6} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h3" className={`${classes.bold} ${classes.amount}`} noWrap>
              {totalValue}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              noWrap
              paragraph
              component="div"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {translate('dashboard.sumDepot')}
              <IconButton style={{ marginLeft: 10 }} size="small" onClick={openDialogSumDepot}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              {gram || gram === 0 ? formatGram(gram) : translate('dashboard.notAvailable')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" paragraph>
              {translate('dashboard.sumMetal')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">{displayCurrency(savingsRate)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" paragraph style={{ display: 'flex' }}>
              {translate('table.rate')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.amount} noWrap>
              {displayCurrency(depositAmount)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              noWrap
              paragraph
              component="div"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {translate('dashboard.sumDeposit')}
              <IconButton style={{ marginLeft: 10 }} size="small" onClick={openDialogSumDeposit}>
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.totalPerformance}>
              {totalPerformance ? performanceFormatter.format(totalPerformance / 100) : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" paragraph style={{ display: 'flex' }}>
              {translate('savingsPlans.totalChange')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {changePlanInfo && (
        <Box>
          <Typography variant="h6" className={classes.bold}>
            {translate('planAdjustment.headingSection')}
          </Typography>
          <Box display="flex">
            <Box mr={5}>
              <Typography noWrap variant="body2">
                {changePlanInfo}
              </Typography>
              <Typography noWrap variant="body2" component="p">
                {translate('dashboard.fromDate', { date: formattedChangeDate })}
              </Typography>
            </Box>
            <Button
              startIcon={<DeleteIcon />}
              className={classes.errorButton}
              styles={{ backgroundColor: 'error.main' }}
              onClick={openDialogDeleteAdjustment}
              disableRipple
            >
              {translate('planAdjustment.deleteAdjustment')}
            </Button>
          </Box>
        </Box>
      )}

      {/* Dialogs */}
      <DeleteAdjustmentDialog
        open={showDialogDeleteAdjustment}
        onClose={closeDialogDeleteAdjustment}
        changePlanInfo={changePlanInfo}
        formattedChangeDate={formattedChangeDate}
        savingPlanLogId={savingPlanLogId}
      />
      <InfoDialog
        open={showDialogSumDepot}
        onClose={closeDialogSumDepot}
        title={translate('dashboard.sumDepot')}
        infoText={
          <Typography>
            {translate('dashboard.depotValueWithoutMarge', {
              value: displayCurrency(plan?.depotValueWithoutMarge),
            })}
          </Typography>
        }
      />
      <InfoDialog
        open={showDialogSumDeposit}
        onClose={closeDialogSumDeposit}
        title={translate('dashboard.sumDeposit')}
        infoText={translate('dashboard.depositInfo')}
      />
    </>
  )
}

const mapStateToPropsSumsGrid = state => ({
  metals: getBackendMetalsData(state),
})

const PlanOverviewSumsGrid = connect(mapStateToPropsSumsGrid)(_PlanOverviewSumsGrid)

const ChangePlanNameForm = ({
  onSubmit,
  isFetchingChangePlanName,
  isFetchingPlans,
  setOpenForm,
}) => {
  const classes = useStyles()
  const ref = useRef(null)

  useEffect(() => {
    ref.current.focus()
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.nameContainer}>
          <Field
            component={TextFieldAdapter}
            name="newName"
            variant="standard"
            style={{ marginBottom: 0 }}
            inputRef={ref}
          />
          {isFetchingChangePlanName || isFetchingPlans ? (
            <CircularProgress color="inherit" size={20} className={classes.progressIcon} />
          ) : (
            <>
              <IconButton onClick={handleSubmit} size="small" color="primary">
                <CheckCircleOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={() => setOpenForm(false)} size="small" color="primary">
                <CancelOutlinedIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </form>
      )}
    />
  )
}

const PlanOverview = ({
  plan,
  changeName,
  isFetchingChangePlanName,
  successChangeName,
  isFetchingPlans,
  savingsPlanId,
  savingPlanLog,
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const isUnderaged = useIsUnderaged()
  const [openForm, setOpenForm] = useState(false)

  const [
    activateDialogState,
    { openDialog: openActivateDialog, closeDialog: closeActivateDialog },
  ] = useDialog()

  const [
    applyVoucherDialogState,
    { openDialog: openApplyVoucherDialog, closeDialog: closeApplyVoucherDialog },
  ] = useDialog()

  useEffect(() => {
    if (!isFetchingChangePlanName && !isFetchingPlans && successChangeName) {
      setOpenForm(false)
    }
  }, [isFetchingChangePlanName, successChangeName, isFetchingPlans])

  const handleSubmit = values => {
    if (values.newName) {
      changeName({ id: plan?.id, name: values.newName })
    }
  }

  const handlePayoutRedirect = () => {
    dispatch(storeRequestPayoutValues(PAYOUT_FLOW_PAGE_1)({ savingsPlanId }))
    history.push(ROUTES.REQUEST_PAYOUT_STEP_TWO)
  }

  const handleDepositRedirect = () => {
    dispatch(storeCreateDepositValues(DEPOSIT_FLOW_PAGE_1)({ savingsPlanId }))
    history.push(ROUTES.CREATE_DEPOSIT_STEP_TWO)
  }

  const handleAdjustPlanRedirect = () => {
    dispatch(storePlanAdjustmentValues(ADJUST_FLOW_PAGE_1)({ savingsPlanId }))
    history.push(ROUTES.PLAN_ADJUSTMENT_STEP_TWO)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={5} container spacing={2}>
        <Grid item xs={12} container spacing={2} alignItems="center">
          <Grid item xs={3} sm="auto">
            <Typography variant="h6">{translate('savingsPlans.plan')}</Typography>
          </Grid>
          <Grid item xs={9} sm="auto">
            {openForm ? (
              <ChangePlanNameForm
                onSubmit={handleSubmit}
                isFetchingChangePlanName={isFetchingChangePlanName}
                isFetchingPlans={isFetchingPlans}
                setOpenForm={setOpenForm}
              />
            ) : (
              <Box className={classes.nameContainer}>
                <Box className={classes.nameContainer}>
                  <Typography variant="h6" className={classes.planName}>
                    {plan?.customName ?? plan?.name}
                  </Typography>
                  <IconButton onClick={() => setOpenForm(true)} size="small" color="primary">
                    <CreateIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Box display="flex">
                  {(plan?.customName || PLAN_INACTIVE_STATUS.includes(plan?.status)) && (
                    <Box>
                      <Grid container>
                        {plan?.customName && (
                          <Grid item xs={12} sm="auto">
                            <Typography variant="caption" color="textSecondary">
                              {plan?.name}
                            </Typography>
                          </Grid>
                        )}
                        {PLAN_INACTIVE_STATUS.includes(plan?.status) && (
                          <Grid item xs={12} sm="auto">
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              className={classes.planInactiveStatus}
                            >
                              {translate(`planStatus.${plan?.status}`)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            className={classes.marginBottom}
            alignItems="center"
          >
            <Grid item xs={12} sm="auto">
              <Typography variant="h6" display="inline" className={classes.overviewText}>
                {translate('savingsPlans.overview')}
              </Typography>
            </Grid>
            {plan?.status === SAVINGS_PLAN_STATUS.PAUSED && (
              <Grid item xs={12} sm="auto">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AccountBalanceIcon />}
                  onClick={openActivateDialog}
                  fullWidth
                  disabled={isUnderaged}
                >
                  {translate('actions.activate')}
                </Button>
              </Grid>
            )}
            <Grid item xs={6} sm="auto">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<RemoveIcon />}
                onClick={handlePayoutRedirect}
                disabled={PLAN_ACTIONS_DISABLED_STATUS.includes(plan?.status) || isUnderaged}
                fullWidth
              >
                {translate('transactions.toPayout')}
              </Button>
            </Grid>
            <Grid item xs={6} sm="auto">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleDepositRedirect}
                disabled={PLAN_ACTIONS_DISABLED_STATUS.includes(plan?.status)}
                fullWidth
              >
                {translate('transactions.toDeposit')}
              </Button>
            </Grid>
            <Grid item xs={6} sm="auto">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleAdjustPlanRedirect}
                disabled={PLAN_ACTIONS_DISABLED_STATUS.includes(plan?.status) || isUnderaged}
                fullWidth
              >
                {translate('transactions.toAdjust')}
              </Button>
            </Grid>
            <Grid item xs={6} sm="auto">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                onClick={openApplyVoucherDialog}
                disabled={PLAN_ACTIONS_DISABLED_STATUS.includes(plan?.status) || isUnderaged}
                fullWidth
              >
                {translate('transactions.toApplyVoucher')}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PlanOverviewSumsGrid plan={plan} savingPlanLog={savingPlanLog} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={7}>
        <ChartContainer />
      </Grid>
      <ActivatePlanDialog open={activateDialogState} onClose={closeActivateDialog} plan={plan} />
      <ApplyVoucherToSavingsPlanDialog
        open={applyVoucherDialogState}
        onClose={closeApplyVoucherDialog}
      />
    </Grid>
  )
}

PlanOverview.propTypes = {
  hasPlans: PropTypes.bool,
}

const SavingsPlanDetailsPage = ({
  allPlans,
  changeName,
  isFetchingChangePlanName,
  successChangeName,
  isFetchingPlans,
  invoices,
  isFetchingInvoices,
  savingPlanLogs,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { id } = useParams()

  const plan = find(allPlans, { id })
  const relatedInvoices = filter(invoices, { savingsPlan: id })
  const savingPlanLog = savingPlanLogs
    .filter(({ status }) => status === SAVING_PLAN_LOG_STATUS.OPEN)
    .sort((a, b) => new Date(a.changeDate) - new Date(b.changeDate))
    .find(({ savingPlanId }) => savingPlanId === id)

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PlanOverview
          plan={plan}
          savingPlanLog={savingPlanLog}
          changeName={changeName}
          isFetchingChangePlanName={isFetchingChangePlanName}
          successChangeName={successChangeName}
          isFetchingPlans={isFetchingPlans}
          savingsPlanId={id}
        />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.bold} paragraph>
            {translate('savingsPlans.activities')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PaymentsTable data={relatedInvoices} loading={isFetchingInvoices} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  allPlans: getBackendSavingsPlansData(state),
  isFetchingPlans: isBackendSavingsPlansFetching(state),
  isFetchingChangePlanName: isBackendChangePlanNameFetching(state),
  successChangeName: getBackendChangePlanNameData(state),
  isFetchingInvoices: isBackendInvoicesFetching(state),
  invoices: getBackendInvoicesData(state),
  savingPlanLogs: getBackendSavingPlanLogsData(state) ?? [],
})

const mapDispatchToProps = {
  changeName: fetchChangePlanNameActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(SavingsPlanDetailsPage)
