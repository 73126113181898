import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CustomSaveButton, FormLayout, TextFieldAdapter } from '../..'
import { METAL_TYPE, ROUTES } from '../../../config'
import useCurrency from '../../../lib/customHooks/useCurrency'
import { useTranslate } from '../../../lib/translate'
import useSnackbar from '../../../lib/useSnackbar'
import { formatIbanForInput, parseIbanForInput, parseToUpperCase } from '../../../lib/util'
import { required } from '../../../lib/validation'
import { fetchValidateIbanActions, storeRequestPayoutValues } from '../../../redux/actions'
import {
  getBackendSavingsPlansData,
  getBackendUserData,
  getFormRequestPayoutPageData,
  isBackendValiadateIbanFetching,
} from '../../../redux/selectors'
import { RadioAdapter } from '../../common/FormFields'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_1 } from './RequestPayoutPage1'

export const WORKFLOW_PAGE = 'pageTwo'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  greyText: {
    color: theme.palette.text.hint,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  inlineButton: {
    marginBottom: '1.25rem',
    marginLeft: '1rem',
  },
}))

const PAYOUT_OPTIONS = {
  FIAT: 'fiatWithdrawal',
  PHYSICAL: 'physicalDelivery',
}

const RequestPayoutPageTwo = ({
  formValues,
  plan,
  storeValues,
  userData,
  isFetching,
  validateIban,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const history = useHistory()
  const showSnackbar = useSnackbar()

  const onSubmit = ({ payoutOption, ...values }) => {
    storeValues({
      ...values,
      physicalDelivery: payoutOption === PAYOUT_OPTIONS.PHYSICAL,
      fiatWithdrawal: payoutOption === PAYOUT_OPTIONS.FIAT,
      payoutOption,
    })
    history.push(
      payoutOption === PAYOUT_OPTIONS.FIAT
        ? ROUTES.REQUEST_PAYOUT_STEP_THREE
        : ROUTES.REQUEST_PAYOUT_STEP_FOUR
    )
  }

  const handleIbanValidationResponse = ([response], state, tools) => {
    const { bic, bank, result } = response.data.validateIban ?? {}

    if (!result) {
      showSnackbar('notification.invalidIban', 'error')
      tools.changeValue(state, 'bic', () => '')
      tools.changeValue(state, 'bank', () => '')
    } else {
      tools.changeValue(state, 'bic', () => bic)
      tools.changeValue(state, 'bank', () => bank)
    }
  }

  return (
    <FormLayout title={translate('payoutRequest.title')} wideLayout showClose isSignedIn>
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={formValues}
          mutators={{ handleIbanValidationResponse }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3} ml={5}>
                <Typography variant="h6" paragraph className={classes.boldText}>
                  {translate('cancelPlans.availableAmount')}
                </Typography>
                <Typography variant="h4" paragraph color="primary" className={classes.boldText}>
                  {displayCurrency(plan?.currentValue)}
                </Typography>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="h6" paragraph className={classes.boldText}>
                      {translate('payoutRequest.selectPayoutType')}
                    </Typography>
                    <Field name="payoutOption" component={RadioAdapter} validate={required}>
                      <FormControlLabel
                        value={PAYOUT_OPTIONS.FIAT}
                        control={<Radio />}
                        label={translate('formFields.fiatWithdrawal')}
                      />
                      <Collapse in={values?.payoutOption === PAYOUT_OPTIONS.FIAT} unmountOnExit>
                        <Typography variant="body1" paragraph className={classes.greyText}>
                          {translate('payoutRequest.subtitle')}
                        </Typography>

                        <Box mt={3}>
                          <Field
                            name="bankAccountOwner"
                            disabled
                            initialValue={`${userData?.firstName} ${userData?.lastName}`}
                            component={TextFieldAdapter}
                            validate={required}
                            fullWidth
                            label={translate('formFields.accountOwner')}
                          />
                          <Box display="flex" flexDirection="row">
                            <Field
                              name="iban"
                              validate={required}
                              component={TextFieldAdapter}
                              format={formatIbanForInput}
                              parse={parseIbanForInput}
                              fullWidth
                              label={translate('formFields.iban')}
                            />
                            <CustomSaveButton
                              disabled={!values?.iban}
                              onClick={() =>
                                validateIban(
                                  values?.iban,
                                  form.mutators.handleIbanValidationResponse
                                )
                              }
                              loading={isFetching}
                              className={classes.inlineButton}
                            >
                              {translate('actions.verify')}
                            </CustomSaveButton>
                          </Box>
                          <Field
                            name="bank"
                            validate={required}
                            component={TextFieldAdapter}
                            fullWidth
                            label={translate('formFields.bankName')}
                          />
                          <Field
                            name="bic"
                            validate={required}
                            component={TextFieldAdapter}
                            parse={parseToUpperCase}
                            fullWidth
                            label={translate('formFields.bic')}
                          />
                        </Box>
                      </Collapse>
                      {METAL_TYPE.GOLD === plan?.metalName?.toLowerCase() && (
                        <Box mt={2}>
                          <FormControlLabel
                            value={PAYOUT_OPTIONS.PHYSICAL}
                            control={<Radio />}
                            label={translate('cancelPlans.metalDeliveryText')}
                          />
                        </Box>
                      )}
                    </Field>
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton disabled={!values?.payoutOption}>
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => {
  const savingsPlanId = get(getFormRequestPayoutPageData(WORKFLOW_PAGE_1)(state), 'savingsPlanId')
  return {
    formValues: getFormRequestPayoutPageData(WORKFLOW_PAGE)(state),
    plan: find(getBackendSavingsPlansData(state), { id: savingsPlanId }),
    userData: getBackendUserData(state),
    isFetching: isBackendValiadateIbanFetching(state),
  }
}

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeRequestPayoutValues(WORKFLOW_PAGE)(values)),
  validateIban: (iban, handleIbanValidationResponse) =>
    dispatch(fetchValidateIbanActions.requestAction({ iban, handleIbanValidationResponse })),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestPayoutPageTwo)
