import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { EXCHANGE_RATE_RANGES } from '../../config'
import { useSetLocale } from '../../lib/translate'
import {
  fetchAccountBrandActions,
  fetchCountryDepartmentActions,
  fetchCustomerMessagesActions,
  fetchExchangeRatesActions,
  fetchInvoicesActions,
  fetchMetalsActions,
  fetchPerformancesActions,
  fetchSavingPlanLogsActions,
  fetchSavingsPlansActions,
  fetchUserDataActions,
} from '../../redux/actions'
import { getBackendCountryDepartmentData, getBackendUserData } from '../../redux/selectors'
import MaintenancePage from '../page/MaintenancePage'
import { CustomAppBar, CustomDrawer, DRAWER_WIDTH } from './../'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
}))

const MainLayout = ({
  children,
  fetchCustomerMessages,
  fetchCountryDepartment,
  fetchUserData,
  fetchPlans,
  fetchInvoices,
  fetchBrand,
  fetchSavingPlanLogs,
  fetchExchangeRates,
  fetchPerformances,
  userData,
  countryDepartment,
  fetchMetals,
}) => {
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)
  const setLocale = useSetLocale()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  useEffect(() => {
    fetchCustomerMessages()
    fetchUserData()
    fetchPlans()
    fetchSavingPlanLogs()
    fetchInvoices()
    fetchBrand()
    fetchMetals()
    fetchPerformances()
    fetchExchangeRates({ range: EXCHANGE_RATE_RANGES['5Y'] })
  }, [
    fetchCustomerMessages,
    fetchPlans,
    fetchUserData,
    fetchInvoices,
    fetchBrand,
    fetchSavingPlanLogs,
    fetchExchangeRates,
    fetchPerformances,
    fetchMetals,
  ])

  useEffect(() => {
    const departmentId = userData?.countryDepartment
    if (departmentId) {
      fetchCountryDepartment({ id: departmentId })
    }
  }, [fetchCountryDepartment, userData?.countryDepartment])

  useEffect(() => {
    if (countryDepartment?.countryCode) {
      setLocale(countryDepartment.countryCode.toLowerCase())
    }
  }, [countryDepartment, setLocale])

  if (countryDepartment?.showMaintenance) {
    return <MaintenancePage />
  }

  return (
    <Container className={classes.mainContainer} maxWidth={false}>
      <CustomAppBar toggleDrawer={handleDrawerToggle} />
      <CustomDrawer open={mobileOpen} close={handleDrawerToggle} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </Container>
  )
}

const mapStateToProps = state => ({
  userData: getBackendUserData(state),
  countryDepartment: getBackendCountryDepartmentData(state),
})

const mapDispatchToProps = {
  fetchCustomerMessages: fetchCustomerMessagesActions.requestAction,
  fetchCountryDepartment: fetchCountryDepartmentActions.requestAction,
  fetchUserData: fetchUserDataActions.requestAction,
  fetchPlans: fetchSavingsPlansActions.requestAction,
  fetchInvoices: fetchInvoicesActions.requestAction,
  fetchBrand: fetchAccountBrandActions.requestAction,
  fetchSavingPlanLogs: fetchSavingPlanLogsActions.requestAction,
  fetchExchangeRates: fetchExchangeRatesActions.requestAction,
  fetchMetals: fetchMetalsActions.requestAction,
  fetchPerformances: fetchPerformancesActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
