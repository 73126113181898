import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { getReferralFromUrl, storeReferralInSessionStorage } from '../../../lib/util'

import {
  CreateSavingsPlanPageOne,
  CreateSavingsPlanPageThree,
  CreateSavingsPlanPageTwo,
  CreateSavingsPlanPageFour,
  CustomRoute,
  TwoFactorAuthenticationPage,
  CompleteRequestPageSavingsPlan,
} from '../..'
import { ROUTES } from '../../../config'
import { getFormCreatePlanData } from '../../../redux/selectors'

/**
 * Create Plan Navigation
 *
 * Our nested navigation component for the create savingsplan workflow
 */

const NoLoginCreatePlanNavigation = ({ isFormDataMissing }) => {
  const { pathname } = useLocation()

  const referral = getReferralFromUrl();
  if (referral) storeReferralInSessionStorage(referral);

  if (ROUTES.NO_LOGIN_PLAN_ONE !== pathname && isFormDataMissing) {
    return <Redirect to={ROUTES.NO_LOGIN_PLAN_ONE} />
  }

  return (
    <Switch>
      <CustomRoute path={ROUTES.NO_LOGIN_PLAN_ONE}>
        <CreateSavingsPlanPageOne noLogin />
      </CustomRoute>
      <CustomRoute path={ROUTES.NO_LOGIN_PLAN_TWO}>
        <CreateSavingsPlanPageTwo noLogin showUserAccountForm />
      </CustomRoute>
      <CustomRoute path={ROUTES.NO_LOGIN_PLAN_THREE}>
        <CreateSavingsPlanPageThree noLogin />
      </CustomRoute>
      <CustomRoute path={ROUTES.NO_LOGIN_PLAN_FOUR}>
        <CreateSavingsPlanPageFour noLogin />
      </CustomRoute>
      <CustomRoute path={ROUTES.NO_LOGIN_PLAN_FIVE}>
        <TwoFactorAuthenticationPage isCreatePlan noLogin />
      </CustomRoute>
      <CustomRoute path={ROUTES.NO_LOGIN_COMPLETE_REQUEST_SAVINGS_PLAN}>
        <CompleteRequestPageSavingsPlan noLogin/>
      </CustomRoute>
    </Switch>
  )
}

const mapStateToProps = state => ({
  isFormDataMissing: isEmpty(getFormCreatePlanData(state)),
})

export default connect(mapStateToProps)(NoLoginCreatePlanNavigation)
