import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

import {
  getBackendProductsData,
  getBackendSavingsPlansData,
  getRemainingGram,
} from '../../redux/selectors'
import { setRemainingGram } from '../../redux/actions'

/**
 * Custom Hook for Payout and Cancellation flow used in DepositOverviewCard
 * Calculates the Remaining Gram available for payout and saves the value in the redux store
 */
const useCalcRemainingGram = (formValues, planId) => {
  const dispatch = useDispatch()
  const remainingGram = useSelector(getRemainingGram)
  const plan = find(useSelector(getBackendSavingsPlansData), {
    id: planId,
  })
  const products = useSelector(getBackendProductsData)

  useEffect(() => {
    if (isEmpty(formValues)) {
      dispatch(setRemainingGram(plan?.availableGram))
    } else {
      const remaining = Object.values(formValues).reduce((result, value) => {
        if (!value?.id || !value.qty) {
          return result
        }
        const product = find(products, { id: value.id })
        return result - product?.grossWeight * value.qty
      }, plan?.availableGram)

      dispatch(setRemainingGram(remaining))
    }
  }, [dispatch, formValues, plan?.availableGram, products])

  return [remainingGram, plan?.gram]
}

export default useCalcRemainingGram
