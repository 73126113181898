import { gql } from '@apollo/client'

export const AUTHENTICATE = gql`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(email: $email, pass: $password) {
      token
      action
      twoFactorAuthMode
      user
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken
  }
`

export const START_REGISTER_NEW = gql`
  mutation StartRegisterNew(
    $salutation: String
    $title: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $newsletter: Boolean!
    $countryDepartment: CountryDepartment!
    $tos: Boolean!
    $referral: String
  ) {
    startRegisterNewCustomer(
      salutation: $salutation
      title: $title
      firstname: $firstName
      lastname: $lastName
      email: $email
      pass: $password
      newsletter: $newsletter
      countryDepartment: $countryDepartment
      tos: $tos
      privacyPolicy: $tos
      agb: $tos
      referral: $referral
    )
  }
`

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($uuid: String!) {
    verifyEmail(uuid: $uuid)
  }
`

export const RESEND_VERIFY_MAIL = gql`
  mutation ResendMail($email: String!) {
    resendMail(email: $email)
  }
`

export const SEND_SECURITY_CODE = gql`
  mutation SendSecurityCode {
    sendSecurityCode
  }
`

export const VERIFY_SECURITY_CODE = gql`
  mutation VerifySecurityCode($code: String!) {
    verifySecurityCode(securityCode: $code) {
      token
    }
  }
`

export const VERIFY_PHONE = gql`
  mutation VerifyPhone($code: String!) {
    verifyPhone(securityCode: $code) {
      token
    }
  }
`
export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($password: String!, $uuid: String!) {
    updatePassword(pass: $password, uuid: $uuid)
  }
`

export const COMPLETE_REGISTER_NEW = gql`
  mutation CompleteRegisterNew($phone: String!, $password: String) {
    completeRegisterNewCustomer(phone: $phone, password: $password)
  }
`

export const COMPLETE_REGISTER_EXISTING = gql`
  mutation CompleteRegisterExisting(
    $salutation: String
    $title: String
    $firstName: String!
    $lastName: String!
    $dateOfBirth: String!
    $nationality: String!
    $phone: String!
    $newsletter: Boolean!
    $street: String!
    $postalCode: String!
    $city: String!
    $country: String!
    $state: String!
    $agb: Boolean!
    $privacyPolicy: Boolean!
  ) {
    completeRegisterExistingCustomer(
      salutation: $salutation
      title: $title
      firstname: $firstName
      lastname: $lastName
      dob: $dateOfBirth
      nationality: $nationality
      phone: $phone
      newsletter: $newsletter
      street: $street
      zip: $postalCode
      city: $city
      country: $country
      state: $state
      agb: $agb
      privacyPolicy: $privacyPolicy
    )
  }
`

export const UPLOAD_VERIFICATION_DOCUMENT = gql`
  mutation UploadVerificationDocument(
    $file: Upload
    $file2: Upload
    $type: FileType!
    $number: String!
    $expDate: String!
    $authority: String!
    $skippedUpload: Boolean!
  ) {
    uploadVerificationDocument(
      file: $file
      file2: $file2
      type: $type
      number: $number
      expDate: $expDate
      authority: $authority
      skippedUpload: $skippedUpload
    )
  }
`

export const CREATE_SAVINGS_PLAN_REQUEST = gql`
  mutation CreateSavingsPlan(
    $customFileId: String!
    $noLogin: Boolean
    $securityCode: String!
    $gold: Boolean!
    $silver: Boolean!
    $platinum: Boolean!
    $palladium: Boolean!
    $total: Int!
    $goldMonthly: Int
    $goldStartMonth: String
    $silverMonthly: Int
    $silverStartMonth: String
    $platinumMonthly: Int
    $platinumStartMonth: String
    $palladiumMonthly: Int
    $palladiumStartMonth: String
    $noPoliticallyExpose: Boolean!
    $beneficialOwner: Boolean!
    $vouchers: [VoucherInput]
    $pepOffice: String
    $copyReceived: Boolean!
  ) {
    createSavingsPlanRequest(
      copyReceived: $copyReceived
      customFileId: $customFileId
      noLogin: $noLogin
      securityCode: $securityCode
      gold: $gold
      silver: $silver
      platinum: $platinum
      palladium: $palladium
      total: $total
      goldMonthly: $goldMonthly
      goldStartMonth: $goldStartMonth
      silverMonthly: $silverMonthly
      silverStartMonth: $silverStartMonth
      platinumMonthly: $platinumMonthly
      platinumStartMonth: $platinumStartMonth
      palladiumMonthly: $palladiumMonthly
      palladiumStartMonth: $palladiumStartMonth
      noPoliticallyExpose: $noPoliticallyExpose
      beneficialOwner: $beneficialOwner
      noPoliticallyExposeSecond: $noPoliticallyExpose
      beneficialOwnerSecond: $beneficialOwner
      vouchers: $vouchers
      pepOffice: $pepOffice
    )
  }
`

export const UPLOAD_DOCUMENT = gql`
  mutation ($request: String!, $file: Upload!) {
    uploadDocument(request: $request, file: $file)
  }
`

export const SEND_VIA_OTHER = gql`
  mutation ($request: String!, $option: SendOption!) {
    sendViaOther(request: $request, option: $option)
  }
`
export const PATCH_USER = gql`
  mutation PatchUser(
    $securityCode: String!
    $salutation: String
    $title: String
    $firstName: String!
    $lastName: String!
    $street: String!
    $postalCode: String!
    $city: String!
    $country: String!
    $state: String
    $dateOfBirth: String!
    $nationality: String!
  ) {
    patchUser(
      securityCode: $securityCode
      salutation: $salutation
      title: $title
      firstname: $firstName
      lastname: $lastName
      street: $street
      zip: $postalCode
      city: $city
      country: $country
      state: $state
      dob: $dateOfBirth
      nationality: $nationality
    )
  }
`

export const INITIATE_ONLINE_IDENT = gql`
  mutation InitiateOnlineIdent {
    initiateOnlineIdent
  }
`

export const REGISTER_AND_INITIATE_ONLINE_IDENT = gql`
  mutation RegisterAndInitiateOnlineIdent(
    $email: String!
    $password: String!
    $phone: String!
    $salutation: String
    $title: String
    $firstName: String!
    $lastName: String!
    $street: String!
    $postalCode: String!
    $city: String!
    $country: String!
    $state: String
    $dateOfBirth: String!
    $nationality: String!
    $referral: String
  ) {
    registerAndInitiateOnlineIdent(
      email: $email
      pass: $password
      phone: $phone
      salutation: $salutation
      title: $title
      firstname: $firstName
      lastname: $lastName
      street: $street
      zip: $postalCode
      city: $city
      country: $country
      state: $state
      dob: $dateOfBirth
      nationality: $nationality
      referral: $referral
    )
  }
`

export const CREATE_PAYOUT_REQUEST = gql`
  mutation CreatePayoutRequest(
    $savingsPlan: String!
    $physicalDelivery: Boolean!
    $fiatWithdrawal: Boolean!
    $amount: Float
    $lineItems: [ProductInput]
    $pickUpStore: PickUpStore
    $phone: String
    $email: String
    $shippingPayment: ShippingPayment
    $bankAccountOwner: String
    $bank: String
    $iban: String
    $bic: String
    $payForFollowUpCosts: Boolean
  ) {
    createPayoutRequest(
      savingsPlan: $savingsPlan
      physicalDelivery: $physicalDelivery
      fiatWithdrawal: $fiatWithdrawal
      amount: $amount
      lineItems: $lineItems
      pickUpStore: $pickUpStore
      phone: $phone
      email: $email
      shippingPayment: $shippingPayment
      bankAccountOwner: $bankAccountOwner
      bank: $bank
      iban: $iban
      bic: $bic
      payForFollowUpCosts: $payForFollowUpCosts
    )
  }
`

export const CANCEL_PLAN_REQUEST = gql`
  mutation CancelPlanRequest(
    $savingsPlan: String!
    $cancellationDate: String!
    $physicalDelivery: Boolean!
    $lineItems: [ProductInput]
    $pickUpStore: PickUpStore
    $phone: String
    $email: String
    $shippingPayment: ShippingPayment
    $bankAccountOwner: String
    $bank: String
    $iban: String
    $bic: String
    $payForFollowUpCosts: Boolean
  ) {
    cancelSavingsPlanRequest(
      savingsPlan: $savingsPlan
      cancellationDate: $cancellationDate
      physicalDelivery: $physicalDelivery
      lineItems: $lineItems
      pickUpStore: $pickUpStore
      phone: $phone
      email: $email
      shippingPayment: $shippingPayment
      bankAccountOwner: $bankAccountOwner
      bank: $bank
      iban: $iban
      bic: $bic
      payForFollowUpCosts: $payForFollowUpCosts
    )
  }
`

export const ADJUST_PLAN = gql`
  mutation AdjustSavingsPlan(
    $securityCode: String!
    $savingsPlanId: String!
    $newSavingsRate: Float!
    $bankAccountOwner: String
    $bank: String
    $iban: String
    $bic: String
    $startMonth: String
  ) {
    adjustSavingsPlan(
      securityCode: $securityCode
      savingsPlan: $savingsPlanId
      total: $newSavingsRate
      bankAccountOwner: $bankAccountOwner
      bank: $bank
      iban: $iban
      bic: $bic
      startMonth: $startMonth
    )
  }
`

export const CHANGE_PLAN_NAME = gql`
  mutation ChangePlanName($id: String!, $name: String!) {
    changePlanName(id: $id, name: $name)
  }
`

export const CHANGE_SECURITY_DETAILS = gql`
  mutation ChangeSecurityDetails(
    $code: String!
    $email: String
    $password: String
    $phone: String
  ) {
    changeSecurityDetails(securityCode: $code, email: $email, pass: $password, phone: $phone)
  }
`

export const CONFIRM_NEW_MAIL = gql`
  mutation ConfirmNewMail($uuid: String!) {
    confirmNewEmail(uuid: $uuid)
  }
`

export const CONFIRM_NEW_PHONE = gql`
  mutation ConfirmNewPhone($code: String!) {
    confirmNewPhone(securityCode: $code)
  }
`

export const VERIFY_REQUEST_2FA = gql`
  mutation VerifyRequest2FA($requestId: String!, $code: String!) {
    verifyRequest2FA(requestId: $requestId, code: $code)
  }
`

export const SET_CUSTOMER_MESSAGES = gql`
  mutation SetCustomerMessages($notifications: [String!]!, $newStatus: String!) {
    setNotifications(notifications: $notifications, newStatus: $newStatus)
  }
`

export const CREATE_CUSTOMER_MESSAGE = gql`
  mutation CreateCustomerMessage($title: String!, $message: String!) {
    createCustomerMessage(title: $title, message: $message)
  }
`

export const DELETE_CACHE = gql`
  mutation DeleteCache {
    deleteCacheForUser
  }
`

export const VERIFY_VOUCHER = gql`
  mutation VerifyVoucher(
    $code: String!
    $screen: VoucherScreen!
    $gold: Boolean
    $silver: Boolean
    $platinum: Boolean
    $palladium: Boolean
  ) {
    verifyVoucherCode(
      code: $code
      screen: $screen
      gold: $gold
      silver: $silver
      platinum: $platinum
      palladium: $palladium
    )
  }
`

export const REQUEST_CALLBACK = gql`
  mutation RequestCallback {
    requestCallback
  }
`

export const DELETE_REQUEST = gql`
  mutation DeleteRequest($id: String!) {
    deleteRequest(id: $id)
  }
`

export const MIGRATE = gql`
  mutation Migrate($uuid: String!) {
    migrate(uuid: $uuid)
  }
`

export const DELETE_ADJUSTMENT = gql`
  mutation DeleteAdjustment($savingPlanLogId: String!) {
    deleteAdjustment(savingPlanLogId: $savingPlanLogId)
  }
`

export const APPLY_VOUCHER = gql`
  mutation ApplyVoucher($savingsPlanId: String!, $voucherId: String!) {
    applyVoucher(savingsPlanId: $savingsPlanId, voucherId: $voucherId)
  }
`

export const CREATE_CONSENT_SAVINGS_PLAN = gql`
  mutation CreateConsentSavingsPlan(
    $beneficialOwner: Boolean!
    $isPep: Boolean!
    $firstName: String
    $lastName: String
    $dateOfBirth: String
    $nationality: String
    $streetAndNumber: String
    $postalCode: String
    $city: String
    $country: String
    $politicalPosition: String
  ) {
    createConsentSavingsPlan(
      beneficialOwner: $beneficialOwner
      isPep: $isPep
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      nationality: $nationality
      streetAndNumber: $streetAndNumber
      postalCode: $postalCode
      city: $city
      country: $country
      politicalPosition: $politicalPosition
    )
  }
`
