import { Box, makeStyles, Typography } from '@material-ui/core'
import find from 'lodash/find'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { REQUEST_RECORD_TYPE } from '../../config'
import { useTranslate } from '../../lib/translate'
import { getBackendRequestsData } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
    marginBottom: '2rem',
  },
}))

const getPageTitle = request => {
  switch (request?.recordTypeId) {
    case REQUEST_RECORD_TYPE.PAYOUT.id:
      return request?.physicalDelivery
        ? 'createSavingsPlan.pdfShippingDownloadTitle'
        : 'createSavingsPlan.pdfTransferDownloadTitle'
    case REQUEST_RECORD_TYPE.CANCEL.id:
      return 'createSavingsPlan.pdfcancelDownloadTitle'
    default:
      return 'createSavingsPlan.pdfDownloadTitle'
  }
}

const DownloadInstructions = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const { requestId } = useParams()

  const requests = useSelector(getBackendRequestsData)
  const request = find(requests, { id: requestId })
  const isPayout = request?.recordTypeId === REQUEST_RECORD_TYPE.PAYOUT.id
  const title = translate(getPageTitle(request))

  return (
    <>
      <Box>
        <Typography variant="h6" className={classes.bold}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" paragraph>
          <Box fontWeight="fontWeightBold" component="span" mr={1}>
            1.
          </Box>
          {isPayout
            ? translate('payoutRequest.instructions1')
            : translate('createSavingsPlan.pdfDownloadInfo1', {
                request: title,
              })}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" paragraph>
          <Box fontWeight="fontWeightBold" component="span" mr={1}>
            2.
          </Box>

          {isPayout
            ? translate('payoutRequest.instructions2')
            : translate('createSavingsPlan.pdfDownloadInfo2')}
        </Typography>
      </Box>
      {!isPayout && (
        <Box>
          <Typography variant="body1" paragraph>
            <Box fontWeight="fontWeightBold" component="span" mr={1}>
              3.
            </Box>
            {translate('createSavingsPlan.pdfDownloadInfo3')}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default DownloadInstructions
