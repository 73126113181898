import { Box, Button, Card, CardContent, Divider, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { FormLayout, RegisterWithoutAccountForm } from '..'
import { COUNTRY_DEPARTMENT, GOOGLE_TAG_MANAGER, ROUTES } from '../../config'
import useDataLayer from '../../lib/customHooks/useDataLayer'
import useQueryParams from '../../lib/customHooks/useQueryParams'
import { useTranslate } from '../../lib/translate'
import { fetchStartRegisterActions } from '../../redux/actions'
import { isBackendStartRegisterFetching } from '../../redux/selectors'
import {
  getReferralFromSessionStorage,
  getReferralFromUrl,
  storeReferralInSessionStorage,
} from '../../lib/util'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  departmentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  topCard: {
    margin: `${theme.spacing(3.75)}px auto`,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bottomContents: {
    margin: `${theme.spacing(3.75)}px auto`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  radioFormControl: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const RegisterPage = ({ fetchRegister }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const queryParams = useQueryParams()
  const history = useHistory()
  const pushToDataLayer = useDataLayer()
  let referral = getReferralFromSessionStorage()

  if (!referral) {
    referral = getReferralFromUrl()

    if (referral) {
      storeReferralInSessionStorage(referral)
    }
  }

  const countryParam = queryParams.get('country')
  const countryDepartment = COUNTRY_DEPARTMENT.SWITZERLAND

  const onSubmitNewAccount = ({ confirmPassword, newsletter = false, email2, ...values }) => {
    fetchRegister({
      ...values,
      newsletter,
      history,
      countryDepartment: countryDepartment.value,
      countryParam: countryDepartment.tld,
      referral,
    })
  }

  useEffect(() => {
    pushToDataLayer({ event: GOOGLE_TAG_MANAGER.EVENT.REGISTRATION_OPEN })
  }, [pushToDataLayer])

  return (
    <FormLayout>
      <Box>
        <Typography variant="h6" gutterBottom className={classes.textBold}>
          {translate('register.title')}
        </Typography>
        <Typography variant="subtitle2" paragraph>
          {translate('register.subtitle')}
        </Typography>
        <Card className={classes.topCard}>
          <CardContent className={`${classes.cardContentPadding} ${classes.departmentContainer}`}>
            <Typography variant="body2" paragraph color="primary">
              {translate('register.departmentAffiliation')}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <RegisterWithoutAccountForm onSubmit={onSubmitNewAccount} />
      <Divider />
      <Box className={classes.bottomContents}>
        <Button
          variant="text"
          className={classes.textBold}
          onClick={() =>
            history.replace(`${ROUTES.LOGIN}${countryParam ? `?country=${countryParam}` : ''}`)
          }
        >
          {translate('register.backLogin')}
        </Button>
      </Box>
      <Box ml={1}>
        <Link color="textSecondary" href={ROUTES.IMPRESSUM} target="__blank">
          {translate('drawer.drawerLink.legalInfo')}
        </Link>
      </Box>
    </FormLayout>
  )
}

RegisterPage.propTypes = {
  fetchRegister: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isFetching: isBackendStartRegisterFetching(state),
})

const mapDispatchToProps = {
  fetchRegister: fetchStartRegisterActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
