import { Link, Typography } from '@material-ui/core'

import { COUNTRY_DEPARTMENT } from '../../config'
import { useTranslate } from '../../lib/translate'

const CHCountryInfoText = () => {
  const translate = useTranslate()

  return (
    <Typography component="div" variant="caption" color="textSecondary" align="justify">
      {translate('createSavingsPlan.addressInfoCH1')}{' '}
      <Link
        color="secondary"
        href={COUNTRY_DEPARTMENT.AUSTRIA.domain}
        target="_blank"
        rel="noopener noreferrer"
      >
        {COUNTRY_DEPARTMENT.AUSTRIA.domain}
      </Link>{' '}
      {translate('createSavingsPlan.addressInfoCH2')}{' '}
      <Link
        color="secondary"
        href={COUNTRY_DEPARTMENT.GERMANY.domain}
        target="_blank"
        rel="noopener noreferrer"
      >
        {COUNTRY_DEPARTMENT.GERMANY.domain}
      </Link>{' '}
      {translate('createSavingsPlan.addressInfoCH3')}
    </Typography>
  )
}

export default CHCountryInfoText
