import { Box, Divider, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../config'
import { useTranslate } from '../../lib/translate'

const useStyles = makeStyles(theme => ({
  linkWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.text.secondary,
    },
  },
}))

const LegalLinksNoLoginForm = () => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <>
      <Divider />
      <Box className={classes.linkWrapper} mx={2}>
        <Link to={ROUTES.IMPRESSUM} target="__blank" className={classes.link}>
          <Typography variant="body2" color="textSecondary" align="center">
            {translate('drawer.drawerLink.legalInfo')}
          </Typography>
        </Link>
        <Link to={ROUTES.TOS} target="__blank" className={classes.link}>
          <Typography variant="body2" color="textSecondary" align="center">
            {translate('drawer.drawerLink.tos')}
          </Typography>
        </Link>
        <Link to={ROUTES.PRIVACY_INFO} target="__blank" className={classes.link}>
          <Typography variant="body2" color="textSecondary" align="center">
            {translate('drawer.drawerLink.privacyInfo')}
          </Typography>
        </Link>
      </Box>
    </>
  )
}

export default LegalLinksNoLoginForm
