import { Grid, makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import { useEffect } from 'react'
import { connect } from 'react-redux'

import useCurrency from '../../lib/customHooks/useCurrency'
import { useTranslate } from '../../lib/translate'
import { fetchConditionsActions } from '../../redux/actions'
import { getBackendConditionsData } from '../../redux/selectors'
import RowField from './RowField'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  subtitle: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fffbe6',
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    borderRadius: 5,
  },
}))

const CreatePlanRequestControlDialogMetals = ({
  gold,
  goldMonthly,
  goldStartMonth,
  silver,
  silverMonthly,
  silverStartMonth,
  platinum,
  platinumMonthly,
  platinumStartMonth,
  palladium,
  palladiumMonthly,
  palladiumStartMonth,
  total,
  fetchConditions,
}) => {
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const classes = useStyles()

  let monthlyTotal = 0
  const payments = [
    { monthly: goldMonthly, startMonth: goldStartMonth },
    { monthly: silverMonthly, startMonth: silverStartMonth },
    { monthly: platinumMonthly, startMonth: platinumStartMonth },
    { monthly: palladiumMonthly, startMonth: palladiumStartMonth },
  ]
    .reduce((prev, { monthly, startMonth }) => {
      if (startMonth) {
        const i = prev.findIndex(itm => itm.startMonth === startMonth)
        i === -1
          ? prev.push({ monthly, startMonth })
          : (prev[i] = { ...prev[i], monthly: prev[i].monthly + monthly })
        return prev
      }
      return prev
    }, [])
    .sort((a, b) => new Date(a.startMonth) - new Date(b.startMonth))

  useEffect(() => {
    fetchConditions()
  }, [fetchConditions])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" className={`${classes.bold} ${classes.subtitle}`}>
          {translate('createSavingsPlan.controlDialogPlans')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RowField
          bold
          label={translate('formFields.monthlyTotal')}
          value={displayCurrency(total)}
        />
      </Grid>
      {gold && (
        <>
          <Grid item xs={6}>
            <RowField label={translate('metals.gold')} value={displayCurrency(goldMonthly)} />
          </Grid>
          <Grid item xs={6}>
            <RowField
              label={translate('createSavingsPlan.controlDialogBegin')}
              value={moment(goldStartMonth).format(translate('formFields.dateFormat'))}
            />
          </Grid>
        </>
      )}
      {silver && (
        <>
          <Grid item xs={6}>
            <RowField label={translate('metals.silver')} value={displayCurrency(silverMonthly)} />
          </Grid>
          <Grid item xs={6}>
            <RowField
              label={translate('createSavingsPlan.controlDialogBegin')}
              value={moment(silverStartMonth).format(translate('formFields.dateFormat'))}
            />
          </Grid>
        </>
      )}
      {platinum && (
        <>
          <Grid item xs={6}>
            <RowField
              label={translate('metals.platinum')}
              value={displayCurrency(platinumMonthly)}
            />
          </Grid>
          <Grid item xs={6}>
            <RowField
              label={translate('createSavingsPlan.controlDialogBegin')}
              value={moment(platinumStartMonth).format(translate('formFields.dateFormat'))}
            />
          </Grid>
        </>
      )}
      {palladium && (
        <>
          <Grid item xs={6}>
            <RowField
              label={translate('metals.palladium')}
              value={displayCurrency(palladiumMonthly)}
            />
          </Grid>
          <Grid item xs={6}>
            <RowField
              label={translate('createSavingsPlan.controlDialogBegin')}
              value={moment(palladiumStartMonth).format(translate('formFields.dateFormat'))}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {payments.map(({ monthly, startMonth }, i) => {
          monthlyTotal += monthly
          return (
            <RowField
              label={translate(i === 0 ? 'requestDialog.totalFirstPayment' : 'requestDialog.from', {
                date: moment(startMonth).format(translate('formFields.dateFormat')),
              })}
              value={
                i === 0
                  ? displayCurrency(monthly)
                  : displayCurrency(monthlyTotal)
              }
              bold
              underline={i === payments.length - 1}
              key={startMonth}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  conditions: getBackendConditionsData(state),
})

const mapDispatchToProps = {
  fetchConditions: fetchConditionsActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlanRequestControlDialogMetals)
