import {
  Box,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import {
  CustomSaveButton,
  FormLayout,
} from '..'
import { ROUTES } from '../../config'
import { useTranslate } from '../../lib/translate'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

const CompleteRequestPageSavingsPlan = ({ noLogin }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const handleSubmit = () => {
    if (noLogin) {
      history.push(ROUTES.LOGIN)
    } else {
      history.push(ROUTES.DASHBOARD)
    }
  }

  return (
    <FormLayout title={translate('completeRequestPage.title')} wideLayout showClose isSignedIn>
      <Box className={classes.contentColumns}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="h6" className={classes.bold}>
                {translate('createSavingsPlan.completeRequestPageSuccessfulTitle')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                {translate('createSavingsPlan.completeRequestPageSuccessfulText')}
              </Typography>
              <Typography variant="body1">
                {translate('createSavingsPlan.checkEmail')}
              </Typography>
              <Typography variant="body1">
                {translate('createSavingsPlan.contact')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <CustomSaveButton onClick={handleSubmit}>{translate('actions.end')}</CustomSaveButton>
        </Box>
      </Box>
    </FormLayout>
  )
}

export default CompleteRequestPageSavingsPlan

