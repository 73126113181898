import { combineReducers } from 'redux'

import { createBackendReducer, createBackendReducerNestedById } from '../../createReducer'
import * as actions from '../actions'

/**
 * List of reducers for our mutations
 *
 * Add here an { key: createBackendReducer(actions.backendAction) } pair for each mutation
 */
const mutationReducerList = {
  login: createBackendReducer(actions.fetchLoginActions),
  refreshToken: createBackendReducer(actions.fetchRefreshTokenActions),
  verifyCode: createBackendReducer(actions.fetchVerifyCodeActions),
  startRegister: createBackendReducer(actions.fetchStartRegisterActions),
  verifyMail: createBackendReducer(actions.fetchVerifyMailActions),
  resendMail: createBackendReducer(actions.fetchResendVerifyMailActions),
  finishRegister: createBackendReducer(actions.fetchFinishRegisterActions),
  sendCode: createBackendReducer(actions.fetchSendSecurityCodeActions),
  verifyPhone: createBackendReducer(actions.fetchVerifyPhoneActions),
  resetPassword: createBackendReducer(actions.fetchResetPasswordActions),
  updatePassword: createBackendReducer(actions.fetchUpdatePasswordActions),
  confirmNewMail: createBackendReducer(actions.fetchConfirmNewMailActions),
  confirmNewPhone: createBackendReducer(actions.fetchConfirmNewPhoneActions),
  changeSecurityDetails: createBackendReducer(actions.fetchChangeSecurityDetailsActions),
  sendViaOther: createBackendReducer(actions.fetchSendViaOtherActions),
  uploadVerificationDocument: createBackendReducer(actions.fetchUploadVerificationDocumentActions),
  downloadFile: createBackendReducer(actions.fetchDownloadFileActions),
  uploadDocument: createBackendReducer(actions.fetchUploadDocumentActions),
  createPlan: createBackendReducer(actions.fetchCreateSavingsPlanRequestActions),
  initiateOnlineIdent: createBackendReducer(actions.fetchInitiateOnlineIdentActions),
  registerAndInitiateOnlineIdent: createBackendReducer(
    actions.fetchRegisterAndInitiateOnlineIdentActions
  ),
  createPayout: createBackendReducer(actions.fetchCreatePayoutRequestActions),
  cancelPlan: createBackendReducer(actions.fetchCancelPlanRequestActions),
  adjustPlan: createBackendReducer(actions.fetchAdjustPlanActions),
  changePlanName: createBackendReducer(actions.fetchChangePlanNameActions),
  verifyRequest2FA: createBackendReducer(actions.fetchVerifyRequest2FAActions),
  setCustomerMessages: createBackendReducer(actions.fetchSetCustomerMessagesActions),
  requestCallback: createBackendReducer(actions.fetchRequestCallbackActions),
  createCustomerMessage: createBackendReducer(actions.fetchCreateCustomerMessageActions),
  deleteCache: createBackendReducer(actions.fetchDeleteCacheActions),
  verifyVoucher: createBackendReducerNestedById(actions.fetchVerifyVoucherActions),
  deleteRequest: createBackendReducer(actions.fetchDeleteRequestActions),
  migrate: createBackendReducer(actions.fetchMigrateActions),
  patchUser: createBackendReducer(actions.fetchPatchUserActions),
  deleteAdjustment: createBackendReducer(actions.fetchDeleteAdjustmentActions),
  applyVoucher: createBackendReducer(actions.fetchApplyVoucherActions),
  createConsentSavingsPlan: createBackendReducer(actions.fetchCreateConsentSavingsPlanActions),
}

// combine all mutation reducers
const mutationReducer = combineReducers(mutationReducerList)

export default mutationReducer
