import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { connect, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslate } from '../../lib/translate'
import { fetchDocumentVersionId } from '../../redux/actions'
import {
  getDocumentIdConsentSavingsPlan,
  isBackendDownloadFileFetching,
} from '../../redux/selectors'

import CustomSaveButton from './CustomSaveButton'

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}))

const WAIT_TIME_FOR_VER_ID = 3000

const DownloadDialogDocuments = ({ documentId, downloadConsentDocument }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const isDownloading = useSelector(isBackendDownloadFileFetching)
  const [isLoading, setIsLoading] = useState(true)

  const handleDocumentDownload = () => {
    setIsLoading(isDownloading)
    downloadConsentDocument({ documentId })
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, WAIT_TIME_FOR_VER_ID)
  }, [])

  return (
    <Box>
      <Typography variant="body2" className={classes.heading}>
        {translate('requestDialog.moreDownloads')}
      </Typography>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={4}>
          <CustomSaveButton
            fullWidth
            onClick={handleDocumentDownload}
            loading={isLoading}
            variant="outlined"
            type="button"
          >
            {translate('createSavingsPlan.consentDownload')}
          </CustomSaveButton>
        </Grid>
      </Grid>
    </Box>
  )
}

const mapStateToProps = state => ({
  documentId: getDocumentIdConsentSavingsPlan(state),
})

const mapDispatchToProps = dispatch => ({
  downloadConsentDocument: payload => dispatch(fetchDocumentVersionId.requestAction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDialogDocuments)
