import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  DialogActions,
  Button,
} from '@material-ui/core'

import { useTranslate } from '../../lib/translate'
import CustomSaveButton from './CustomSaveButton'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
}))

const ContinueFromOnlineIdentDialog = ({ open, onClose, onClickContinue }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {translate('createSavingsPlan.onlineIdentContinueDialogTitle')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography align="justify">
          {translate('createSavingsPlan.onlineIdentContinueDialogText')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {translate('createSavingsPlan.onlineIdentContinueDialogGoBack')}
        </Button>
        <CustomSaveButton onClick={onClickContinue}>
          {translate('createSavingsPlan.onlineIdentContinueDialogContinue')}
        </CustomSaveButton>
      </DialogActions>
    </Dialog>
  )
}

export default ContinueFromOnlineIdentDialog
