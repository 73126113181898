import { Box, Button, Card, CardContent, Grid, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment/moment'
import React, { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import {
  AddressAutocompleteAdapter,
  CustomSaveButton,
  DatePickerAdapter,
  DialingCodeAdaper,
  FormLayout,
  NationalitiesAdapter,
  PasswordSafetyIndicator,
  SelectAdapter,
  TextFieldAdapter,
} from '../..'
import { GOOGLE_TAG_MANAGER, ROUTES, SALUTATION_OPTIONS, TITLE_OPTIONS } from '../../../config'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import { sanitizeContractorForForm } from '../../../lib/sanitizeData'
import { useTranslate } from '../../../lib/translate'
import {
  required,
  validateConfirmPasswordAndEmail2,
  validateCountry,
  validateDate,
  validateEmail,
  validatePassword,
  validatePhoneNumber,
} from '../../../lib/validation'
import {
  fetchInitiateOnlineIdentActions,
  fetchRegisterAndInitiateOnlineIdentActions,
  storeCreatePlanValues,
} from '../../../redux/actions'
import {
  getBackendToken,
  getBackendUserData,
  getFormCreatePlanPageData,
  isBackendInitiateOnlineIdentFetching,
  isBackendRegisterAndInitiateOnlineIdentFetching,
} from '../../../redux/selectors'
import CHCountryInfoText from '../../common/CHCountryInfoText'
import LegalLinksNoLoginForm from '../../common/LegaLinksNoLoginForm'
import { getReferralFromSessionStorage } from '../../../lib/util'

export const WORKFLOW_PAGE = 'pageTwo'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  passwordSafetyContainer: {
    display: 'grid',
    gridTemplateColumns: '25% auto',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  passwordHint: {
    color: theme.palette.text.hint,
  },
}))

const getSalutationOptions = () => Object.values(SALUTATION_OPTIONS)
const getTitleOptions = () => Object.values(TITLE_OPTIONS)

const FirstCard = ({ disabled }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box mb={3}>
      <Card>
        <CardContent className={classes.cardContentPadding}>
          <Field
            name="salutation"
            component={SelectAdapter}
            label={translate('formFields.salutation')}
            fullWidth
            disabled={disabled}
          >
            {getSalutationOptions().map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {translate(option.key)}
              </MenuItem>
            ))}
          </Field>
          <Field
            name="title"
            component={SelectAdapter}
            label={translate('formFields.title')}
            fullWidth
            disabled={disabled}
          >
            {getTitleOptions().map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {translate(option.key)}
              </MenuItem>
            ))}
          </Field>
          <Field
            name="firstName"
            component={TextFieldAdapter}
            validate={required}
            fullWidth
            label={translate('formFields.firstName')}
            disabled={disabled}
          />
          <Field
            name="lastName"
            component={TextFieldAdapter}
            validate={required}
            fullWidth
            label={translate('formFields.lastName')}
            disabled={disabled}
          />
          <Field
            name="dateOfBirth"
            component={DatePickerAdapter}
            openTo="year"
            validate={validateDate}
            fullWidth
            label={translate('formFields.dateOfBirth')}
            disabled={disabled}
          />
          <Field
            name="nationality"
            component={NationalitiesAdapter}
            validate={required}
            disabled={disabled}
          />
        </CardContent>
      </Card>
    </Box>
  )
}

const SecondCard = ({ disabled, autofillAddress }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box mb={3}>
      <Card>
        <CardContent className={classes.cardContentPadding}>
          <Field
            name="street"
            component={AddressAutocompleteAdapter}
            formMutator={autofillAddress}
            validate={required}
            disabled={disabled}
          />
          <Field
            name="postalCode"
            component={TextFieldAdapter}
            validate={required}
            fullWidth
            label={translate('formFields.postalCodeShort')}
            disabled={disabled}
          />
          <Field
            name="city"
            component={TextFieldAdapter}
            validate={required}
            fullWidth
            label={translate('formFields.place')}
            disabled={disabled}
          />
          <Field
            name="country"
            component={NationalitiesAdapter}
            isCountryPicker
            validate={validateCountry}
            fullWidth
            label={translate('formFields.country')}
            disabled={disabled}
          />
          <CHCountryInfoText />
        </CardContent>
      </Card>
    </Box>
  )
}

const CardsGroup = ({ autofillAddress, disabled }) => (
  <>
    <FirstCard disabled={disabled} />
    <SecondCard autofillAddress={autofillAddress} disabled={disabled} />
  </>
)

const CreateSavingsPlanPageTwo = ({
  initialUserData,
  initalValues,
  initiateOnlineIdent,
  registerAndInitiateOnlineIdent,
  isFetchingIdentSessionUrl,
  isFetchingNoLoginIdentSessionUrl,
  disabled,
  showUserAccountForm,
  isLoggedIn,
  storeValues,
  noLogin,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const pushToDataLayer = useDataLayer()
  const history = useHistory()

  const isFetching = noLogin ? isFetchingNoLoginIdentSessionUrl : isFetchingIdentSessionUrl
  const isVerified = initialUserData.isVerified || !!initialUserData.kycCustomerId

  useEffect(() => {
    pushToDataLayer(
      {
        event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
        funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.PERSONAL_DATA,
      },
      noLogin
    )
  }, [pushToDataLayer, noLogin])

  const onSubmit = ({ dialCode, phoneNumber, dateOfBirth, ...values }) => {
    storeValues({ dialCode, phoneNumber, dateOfBirth, ...values })
    const phone = dialCode?.dial_code && dialCode.dial_code.concat(phoneNumber)

    if (noLogin) {
      registerAndInitiateOnlineIdent({
        ...values,
        dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
        phone,
        country: values.country?.code,
        nationality: values.nationality?.code,
        history,
        referral: getReferralFromSessionStorage(),
      })
    } else {
      if (isVerified) {
        history.push(ROUTES.CREATE_SAVINGS_PLAN_STEP_FOUR)
      } else {
        initiateOnlineIdent({ history })
      }
    }
  }

  return (
    <FormLayout
      title={translate('createSavingsPlan.title')}
      subTitle={translate('createSavingsPlan.personalInfos')}
      pageCount={isVerified ? '2/3' : '2/4'}
      wideLayout
      showClose={!noLogin}
      isSignedIn={!noLogin}
    >
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          validate={values => {
            if (showUserAccountForm) {
              return validateConfirmPasswordAndEmail2(values)
            }
          }}
          mutators={{
            autofillAddress: ([addressObj], state, utils) => {
              if (addressObj) {
                Object.entries(addressObj).forEach(([key, val]) => {
                  if (!val) {
                    return
                  }
                  utils.changeValue(state, key, () => val)
                })
              }
            },
          }}
          initialValues={
            isEmpty(initalValues) && !isEmpty(initialUserData)
              ? sanitizeContractorForForm(initialUserData)
              : initalValues
          }
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <CardsGroup disabled={disabled} autofillAddress={form.mutators.autofillAddress} />
              {isLoggedIn && (
                <Button
                  size="small"
                  component={RouterLink}
                  to={ROUTES.PROFILE}
                  className={classes.textAlignCenter}
                >
                  {translate('createSavingsPlan.changePersonalDataInfo')}
                </Button>
              )}
              {showUserAccountForm && (
                <Box mb={3}>
                  <Card>
                    <CardContent className={classes.cardContentPadding}>
                      <Field
                        name="email"
                        component={TextFieldAdapter}
                        validate={validateEmail}
                        fullWidth
                        autoComplete="username"
                        label={translate('formFields.mail')}
                      />
                      <Field
                        name="email2"
                        component={TextFieldAdapter}
                        fullWidth
                        label={translate('formFields.mail2')}
                      />
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <Field
                            name="dialCode"
                            component={DialingCodeAdaper}
                            validate={required}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Field
                            name="phoneNumber"
                            component={TextFieldAdapter}
                            validate={validatePhoneNumber}
                            fullWidth
                            label={translate('formFields.phone')}
                          />
                        </Grid>
                      </Grid>

                      <Field
                        name="password"
                        component={TextFieldAdapter}
                        validate={validatePassword}
                        fullWidth
                        type="password"
                        autoComplete="new-password"
                        label={translate('formFields.pass')}
                      />
                      <Field
                        name="confirmPassword"
                        component={TextFieldAdapter}
                        fullWidth
                        type="password"
                        autoComplete="new-password"
                        label={translate('formFields.confirmPassword')}
                      />
                      <Box className={classes.passwordSafetyContainer}>
                        <Typography variant="subtitle2">
                          {translate('register.security')}
                        </Typography>
                        <PasswordSafetyIndicator password={values?.password} />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" paragraph className={classes.passwordHint}>
                          {translate('register.passwordHint')}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              )}
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton loading={isFetching}>
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
        {noLogin && <LegalLinksNoLoginForm />}
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  initialUserData: getBackendUserData(state),
  initalValues: getFormCreatePlanPageData(WORKFLOW_PAGE)(state),
  isLoggedIn: !!getBackendToken(state),
  isFetchingIdentSessionUrl: isBackendInitiateOnlineIdentFetching(state),
  isFetchingNoLoginIdentSessionUrl: isBackendRegisterAndInitiateOnlineIdentFetching(state),
})

const mapDispatchToProps = dispatch => ({
  storeValues: payload => dispatch(storeCreatePlanValues(WORKFLOW_PAGE)(payload)),
  initiateOnlineIdent: payload => dispatch(fetchInitiateOnlineIdentActions.requestAction(payload)),
  registerAndInitiateOnlineIdent: payload =>
    dispatch(fetchRegisterAndInitiateOnlineIdentActions.requestAction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavingsPlanPageTwo)
