import {
  Box,
  CardMedia,
  Container,
  Link as MuiLink,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../config'
import useAppLogo from '../../lib/customHooks/useAppLogo'
import { useTranslate } from '../../lib/translate'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  img: {
    maxHeight: '4rem',
    width: 'unset',
    objectFit: 'contain',
  },
}))

const TOSPage = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const appLogo = useAppLogo()
  const countryCode = 'CH'

  return (
    <Container maxWidth="md">
      <Paper>
        <Box p={4} minHeight="100vh">
          <Box width="10rem" my={3}>
            <CardMedia
              className={classes.img}
              component="img"
              alt="App Logo"
              image={appLogo}
              title="philoro"
            />
          </Box>
          <Typography align="center" variant="h4" paragraph>
            {translate('tos.title')}
          </Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate('tos.sub1')}
          </Typography>
          <Typography align="justify">{translate(`tos.sub1sub1${countryCode}`)}</Typography>
          <Typography align="justify" paragraph>
            {translate('tos.sub1sub2')}
          </Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate('tos.sub2')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate(`tos.sub2sub1${countryCode}`)}
          </Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate('tos.sub3')}
          </Typography>
          <Typography align="justify">{translate(`tos.sub3sub1${countryCode}`)}</Typography>
          <Typography align="justify">{translate('tos.sub3sub2')}</Typography>
          <Typography align="justify" paragraph>
            {translate('tos.sub3sub3')}
          </Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate('tos.sub4')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('tos.sub4sub1')}
          </Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate('tos.sub5')}
          </Typography>
          <Typography className={classes.bold}>{translate('tos.sub5sub1')}</Typography>
          <Typography align="justify">{translate('tos.sub5sub2')}</Typography>
          <Typography align="justify">{translate('tos.sub5sub3')}</Typography>
          <Typography align="justify">{translate('tos.sub5sub4')}</Typography>
          <Typography className={classes.bold} align="justify">
            {translate('tos.sub5sub5')}
          </Typography>
          <Typography align="justify">{translate(`tos.sub5sub6${countryCode}`)}</Typography>
          <Typography align="justify">{translate(`tos.sub5sub7${countryCode}`)}</Typography>
          <Typography align="justify">{translate('tos.sub5sub8')}</Typography>
          <Typography align="justify">{translate('tos.sub5sub9')}</Typography>
          <Typography className={classes.bold} align="justify">
            {translate(`tos.sub5sub10${countryCode}`)}
          </Typography>
          <Typography align="justify">
            {`${translate(`tos.sub5sub11-1${countryCode}`)} `}
            <MuiLink
              color="secondary"
              component={Link}
              to={`${ROUTES.PRIVACY_INFO}?country=${countryCode}`}
            >
              {translate(`tos.sub5sub11-2${countryCode}`)}
            </MuiLink>
          </Typography>
          <Typography align="justify" paragraph>
            {translate('tos.sub5sub12')}
          </Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate('tos.sub6')}
          </Typography>
          <Typography align="justify">{translate(`tos.sub6sub1${countryCode}`)}</Typography>
          <Typography align="justify" paragraph>
            {translate(`tos.sub6sub2${countryCode}`)}
          </Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate('tos.sub7')}
          </Typography>
          <Typography align="justify">{translate(`tos.sub7sub1${countryCode}`)}</Typography>
          <Typography align="justify">{translate(`tos.sub7sub2${countryCode}`)}</Typography>
          <Typography align="justify">{translate(`tos.sub7sub3${countryCode}`)}</Typography>
        </Box>
      </Paper>
    </Container>
  )
}

export default TOSPage
