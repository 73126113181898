import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { CustomSaveButton, InfoTextField } from '../'
import { COUNTRY_DEPARTMENT, GOOGLE_TAG_MANAGER } from '../../config'
import { capitalizeFirstLetter } from '../../lib/miscellaneous'
import { transformDataForRequest } from '../../lib/transform'
import { useTranslate } from '../../lib/translate'
import {
  getBackendVoucherDataAllSuccess,
  getFormCreatePlanData,
  getFormCreatePlanPageData,
} from '../../redux/selectors'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_2 } from '../page/createSavingsPlan/CreateSavingsPlanPage2'
import CreatePlanRequestControlDialogMetals from './CreatePlanRequestControlDialogMetals'
import DownloadDialogDocuments from './DownloadDialogDocuments'
import useDataLayer from '../../lib/customHooks/useDataLayer'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  red: {
    color: 'red',
  },
  subtitle: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fffbe6',
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    borderRadius: 5,
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
  arrowIcon: {
    transition: 'transform 200ms ease-in-out',
  },
  arrowIconActive: {
    transform: 'rotate(180deg)',
  },
}))

/**
+ * That's so ugly, but fuck ittttttttttttttttttttttt
+ */
const VOUCHER_FIELDS = [
  'euroGold',
  'euroPalladium',
  'euroPlatinum',
  'euroSilver',
  'gramGold',
  'gramPalladium',
  'gramPlatinum',
  'gramSilver',
]

const getVoucherValue = (voucher, metalName) => {
  if (voucher.percentageDiscount) {
    return `-${voucher.percentageDiscount}%`
  }
  const field = VOUCHER_FIELDS.find(
    field => field.toLowerCase().includes(metalName) && voucher[field] > 0
  )
  if (field) {
    return `${voucher[field]}${field.includes('gram') ? 'g' : '€'}`
  }

  return ''
}

const VoucherDetails = ({ voucher, metalName }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Grid item xs={5}>
        <InfoTextField label={translate('createSavingsPlan.voucherCode')} value={voucher.code} />
      </Grid>
      <Grid item xs={4}>
        <InfoTextField
          label={translate('createSavingsPlan.savingsPlan')}
          value={capitalizeFirstLetter(metalName ?? ' ')}
        />
      </Grid>
      <Grid item xs={3}>
        <InfoTextField
          label={translate('createSavingsPlan.value')}
          value={getVoucherValue(voucher, metalName)}
        />
      </Grid>
      <Grid item xs={12}>
        <ButtonBase disableRipple onClick={() => setIsOpen(oldIsOpen => !oldIsOpen)}>
          <Typography>{translate('createSavingsPlan.details')}</Typography>
          <KeyboardArrowDownIcon
            className={clsx(classes.arrowIcon, {
              [classes.arrowIconActive]: isOpen,
            })}
          />
        </ButtonBase>
        <Collapse in={isOpen}>
          <div dangerouslySetInnerHTML={{ __html: voucher.text }} />
        </Collapse>
      </Grid>
    </>
  )
}

const CreatePlanRequestControlDialog = ({ open, onClose, isFetching, onSubmit, noLogin }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const createPlanValues = useSelector(getFormCreatePlanData)
  const pushToDataLayer = useDataLayer()

  const data = transformDataForRequest({
    ...createPlanValues.pageOne,
    ...createPlanValues.pageTwo,
    ...createPlanValues.pageThree,
    countryDepartment: COUNTRY_DEPARTMENT.SWITZERLAND.value,
  })

  const userData = useSelector(getFormCreatePlanPageData(WORKFLOW_PAGE_2))
  const vouchers = useSelector(getBackendVoucherDataAllSuccess) ?? {}

  useEffect(() => {
    if (open) {
      pushToDataLayer(
        {
          event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
          funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.CONFIRMATION,
        },
        noLogin
      )
    }
  }, [pushToDataLayer, noLogin, open])
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" gutterBottom className={classes.bold} align="center">
          {translate('createSavingsPlan.controlDialogTitle')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CreatePlanRequestControlDialogMetals {...data} userData={userData} />
          </Grid>
          <Grid item xs={12} sm={6} container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" className={`${classes.bold} ${classes.subtitle}`}>
                {translate('createSavingsPlan.firstContractor')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InfoTextField
                label={translate('createSavingsPlan.controlDialogBankFullName')}
                value={`${userData?.firstName} ${userData?.lastName}`}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoTextField
                label={translate('formFields.dateOfBirth')}
                value={moment(userData?.dateOfBirth).format(translate('formFields.dateFormat'))}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoTextField
                label={translate('formFields.nationality')}
                value={userData?.nationality?.nationality_de}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoTextField label={translate('formFields.phone')} value={userData?.phone} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoTextField label={translate('formFields.mail')} value={userData?.email} />
            </Grid>
            <Grid item xs={6}>
              <InfoTextField label={translate('formFields.street')} value={userData?.street} />
            </Grid>
            <Grid item xs={6}>
              <InfoTextField
                label={translate('formFields.postCode')}
                value={userData?.postalCode}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoTextField label={translate('formFields.city')} value={userData?.city} />
            </Grid>
            <Grid item xs={6}>
              <InfoTextField
                label={translate('formFields.country')}
                value={userData?.country?.name_de}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" className={`${classes.bold} ${classes.subtitle}`}>
                {translate('createSavingsPlan.voucher')}
              </Typography>
            </Grid>
            {Object.entries(vouchers).map(
              (
                [
                  id,
                  {
                    data: { voucher },
                  },
                ],
                i,
                vouchersArray
              ) => {
                const metalName = data?.metals?.[id]

                return (
                  <>
                    <VoucherDetails
                      voucher={voucher}
                      metalName={metalName}
                      key={`voucher-details-${id}`}
                    />
                    {i !== vouchersArray.length - 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </>
                )
              }
            )}
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
        <DownloadDialogDocuments />
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
        <Typography variant="body2" paragraph>
          {translate('createSavingsPlan.controlDataInfo')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('actions.back')}</Button>
        <CustomSaveButton onClick={onSubmit} loading={isFetching}>
          {translate('actions.submit')}
        </CustomSaveButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreatePlanRequestControlDialog
