import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  CustomSaveButton,
  DialingCodeAdaper,
  FormLayout,
  SelectAdapter,
  TextFieldAdapter,
} from '../..'
import { PICKUP_STORES, SHIPPING_METHODS, SHIPPING_PAYMENT_MAPPING } from '../../../config'
import { transformPayoutCancelRequest } from '../../../lib/transform'
import { useTranslate } from '../../../lib/translate'
import { required, validateEmail, validatePhoneNumber } from '../../../lib/validation'
import { fetchCreatePayoutRequestActions } from '../../../redux/actions'
import {
  getBackendUserData,
  getFormRequestPayoutData,
  isBackendCreatePayoutFetching,
} from '../../../redux/selectors'
import { RadioAdapter } from '../../common/FormFields'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_1 } from './RequestPayoutPage1'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_2 } from './RequestPayoutPage2'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_3 } from './RequestPayoutPage3'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_4 } from './RequestPayoutPage4'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  greyText: {
    color: theme.palette.text.hint,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const PaymentExample = () => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box mt={2} ml={4} mr={2}>
      <Typography variant="body1" paragraph className={`${classes.greyText} ${classes.boldText}`}>
        {translate('cancelPlans.forExample')}
      </Typography>
      <Typography variant="body1" paragraph className={classes.greyText}>
        {translate('cancelPlans.infoAbout')}
      </Typography>
      <Typography variant="body1" paragraph className={classes.greyText}>
        {translate('cancelPlans.infoAboutCosts')}
      </Typography>
    </Box>
  )
}

const prepRequestObject = data => {
  const savingsPlan = get(data, `${WORKFLOW_PAGE_1}.savingsPlanId`)
  const secondPage = get(data, WORKFLOW_PAGE_2)
  const amount = get(data, `${WORKFLOW_PAGE_3}.amountToSell`)
  const lineItems = Object.values(get(data, `${WORKFLOW_PAGE_4}`, {})).reduce(
    // eslint-disable-next-line no-sequences
    (arr, val) => (arr.push(val), arr),
    []
  )
  return {
    savingsPlan,
    ...secondPage,
    amount,
    lineItems,
  }
}

const RequestPayoutPageFive = ({ requestData, createRequest, isFetching, userData }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()

  const [shippingMethod, setShippingMethod] = useState(null)

  const onSubmit = ({ dialCode, phoneNr, pickUpStore, email, ...rest }) => {
    const data = prepRequestObject(requestData)
    const phone = dialCode?.dial_code && dialCode.dial_code.concat(phoneNr)
    const requestObject = transformPayoutCancelRequest({
      ...(shippingMethod === SHIPPING_METHODS.PICKUP && { phone, pickUpStore, email }),
      ...rest,
      ...data,
    })

    createRequest({
      history,
      values: requestObject,
    })
  }

  return (
    <FormLayout title={translate('payoutRequest.title')} wideLayout showClose isSignedIn>
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <FormControl>
                      <RadioGroup
                        value={shippingMethod}
                        onChange={event => setShippingMethod(event.target.value)}
                      >
                        <FormControlLabel
                          value={SHIPPING_METHODS.PICKUP}
                          control={<Radio />}
                          label={
                            <Typography variant="body1" className={classes.boldText}>
                              {translate('cancelPlans.methodPickup')}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={SHIPPING_METHODS.DELIVERY}
                          control={<Radio />}
                          label={
                            <Typography variant="body1" className={classes.boldText}>
                              {translate('cancelPlans.methodDelivery')}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <Collapse in={shippingMethod === SHIPPING_METHODS.PICKUP} unmountOnExit>
                      <Box marginY={4}>
                        <Divider />
                      </Box>
                      <Box mb={3}>
                        <Typography variant="h6" paragraph className={classes.boldText}>
                          {translate('cancelPlans.personalPickup')}
                        </Typography>
                        <Field
                          name="pickUpStore"
                          component={SelectAdapter}
                          fullWidth
                          validate={required}
                          label={translate('cancelPlans.fields.selectCity')}
                        >
                          {Object.values(PICKUP_STORES).map((option, index) => (
                            <MenuItem value={option.value} key={index}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Box>
                      <Typography variant="h6" paragraph className={classes.boldText}>
                        {translate('cancelPlans.appointmentContact')}
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <Field
                            name="dialCode"
                            component={DialingCodeAdaper}
                            validate={required}
                            label={translate('formFields.dialCode')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Field
                            name="phoneNr"
                            component={TextFieldAdapter}
                            validate={validatePhoneNumber}
                            type="tel"
                            label={translate('formFields.phone')}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Field
                        name="email"
                        component={TextFieldAdapter}
                        fullWidth
                        label={translate('formFields.mail')}
                        validate={validateEmail}
                      />
                      <Field name="shippingPayment" component={RadioAdapter} validate={required}>
                        <Box mt={2}>
                          <FormControlLabel
                            value={SHIPPING_PAYMENT_MAPPING.STORE.key}
                            control={<Radio />}
                            label={translate(
                              `cancelPlans.fields.${SHIPPING_PAYMENT_MAPPING.STORE.label}`
                            )}
                          />
                        </Box>
                        <Box mt={2}>
                          <FormControlLabel
                            value={SHIPPING_PAYMENT_MAPPING.STORE_PLAN.key}
                            control={<Radio />}
                            label={translate(
                              `cancelPlans.fields.${SHIPPING_PAYMENT_MAPPING.STORE_PLAN.label}`
                            )}
                          />
                        </Box>
                        <Box mt={2}>
                          <FormControlLabel
                            value={SHIPPING_PAYMENT_MAPPING.STORE_TRANSFER.key}
                            control={<Radio />}
                            label={translate(
                              `cancelPlans.fields.${SHIPPING_PAYMENT_MAPPING.STORE_TRANSFER.label}`
                            )}
                          />
                        </Box>
                      </Field>
                      <PaymentExample />
                    </Collapse>
                    <Collapse in={shippingMethod === SHIPPING_METHODS.DELIVERY} unmountOnExit>
                      <Box marginY={4}>
                        <Divider />
                      </Box>
                      <Typography variant="body1" paragraph className={classes.boldText}>
                        {translate('cancelPlans.deliveryInfo')}
                      </Typography>
                      <Box mb={2} textAlign="center">
                        <Typography>{`${userData?.firstName} ${userData?.lastName}`}</Typography>
                        <Typography>{userData?.street}</Typography>
                        <Typography>{`${userData?.postalCode} ${userData?.city}`}</Typography>
                      </Box>
                      <Typography variant="body1">
                        {translate('cancelPlans.chooseDeliveryPayment')}
                      </Typography>
                      <Field name="shippingPayment" component={RadioAdapter} validate={required}>
                        <Box mt={2}>
                          <FormControlLabel
                            value={SHIPPING_PAYMENT_MAPPING.INVOICE.key}
                            control={<Radio />}
                            label={translate(
                              `cancelPlans.fields.${SHIPPING_PAYMENT_MAPPING.INVOICE.label}`
                            )}
                          />
                        </Box>
                        <Box mt={2}>
                          <FormControlLabel
                            value={SHIPPING_PAYMENT_MAPPING.SAVINGS_PLAN.key}
                            control={<Radio />}
                            label={translate(
                              `cancelPlans.fields.${SHIPPING_PAYMENT_MAPPING.SAVINGS_PLAN.label}`
                            )}
                          />
                        </Box>
                      </Field>
                      <PaymentExample />
                    </Collapse>
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton loading={isFetching} disabled={!shippingMethod}>
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  requestData: getFormRequestPayoutData(state),
  isFetching: isBackendCreatePayoutFetching(state),
  userData: getBackendUserData(state),
})

const mapDispatchToProps = {
  createRequest: fetchCreatePayoutRequestActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestPayoutPageFive)
