import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import filter from 'lodash/filter'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { InfoDialog, SkeletonTable } from '../../'
import { INVOICE_STATUS, INVOICE_STATUS_LABEL_MAPPING, INVOICE_TYPE } from '../../../config'
import useCurrency from '../../../lib/customHooks/useCurrency'
import useDialog from '../../../lib/customHooks/useDialog'
import { formatGram } from '../../../lib/miscellaneous'
import { useTranslate } from '../../../lib/translate'
import { performanceFormatter, sortPaymentsTableData } from '../../../lib/util'
import { fetchDownloadFileActions } from '../../../redux/actions'
import { getBackendSavingsPlansData, isBackendDownloadFileFetching } from '../../../redux/selectors'
import LoadingIconButton from '../LoadingIconButton'
import CustomTableHead from './CustomTableHead'
import {
  StyledDatePicker,
  StyledTab,
  StyledTableCell,
  StyledTableContainer,
  StyledTabs,
} from './styled'

const useStyles = makeStyles(theme => ({
  tableRow: {
    '& > *': {
      border: 'unset',
    },
  },
  customHeadShadow: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
    '& th:first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& th:last-child': {
      borderRadius: '0 10px 10px 0',
    },
  },
  displayFlex: {
    display: 'flex',
  },
  dateFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  red: {
    color: theme.palette.error.main,
  },
  green: {
    color: theme.palette.success.main,
  },
}))

const _CustomTableRow = ({ item, relatedPlan, fetchDownloadFile, isFetchingDownloadFile }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const { performance, metalName, brutto, invoiceDate, description, status, id, gram, price } =
    item || {}
  // we only want to display the id and the metal from the description.
  // Format is "id sparplan metal"
  // So we simply split the string and take the first element
  // Todo: Maybe write in separate fields in sf?
  const descrArray = description?.split(' ')
  const descriptionId = descrArray?.[0]

  const [clicked, setClicked] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const handleDownload = () => {
    fetchDownloadFile({ invoice: id, name: `${invoiceDate}_${translate('fileName.invoice')}` })
    setClicked(true)
  }

  useEffect(() => {
    if (isFetchingDownloadFile && clicked) {
      setIsFetching(true)
    }
  }, [clicked, isFetchingDownloadFile])

  useEffect(() => {
    if (!isFetchingDownloadFile) {
      setIsFetching(false)
      setClicked(false)
    }
  }, [isFetchingDownloadFile])

  return (
    <TableRow className={classes.tableRow}>
      <StyledTableCell>{descriptionId}</StyledTableCell>
      <StyledTableCell align="right">{metalName ? translate(`metals.${metalName?.toLowerCase()}`) : null}</StyledTableCell>
      <StyledTableCell align="right">
        {relatedPlan?.customName || relatedPlan?.name}
      </StyledTableCell>
      <StyledTableCell align="right">
        {moment(invoiceDate).format(translate('formFields.dateFormat'))}
      </StyledTableCell>
      <StyledTableCell align="right">{displayCurrency(brutto)}</StyledTableCell>
      <StyledTableCell align="right">{formatGram(gram)}</StyledTableCell>
      <StyledTableCell align="right">{`${displayCurrency(price)}/g`}</StyledTableCell>
      <StyledTableCell
        align="right"
        className={performance ? (performance >= 0 ? classes.green : classes.red) : ''}
      >
        {performance ? performanceFormatter.format(performance / 100) : '-'}
      </StyledTableCell>
      <StyledTableCell align="right">
        {status && translate(INVOICE_STATUS_LABEL_MAPPING[status])}
      </StyledTableCell>
      <StyledTableCell align="right" className={classes.displayFlex}>
        <LoadingIconButton
          icon={<GetAppIcon />}
          small
          onClick={handleDownload}
          loading={isFetching}
          disabled={status !== INVOICE_STATUS.INVOICE_CREATED}
        />
      </StyledTableCell>
    </TableRow>
  )
}

const mapStateToPropsRow = (state, { item: { savingsPlan } }) => {
  const relatedPlan = find(getBackendSavingsPlansData(state), {
    id: savingsPlan,
  })

  return {
    relatedPlan,
    isFetchingDownloadFile: isBackendDownloadFileFetching(state),
  }
}

const mapDispatchToPropsRow = {
  fetchDownloadFile: fetchDownloadFileActions.requestAction,
}

const CustomTableRow = connect(mapStateToPropsRow, mapDispatchToPropsRow)(_CustomTableRow)

const PaymentsTable = ({ data, loading }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [tableData, setTableData] = useState(data)
  const [tabValue, setTabValue] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderCol, setOrderCol] = useState('invoiceDate')
  const [filters, setFilters] = useState({
    from: null,
    to: null,
    type: null,
  })
  const [dateFilter, setDateFilter] = useState({
    from: null,
    to: null,
  })
  const [
    showDialogPerformance,
    { openDialog: openDialogPerformance, closeDialog: closeDialogPerformance },
  ] = useDialog()

  const COLUMNS = [
    {
      name: 'description',
      label: 'table.description',
    },
    {
      name: 'metal',
      label: 'table.metal',
    },  
    {
      name: 'savingsPlanName',
      label: 'table.savingsPlan',
    },
    {
      name: 'invoiceDate',
      label: 'table.invoiceDate',
    },
    {
      name: 'brutto',
      label: 'table.amount',
    },
    {
      name: 'gram',
      label: 'table.gram',
    },
    {
      name: 'price',
      label: 'table.gramPrice',
    },
    {
      name: 'performance',
      label: 'table.performance',
      dialogControl: openDialogPerformance,
    },
    {
      name: 'status',
      label: 'table.status',
    },
    {
      name: 'download',
      label: '',
    },
  ]

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    if (newValue === 0) {
      setFilters(old => ({ ...old, type: null, from: null, to: null }))
      setDateFilter({ from: null, to: null })
    } else if (newValue === 1) {
      setFilters(old => ({ ...old, type: INVOICE_TYPE.DEPOSIT }))
    } else if (newValue === 2) {
      setFilters(old => ({ ...old, type: INVOICE_TYPE.WITHDRAW }))
    }
  }

  const handleSort = column => {
    const isAsc = orderCol === column && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderCol(column)
  }

  const handleDateChange = isFromDate => value => {
    if (isFromDate) {
      setDateFilter(old =>
        value.isAfter(old.to) ? { from: value, to: value } : { ...old, from: value }
      )
    } else {
      setDateFilter(old =>
        value.isBefore(old.from) ? { from: value, to: value } : { ...old, to: value }
      )
    }
  }

  const handleApplyDateFilter = () => {
    setFilters(old => ({ ...old, ...dateFilter }))
  }

  useEffect(() => {
    setPage(0)
    setTableData(
      filter(
        data,
        itm =>
          (!filters.type || itm.type === filters.type) &&
          (!filters.from || moment(itm?.invoiceDate).isSameOrAfter(filters.from, 'day')) &&
          (!filters.to || moment(itm?.invoiceDate).isSameOrBefore(filters.to, 'day'))
      )
    )
  }, [data, filters.from, filters.to, filters.type])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} lg={6}>
            <StyledTabs value={tabValue} onChange={handleTabChange}>
              <StyledTab label={translate('table.tabAll')} />
              <StyledTab label={translate('table.tabDeposit')} />
              <StyledTab label={translate('table.tabWithdraw')} />
            </StyledTabs>
          </Grid>
          <Grid item xs={12} lg={6} className={classes.dateFilterContainer}>
            <Box display="flex">
              <StyledDatePicker
                emptyLabel={translate('formFields.from')}
                value={dateFilter.from}
                onChange={handleDateChange(true)}
                format={translate('formFields.dateFormat')}
              />
              <StyledDatePicker
                emptyLabel={translate('formFields.to')}
                value={dateFilter.to}
                onChange={handleDateChange(false)}
                format={translate('formFields.dateFormat')}
              />
              <Button variant="contained" color="secondary" onClick={handleApplyDateFilter}>
                {translate('table.filterDate')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <SkeletonTable />
          ) : (
            <>
              <StyledTableContainer>
                <Table>
                  <CustomTableHead
                    items={COLUMNS}
                    order={order}
                    orderCol={orderCol}
                    onSort={handleSort}
                  />
                  <TableBody>
                    {Array.isArray(tableData) &&
                      orderBy(tableData, sortPaymentsTableData(orderCol, order), order)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, i) => <CustomTableRow key={`table-row-${i}`} item={item} />)}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={tableData?.length || 0}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={translate('table.rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from} - ${to} ${translate('table.of')} ${count}`
                }
                page={page}
                onChangePage={(event, newPage) => setPage(newPage)}
                onChangeRowsPerPage={event => {
                  setRowsPerPage(event.target.value)
                  setPage(0)
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <InfoDialog
        open={showDialogPerformance}
        onClose={closeDialogPerformance}
        title={translate('table.performance')}
        infoText={<Typography>{translate('table.dialog')}</Typography>}
      />
    </>
  )
}

PaymentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

export default PaymentsTable
