import { Box, CardMedia, Container, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'

import useAppLogo from '../../lib/customHooks/useAppLogo'
import { useSetLocale, useTranslate } from '../../lib/translate'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  img: {
    maxHeight: '4rem',
    width: 'unset',
    objectFit: 'contain',
  },
}))

const PrivacyPolicyPage = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const appLogo = useAppLogo()
  const setLocale = useSetLocale()
  const countryCode = 'CH'

  useEffect(() => {
    setLocale(countryCode.toLowerCase())
  }, [countryCode, setLocale])

  return (
    <Container maxWidth="md">
      <Paper>
        <Box p={4} minHeight="100vh">
          <Box width="10rem" my={3}>
            <CardMedia className={classes.img} component="img" alt="App Logo" image={appLogo} />
          </Box>
          <Typography variant="h4" align="center" paragraph>
            {translate('privacyPolicy.title')}
          </Typography>
          <Typography gutterBottom align="justify">
            {translate('privacyPolicy.sub0sub1')}
          </Typography>
          <Typography gutterBottom align="justify">
            {translate('privacyPolicy.sub0sub2')}
          </Typography>
          <Typography paragraph align="justify">
            {translate('privacyPolicy.sub0sub3')}
          </Typography>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyPolicy.sub1')}
          </Typography>
          <Typography gutterBottom>{translate('privacyPolicy.sub1sub1')}</Typography>
          <Typography gutterBottom>{translate('privacyPolicy.sub1sub2')}</Typography>
          <Typography paragraph>{translate('privacyPolicy.sub1sub3')}</Typography>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyPolicy.sub2')}
          </Typography>
          <Typography gutterBottom>{translate('privacyPolicy.sub2sub1')}</Typography>
          <Typography>{translate(`privacyPolicy.sub2sub2${countryCode}`)}</Typography>
          <Typography>{translate(`privacyPolicy.sub2sub3${countryCode}`)}</Typography>
          <Typography paragraph>{translate(`privacyPolicy.sub2sub4${countryCode}`)}</Typography>
          <Typography>{translate(`privacyPolicy.sub2sub5${countryCode}`)}</Typography>
          <Typography>{translate(`privacyPolicy.sub2sub6${countryCode}`)}</Typography>
          <Typography gutterBottom>{translate(`privacyPolicy.sub2sub7${countryCode}`)}</Typography>
          <Typography>{translate(`privacyPolicy.sub2sub8${countryCode}`)}</Typography>
          <Typography>{translate('privacyPolicy.sub2sub9')}</Typography>
          <Typography paragraph>{translate(`privacyPolicy.sub2sub10${countryCode}`)}</Typography>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyPolicy.sub3')}
          </Typography>
          <Typography align="justify">{translate('privacyPolicy.sub3sub1')}</Typography>
          <Typography align="justify">
            <ul>
              <li>{translate('privacyPolicy.sub3sub1dot1')}</li>
              <li>{translate('privacyPolicy.sub3sub1dot2')}</li>
              <li>{translate('privacyPolicy.sub3sub1dot3')}</li>
              <li>{translate('privacyPolicy.sub3sub1dot4')}</li>
              <li>{translate('privacyPolicy.sub3sub1dot5')}</li>
            </ul>
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub3sub2')}
          </Typography>
          <Typography paragraph align="justify" className={classes.bold}>
            {translate('privacyPolicy.sub3sub3')}
          </Typography>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyPolicy.sub4')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub1')}
          </Typography>
          <Typography variant="h6">{translate('privacyPolicy.sub4sub2')}</Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub2sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub2sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub2sub3')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub2sub4')}
          </Typography>
          <Typography variant="h6">{translate('privacyPolicy.sub4sub3')}</Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub3sub1')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub3sub2')}
          </Typography>
          <Typography variant="h6">{translate('privacyPolicy.sub4sub4')}</Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub4sub1')}
          </Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub4sub2')}</Typography>
          <Typography align="justify">
            <ul>
              <li>{translate('privacyPolicy.sub4sub4sub2dot1')}</li>
              <li>{translate('privacyPolicy.sub4sub4sub2dot2')}</li>
              <li>{translate('privacyPolicy.sub4sub4sub2dot3')}</li>
            </ul>
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub4sub3')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate(`privacyPolicy.sub4sub4sub4${countryCode}`)}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub4sub5')}
          </Typography>
          <Typography align="justify" variant="h6">
            {translate('privacyPolicy.sub4sub5')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub5sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub5sub2')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub5sub3')}
          </Typography>
          <Typography variant="h6">{translate('privacyPolicy.sub4sub6')}</Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub6sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub6sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub6sub3')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub6sub4')}
          </Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub6sub5')}</Typography>
          <Typography>
            <ul>
              <li>{translate('privacyPolicy.sub4sub6sub5dot1')}</li>
              <li>{translate('privacyPolicy.sub4sub6sub5dot2')}</li>
              <li>{translate('privacyPolicy.sub4sub6sub5dot3')}</li>
            </ul>
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub6sub6')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub6sub7')}
          </Typography>
          <Typography align="justify" variant="h6">
            {translate('privacyPolicy.sub4sub7')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub7sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub7sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub7sub3')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub7sub4')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub7sub5')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub7sub6')}
          </Typography>
          <Typography align="justify" variant="h6">
            {translate('privacyPolicy.sub4sub8')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub8sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub8sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub8sub3')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub8sub4')}
          </Typography>
          <Typography align="justify" variant="h6">
            {translate('privacyPolicy.sub4sub9')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub9sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub9sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub9sub3')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub9sub4')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub9sub5')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub9sub6')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub9sub7')}
          </Typography>
          <Typography align="justify" variant="h6">
            {translate('privacyPolicy.sub4sub10')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub10sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub10sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub10sub3')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub10sub4')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub10sub5')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub10sub6')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub10sub7')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub10sub8')}
          </Typography>
          <Typography variant="h6">{translate('privacyPolicy.sub4sub11')}</Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub11sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub11sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub11sub3')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub11sub4')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub11sub5')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub11sub6')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub11sub7')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub11sub8')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub11sub9')}
          </Typography>
          <Typography variant="h6">{translate('privacyPolicy.sub4sub12')}</Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub12sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub12sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub12sub3')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub12sub4')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub12sub5')}
          </Typography>
          <Typography variant="h6">{translate('privacyPolicy.sub4sub13')}</Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub3')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub4')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub5')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub6')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub7')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub8')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub13sub9')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub13sub10')}
          </Typography>
          <Typography variant="h6">{translate('privacyPolicy.sub4sub14')}</Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub14sub1')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub14sub2')}
          </Typography>
          <Typography align="justify" gutterBottom>
            {translate('privacyPolicy.sub4sub14sub3')}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub14sub4')}
          </Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub14sub5')}</Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub14sub6')}</Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub14sub7')}
          </Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub14sub8')}</Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub14sub9')}</Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub14sub10')}</Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub14sub11')}
          </Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub14sub12')}</Typography>
          <Typography align="justify">{translate('privacyPolicy.sub4sub14sub13')}</Typography>
          <Typography align="justify" paragraph>
            {translate('privacyPolicy.sub4sub14sub14')}
          </Typography>
        </Box>
      </Paper>
    </Container>
  )
}

export default PrivacyPolicyPage
