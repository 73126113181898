import moment from 'moment'

import {
  BOOLEANS,
  METAL_TYPE,
  REQUEST_RECORD_TYPE_MAPPING,
  SHIPPING_PAYMENT_MAPPING,
} from '../config'

/**
 * TRANSFORM PLAN DATA FOR USE IN DASHBOARD, IF THE SALESFORCE FIELDS ARE FINALIZED, ADJUST THE SAVINGSPLANCARD AND REMOVE THE TRANSFORM FUNCTIONS
 */
export const transformPlanDataForDashboardCard = ({
  currentValue,
  totalPerformance,
  metalName,
  ...rest
}) => ({
  ...rest,
  value: currentValue,
  valueChange: totalPerformance,
  metal: metalName.toLowerCase(),
})

export const transformDataForRequest = ({
  goldMonthly,
  silberMonthly,
  platinMonthly,
  palladiumMonthly,
  goldStartMonth,
  silberStartMonth,
  platinStartMonth,
  palladiumStartMonth,
  gold,
  silber,
  platin,
  palladium,
  password,
  firstName,
  lastName,
  postalCode,
  country,
  nationality,
  dateOfBirth,
  isPoliticallyExpose,
  beneficialOwner,
  metals,
  selectedVoucherMetal,
  pepOffice,
  salutation,
  title,
  street,
  city,
  state,
  email,
  countryDepartment,
  copyReceived,
}) => ({
  metals,
  copyReceived: copyReceived ?? false,
  selectedVoucherMetal,
  pepOffice,
  salutation,
  title,
  street,
  city,
  state,
  email,
  countryDepartment,
  beneficialOwner: beneficialOwner === BOOLEANS.TRUE,
  noPoliticallyExpose: isPoliticallyExpose === BOOLEANS.FALSE,
  goldMonthly: goldMonthly,
  silverMonthly: silberMonthly,
  platinumMonthly: platinMonthly,
  palladiumMonthly: palladiumMonthly,
  goldStartMonth: goldStartMonth ? goldStartMonth.value : undefined,
  silverStartMonth: silberStartMonth ? silberStartMonth.value : undefined,
  platinumStartMonth: platinStartMonth ? platinStartMonth.value : undefined,
  palladiumStartMonth: palladiumStartMonth ? palladiumStartMonth.value : undefined,
  total:
    (+goldMonthly || 0) + (+silberMonthly || 0) + (+platinMonthly || 0) + (+palladiumMonthly || 0),
  gold: gold ?? false,
  silver: silber ?? false,
  platinum: platin ?? false,
  palladium: palladium ?? false,
  ...(firstName && { firstname: firstName }),
  ...(lastName && { lastname: lastName }),
  ...(password && { pass: password }),
  ...(postalCode && { zip: postalCode }),
  ...(country?.code && { country: country.code }),
  ...(nationality?.code && { nationality: nationality.code }),
  ...(dateOfBirth && { dob: moment(dateOfBirth).format('YYYY-MM-DD') }),
})

export const transformPayoutCancelRequest = ({
  amount,
  physicalDelivery,
  shippingPayment,
  ...rest
}) => ({
  amount: amount ? parseFloat(amount) : undefined,
  physicalDelivery: physicalDelivery ?? false,
  payForFollowUpCosts: shippingPayment === SHIPPING_PAYMENT_MAPPING.STORE.key ? true : undefined,
  shippingPayment,
  ...rest,
})

export const transformRequestForTable = ({
  recordTypeId,
  isGoldPlan,
  isSilverPlan,
  isPalladiumPlan,
  isPlatinumPlan,
  total,
  ...rest
}) => {
  const variety = Object.entries({
    [`metals.${METAL_TYPE.GOLD}`]: isGoldPlan,
    [`metals.${METAL_TYPE.SILVER}`]: isSilverPlan,
    [`metals.${METAL_TYPE.PALLADIUM}`]: isPalladiumPlan,
    [`metals.${METAL_TYPE.PLATINUM}`]: isPlatinumPlan,
    // eslint-disable-next-line no-sequences
  }).reduce((arr, [k, v]) => (v && arr.push(k), arr), [])

  return {
    type: REQUEST_RECORD_TYPE_MAPPING[recordTypeId],
    recordTypeId,
    variety,
    rate: total,
    ...rest,
  }
}

export const transformCreateConsentRequest = ({
  pepOffice,
  isPoliticallyExpose,
  beneficialOwner,
  firstName,
  lastName,
  street,
  city,
  country,
  dateOfBirth,
  nationality,
  postalCode,
}) => {
  let customFilesData = {
    beneficialOwner: true,
    isPep: false,
  }
  if (beneficialOwner === BOOLEANS.FALSE) {
    customFilesData.city = city
    customFilesData.country = country.code
    customFilesData.dateOfBirth = dateOfBirth
    customFilesData.nationality = nationality.code
    customFilesData.postalCode = postalCode
    customFilesData.beneficialOwner = beneficialOwner === BOOLEANS.TRUE
    customFilesData.firstName = firstName
    customFilesData.lastName = lastName
    customFilesData.streetAndNumber = street
  }
  if (isPoliticallyExpose === BOOLEANS.TRUE) {
    customFilesData.isPep = isPoliticallyExpose === BOOLEANS.TRUE
    customFilesData.politicalPosition = pepOffice
  }

  return customFilesData
}
