import { Box, Button, Card, CardContent, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { GOOGLE_TAG_MANAGER, ROUTES } from '../../../config'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import useDialog from '../../../lib/customHooks/useDialog'
import { useTranslate } from '../../../lib/translate'
import {
  getBackendInitiateOnlineIdentData,
  getBackendRegisterAndInitiateOnlineIdentData,
} from '../../../redux/selectors'
import ContinueFromOnlineIdentDialog from '../../common/ContinueFromOnlineIdentDialog'
import { DRAWER_WIDTH } from '../../common/CustomDrawer'
import CustomSaveButton from '../../common/CustomSaveButton'
import FormLayout from '../../layout/FormLayout'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  centeredBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  iframe: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100vw - ${DRAWER_WIDTH}px - ${theme.spacing(2)}px)`,
      left: `calc(50% - 50vw + ${DRAWER_WIDTH / 2}px + ${theme.spacing()}px)`,
    },
    width: '100%',
    position: 'absolute',
    height: '100%',
    border: '1px solid black',
  },
  iframeContainer: {
    position: 'relative',
    height: '70vh',
  },
}))

const CreateSavingsPlanPageThree = ({ noLogin, onlineIdentData, noLoginOnlineIdentData }) => {
  const translate = useTranslate()
  const history = useHistory()
  const classes = useStyles()
  const pushToDataLayer = useDataLayer()

  const [isIframeOpen, setIsIframeOpen] = useState(false)
  const [showContinueDialog, { openDialog: openContinueDialog, closeDialog: closeContinueDialog }] =
    useDialog()

  const identSessionUrl = noLogin ? noLoginOnlineIdentData.sessionUrl : onlineIdentData

  useEffect(() => {
    pushToDataLayer(
      {
        event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
        funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.PROOF_OF_IDENTITY,
      },
      noLogin
    )
  }, [pushToDataLayer, noLogin])
  const handleClickContinue = () => {
    history.push(noLogin ? ROUTES.NO_LOGIN_PLAN_FOUR : ROUTES.CREATE_SAVINGS_PLAN_STEP_FOUR)
  }

  return (
    <>
      <FormLayout
        title={translate('createSavingsPlan.title')}
        subTitle={translate('createSavingsPlan.onlineIdent')}
        pageCount="3 / 4"
        wideLayout
        showClose={!noLogin}
        isSignedIn={!noLogin}
      >
        <Box mt={10} mb={10} className={classes.contentColumns}>
          {!isIframeOpen && (
            <>
              <Typography variant="body1" paragraph>
                {translate('createSavingsPlan.onlineIdentExplanation')}
              </Typography>
              <Card>
                <CardContent>
                  <Box className={classes.centeredBox}>
                    <Button onClick={() => setIsIframeOpen(true)} disabled={!identSessionUrl}>
                      {translate('createSavingsPlan.onlineIdentStartButton')}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </>
          )}
          {isIframeOpen && (
            <Box className={classes.iframeContainer}>
              <iframe
                src={identSessionUrl}
                title="Online idenetification"
                className={classes.iframe}
                allow="camera *; microphone *"
                allowfullscreen=""
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" mt={5}>
            <Button variant="text" onClick={() => history.goBack()}>
              {translate('actions.back')}
            </Button>
            <CustomSaveButton
              disabled={!isIframeOpen || showContinueDialog}
              onClick={openContinueDialog}
            >
              {translate('finishRegistration.continue')}
            </CustomSaveButton>
          </Box>
        </Box>
      </FormLayout>
      <ContinueFromOnlineIdentDialog
        open={showContinueDialog}
        onClose={closeContinueDialog}
        onClickContinue={handleClickContinue}
      />
    </>
  )
}

const mapStateToProps = state => ({
  onlineIdentData: getBackendInitiateOnlineIdentData(state),
  noLoginOnlineIdentData: getBackendRegisterAndInitiateOnlineIdentData(state),
})

export default connect(mapStateToProps)(CreateSavingsPlanPageThree)
