import { Box, Typography } from '@material-ui/core'
import size from 'lodash/size'
import some from 'lodash/some'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { CodeInput, CustomSaveButton, FormLayout } from '..'
import { COUNTRY_DEPARTMENT, GOOGLE_TAG_MANAGER } from '../../config'
import useDataLayer from '../../lib/customHooks/useDataLayer'
import { transformDataForRequest } from '../../lib/transform'
import { useTranslate } from '../../lib/translate'
import { fetchCreateSavingsPlanRequestActions, fetchVerifyCodeActions } from '../../redux/actions'
import {
  getFormCreatePlanData,
  isBackendCreatePlanFetching,
  isBackendVerifyCodeFetching,
} from '../../redux/selectors'

const TwoFactorAuthenticationPage = ({
  verifyCode,
  isFetching,
  isCreatePlan,
  createSavingsPlanRequest,
  createPlanValues,
  noLogin,
}) => {
  const translate = useTranslate()
  const { requestId } = useParams()
  const history = useHistory()
  const [values, setValues] = useState({})
  const pushToDataLayer = useDataLayer()

  const onSubmit = () => {
    const code = Object.values(values).join('')
    if (isCreatePlan) {
      const transformedData = transformDataForRequest({
        ...createPlanValues.pageOne,
        ...createPlanValues.pageTwo,
        ...createPlanValues.pageThree,
        ...createPlanValues.pageFour,
        countryDepartment: COUNTRY_DEPARTMENT.SWITZERLAND.value,
      })
      createSavingsPlanRequest({
        ...transformedData,
        history,
        securityCode: code,
        noLogin,
        onSuccess: () => {
          pushToDataLayer({
            event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
            funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.COMPLETION,
          })
          pushToDataLayer({ event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_COMPLETE })
        },
      })
    } else {
      verifyCode({ code, history })
    }
  }

  return (
    <FormLayout isSignedIn={!!requestId}>
      <Box mb={5}>
        <Typography variant="h6" gutterBottom>
          <Box fontWeight="fontWeightBold" component="span">
            {translate('twoFactorAuthCheck.title')}
          </Box>
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {translate('twoFactorAuthCheck.subtitle')}
        </Typography>
      </Box>
      <Box mb={5}>
        <CodeInput values={values} setValues={setValues} onSubmit={onSubmit} showNewPhone />
      </Box>
      <Box textAlign="right" mb={5}>
        <CustomSaveButton
          disabled={size(values) < 4 || some(values, val => val === '')}
          loading={isFetching}
          onClick={onSubmit}
        >
          {translate('twoFactorAuthCheck.sendButton')}
        </CustomSaveButton>
      </Box>
    </FormLayout>
  )
}

TwoFactorAuthenticationPage.propTypes = {
  verifyCode: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
}

const mapStateToProps = state => ({
  createPlanValues: getFormCreatePlanData(state),
  isFetching: isBackendVerifyCodeFetching(state) || isBackendCreatePlanFetching(state),
})

const mapDispatchToProps = {
  createSavingsPlanRequest: fetchCreateSavingsPlanRequestActions.requestAction,
  verifyCode: fetchVerifyCodeActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthenticationPage)
