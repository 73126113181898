import moment from 'moment'

export const required = value => (value ? undefined : 'validation.required')

export const validatePassword = value => {
  if (!value) return 'validation.required'
  const caption = []
  if (value.length < 8) caption.push('validation.password.length')
  if (!/[A-Z]/.test(value)) caption.push('validation.password.uppercase')
  if (!/[a-z]/.test(value)) caption.push('validation.password.lowercase')
  if (!/[0-9]/.test(value)) caption.push('validation.password.number')
  if (!/[^A-Za-z0-9]/.test(value)) caption.push('validation.password.special')
  return caption.length ? ['validation.min', ...caption] : undefined
}

/**
 * This function has to be passed to the <Form> not <Field>
 * Care: The field names have to be password and confirmPassword
 * and they must not be nested in the formValues
 */
export const validateConfirmPassword = values => {
  const errors = {}
  errors.confirmPassword = required(values?.confirmPassword)
  if (values?.password !== values?.confirmPassword) {
    errors.confirmPassword = 'validation.doesNotMatch'
  }
  return errors
}
export const validateConfirmPasswordAndEmail2 = values => {
  const errors = validateConfirmPassword(values)
  errors.email2 = validateSameEmail(values)
  return errors
}

export const validateTwoFactorAuth = value => {
  if (!value) return true
  return parseInt(value) === 0 ? undefined : !parseInt(value) ? true : undefined
}

export const validateEmail = value => {
  const regex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+/g
  if (!value) return 'validation.required'
  if (value && !value.match(regex)) return 'validation.invalidEmail'
  return undefined
}

export const validateSameEmail = values => {
  if (!values.email2) return 'validation.required'
  if (values.email !== values.email2) return 'validation.doesNotMatch'
  return undefined
}

export const validatePhoneNumber = value => {
  if (!value) return 'validation.required'
  if (!parseInt(value)) return 'validation.numberRequired'
  if (value.startsWith('0')) return 'validation.phoneStartZero'
  return undefined
}

export const validateIban = value => {
  const regex =
    /^([A-Z]{2}[ -]?[0-9]{2})([ -]?[A-Z0-9]{0,5})(([ -]?[0-9]{2,4}){2,6})([ -]?[A-Z][0-9]{2})?$/
  if (!value) return 'validation.required'
  if (value && !value.match(regex)) return 'validation.invalidIban'
  return undefined
}

export const validateMin50 = value => {
  if (!value) return 'validation.required'
  if (parseInt(value) < 50) return 'validation.minAmount50'
  return undefined
}

export const validateMin = min => value => {
  if (!value) return 'validation.required'
  if (parseInt(value) < min) return 'validation.minSubceeded'
  return undefined
}

export const validateMin50Max = max => value => {
  if (!value) return 'validation.required'
  if (parseInt(value) < 50) return 'validation.minAmount50'
  if (parseInt(value) > max) return 'validation.maxExceeded'
  return undefined
}

export const validateMinMax = (min, max) => value => {
  if (!value) return 'validation.required'
  if (parseFloat(value) < min) return 'validation.minSubceeded'
  if (parseFloat(value) > max) return 'validation.maxExceeded'
  return undefined
}

export const validateProduct = value => {
  if (!value) return 'validation.required'
  if (!value.id || !value.qty) return 'validation.bothRequired'
  return undefined
}

export const validateDate = value => {
  if (!value) return 'validation.required'
  if (!moment(value).isValid()) return 'validation.invalidDateFormat'
  return undefined
}

export const validateDateToBeAfterDate = date => value => {
  if (!value) return 'validation.required'
  if (!value.isValid()) return 'validation.invalidDateFormat'
  if (value.isBefore(date)) return 'validation.notBeforeMinDate'
  return undefined
}

export const validateNewSavingsRate = min => value => {
  const val = parseInt(value)
  if (!value && val !== 0) return 'validation.required'
  if (val !== 0 && val < min) return 'validation.minSubceeded'
  return undefined
}

export const validateSecurityPageForm = (values, tab) => {
  const errors = {}

  if (tab === 1) {
    errors.password = validatePassword(values?.password)
    errors.confirmPassword = required(values?.confirmPassword)
    if (values?.password !== values?.confirmPassword) {
      errors.confirmPassword = 'validation.doesNotMatch'
    }
  } else if (tab === 2) {
    errors.email = validateEmail(values?.email)
  } else if (tab === 3) {
    errors.dialCode = required(values?.dialCode)
    errors.phoneNr = validatePhoneNumber(values?.phoneNr)
  }
  return errors
}

export const validateCountry = value => {
  if (!value) return 'validation.required'
  if (value.code !== 'CH') return 'validation.countryInCH'
  return undefined
}

export const validateCreateStep4Form = values => {
  const errors = {}

  if (values.iban || values.directDebitAuthorization) {
    errors.iban = required(values.iban)
    errors.directDebitAuthorization = required(values.directDebitAuthorization)
  }

  return errors
}

export const validateFileUpload = value => {
  if (!value) return 'validation.fileNotUploaded'
  return undefined
}
