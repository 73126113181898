import {
  Button,
  CircularProgress,
  Collapse,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { VOUCHER_SCREEN } from '../../../config'
import { useTranslate } from '../../../lib/translate'
import { fetchApplyVoucherActions, fetchVerifyVoucherActions } from '../../../redux/actions'
import {
  getBackendSavingsPlansData,
  getBackendVoucherData,
  isBackendVoucherFetching,
} from '../../../redux/selectors'
import InfoTextField from '../InfoTextField'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  voucherContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  voucherButton: {
    height: '100%',
  },
}))

const ApplyVoucherToSavingsPlanDialogContent = ({
  getIsFetchingVerifyVoucher,
  getVoucher,
  verifyVoucher,
  plans,
  applyVoucher,
  onClose,
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  const [code, setCode] = useState('')
  const initialSavingsPlanId = useParams().id
  const [savingsPlanId, setSavingsPlanId] = useState(initialSavingsPlanId)

  const [selectedSavingsPlanId, setSelectedSavingsPlanId] = useState(0)
  const plan = useMemo(() => plans.find(plan => plan.id === savingsPlanId), [savingsPlanId, plans])
  const { voucher } = useMemo(() => getVoucher(code) ?? {}, [code, getVoucher])
  const isFetchingVerifyVoucher = useMemo(
    () => getIsFetchingVerifyVoucher(code),
    [code, getIsFetchingVerifyVoucher]
  )

  const handleApplyVoucher = () => {
    applyVoucher({ savingsPlanId: plan.id, voucherId: voucher.id })
    onClose()
  }

  const handleVerifyVoucher = () => {
    verifyVoucher({
      id: code.toLowerCase(),
      code,
      gold: plan.isGoldPlan,
      silver: plan.isSilverPlan,
      platinum: plan.isPlatinumPlan,
      palladium: plan.isPalladiumPlan,
      screen: VOUCHER_SCREEN.PLAN_DETAILS,
    })
  }

  const handleVoucherKeyUpPress = event => {
    if (code && event.key === 'Enter') {
      event.preventDefault()
      handleVerifyVoucher()
    }
  }
  return (
    <DialogContent className={classes.dialogContent}>
      <Collapse in={!savingsPlanId}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <Grid container spacing={2} justify="center">
              <Grid item xs={7}>
                <Select
                  fullWidth
                  onChange={event => {
                    setSelectedSavingsPlanId(event.target.value)
                  }}
                  variant="outlined"
                  value={selectedSavingsPlanId}
                >
                  <MenuItem value={0} disabled>
                    Abo auswählen
                  </MenuItem>
                  {plans.map(plan => (
                    <MenuItem value={plan.id} key={`select-item-${plan.id}`}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Grid item xs={8}>
                          {plan.customName ?? plan.name}
                        </Grid>
                        <Grid item xs={3}>
                          {plan.metalName
                            ? translate(`metals.${plan.metalName?.toLowerCase()}`)
                            : null}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  className={classes.voucherButton}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSavingsPlanId(selectedSavingsPlanId)
                  }}
                  disabled={!selectedSavingsPlanId}
                >
                  {translate('applyVoucherDialog.continue')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="caption" color="textSecondary">
              {translate('applyVoucherDialog.selectSavingsPlanForVoucher')}
            </Typography>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={!voucher && savingsPlanId} unmountOnExit>
        <Grid container spacing={2} className={classes.voucherContainer}>
          <Grid item xs>
            <TextField
              label={translate('createSavingsPlan.voucherCode')}
              onChange={event => setCode(event.target.value)}
              value={code}
              variant="outlined"
              onKeyPress={handleVoucherKeyUpPress}
              onKeyUp={handleVoucherKeyUpPress}
              fullWidth
              className={classes.voucherInput}
            />
          </Grid>
          <Grid item xs="auto">
            <Button
              className={classes.voucherButton}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleVerifyVoucher}
              disabled={isFetchingVerifyVoucher || !code}
            >
              {isFetchingVerifyVoucher ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                translate('actions.verify')
              )}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              {translate('createSavingsPlan.voucherInfo')}
            </Typography>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={voucher} unmountOnExit>
        <Grid container>
          <Grid item xs={12} md={6}>
            <InfoTextField label={translate('applyVoucherDialog.voucher')} value={voucher?.name} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoTextField label={translate('applyVoucherDialog.savingsPlan')} value={plan?.name} />
          </Grid>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: voucher?.text }} />
          </Grid>
        </Grid>
        <Divider />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleApplyVoucher}
          fullWidth
          disabled={isFetchingVerifyVoucher || !code}
        >
          {isFetchingVerifyVoucher ? (
            <CircularProgress color="inherit" size={18} />
          ) : (
            translate('applyVoucherDialog.submitVoucher')
          )}
        </Button>
      </Collapse>
    </DialogContent>
  )
}

const mapStateToProps = state => ({
  getIsFetchingVerifyVoucher: isBackendVoucherFetching(state),
  getVoucher: getBackendVoucherData(state),
  plans: getBackendSavingsPlansData(state),
})

const mapDispatchToProps = {
  verifyVoucher: fetchVerifyVoucherActions.requestAction,
  applyVoucher: fetchApplyVoucherActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyVoucherToSavingsPlanDialogContent)
