import { Box, Card, CardContent, Input, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import size from 'lodash/size'
import some from 'lodash/some'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { KEY_CODES } from '../../config'
import { hideTelNumber } from '../../lib/hideData'
import { useTranslate } from '../../lib/translate'
import { fetchSendSecurityCodeActions } from '../../redux/actions'
import {getBackendLoginUserData, getBackendUserData, getTwoFactorAuthMode} from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  inputsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
  },
  inputSquared: {
    width: '4.5rem',
    height: '4rem',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    '& > input': {
      textAlign: 'center',
      fontSize: '1.75rem',
    },
    borderStyle: 'solid',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'rgba(134, 117, 79, 0.2)',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      width: '3.5rem',
      height: '3rem',
    },
  },
  focusedInput: {
    borderColor: theme.palette.secondary.main,
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const DigitInput = ({ index, value, onChange, changeFocus, focused, onSubmit }) => {
  const ref = useRef(null)
  const classes = useStyles()

  useEffect(() => {
    focused && ref.current.focus()
  }, [focused])

  const handleChange = event => {
    onChange(index, event.target.value)
  }

  const handleKeyUpPress = event => {
    const { ARROW_LEFT, ARROW_RIGHT, BACKSPACE, ENTER } = KEY_CODES

    if ([ARROW_LEFT, ARROW_RIGHT].includes(event.keyCode)) {
      changeFocus(event.keyCode)
    }

    if (BACKSPACE === event.keyCode) {
      !value && changeFocus(event.keyCode)
    }

    if (ENTER === event.keyCode) {
      typeof onSubmit === 'function' && onSubmit()
    }

    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  const handleFocus = event => {
    event.target.setSelectionRange(0, 1)
  }

  return (
    <Input
      className={classes.inputSquared}
      classes={{ focused: classes.focusedInput }}
      name={`digit-input-${index}`}
      inputProps={{
        maxLength: 1,
        pattern: '[0-9]*',
      }}
      disableUnderline
      inputRef={ref}
      autoComplete="off"
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyUpPress}
      onKeyUp={handleKeyUpPress}
      onFocus={handleFocus}
      inputMode="numeric"
      type="tel"
    />
  )
}

DigitInput.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  changeFocus: PropTypes.func.isRequired,
  focused: PropTypes.bool,
}

const CodeInput = ({
  values,
  setValues,
  phone,
  sendCode,
  onSubmit,
  textComponent,
  noResendCode,
  userInfo,
  showNewPhone,
  twoFactorAuthMode,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [focusedIndex, setFocusedIndex] = useState(0)
  const showEmailMessage = twoFactorAuthMode === 'email'
  const bodyTranslation = showEmailMessage ? 'twoFactorAuthCheck.body1Email' : 'twoFactorAuthCheck.body1Sms'
  const newContactInfoTranslation = showEmailMessage ? 'twoFactorAuthCheck.newEmail' : 'twoFactorAuthCheck.newPhoneNumber'

  const handleChange = (index, value) => {
    setValues(prev => ({ ...prev, [index]: value }))

    if (value) {
      setFocusedIndex(index < 3 ? index + 1 : index)
    }
  }

  const changeFocus = keyCode => {
    const { ARROW_LEFT, ARROW_RIGHT, BACKSPACE } = KEY_CODES
    if (isNaN(focusedIndex)) return
    if (keyCode === ARROW_LEFT) {
      focusedIndex > 0 && setFocusedIndex(focusedIndex - 1)
    }
    if (keyCode === ARROW_RIGHT) {
      focusedIndex < 3 && setFocusedIndex(focusedIndex + 1)
    }
    if (keyCode === BACKSPACE) {
      focusedIndex > 0 && setFocusedIndex(focusedIndex - 1)
    }
  }

  const handleResendCode = () => {
    sendCode()
  }

  return (
    <Card>
      <CardContent className={classes.cardContentPadding}>
        <Box mb={6}>
          {textComponent ?? (
            <Typography variant="body1">
              {translate(bodyTranslation)}
              <Typography variant="body1" component="span" className={classes.textBold}>
                {hideTelNumber(phone)}
              </Typography>
              {translate(newContactInfoTranslation)}
            </Typography>
          )}
        </Box>
        <Box className={classes.inputsWrapper}>
          <DigitInput
            index={0}
            value={values[0] || ''}
            focused={focusedIndex === 0}
            onChange={handleChange}
            changeFocus={changeFocus}
          />
          <DigitInput
            index={1}
            value={values[1] || ''}
            focused={focusedIndex === 1}
            onChange={handleChange}
            changeFocus={changeFocus}
          />
          <DigitInput
            index={2}
            value={values[2] || ''}
            focused={focusedIndex === 2}
            onChange={handleChange}
            changeFocus={changeFocus}
          />
          <DigitInput
            index={3}
            value={values[3] || ''}
            focused={focusedIndex === 3}
            onChange={handleChange}
            changeFocus={changeFocus}
            onSubmit={!(size(values) < 4 || some(values, val => val === '')) && onSubmit}
          />
        </Box>
        {noResendCode ? (
          <Box height="2rem" />
        ) : (
          <Box mt={5} mb={2}>
            <Typography variant="body1" gutterBottom>
              {translate('twoFactorAuthCheck.noCodeQuestion')}
            </Typography>
            <Link color="secondary" variant="body1" onClick={handleResendCode}>
              {translate('twoFactorAuthCheck.sendNewCode')}
            </Link>
          </Box>
        )}
        {showNewPhone && (
          <Typography variant="body1">
            <Link
              target="_blank"
              rel="noopener"
              color="secondary"
              href={`mailto:${
                userInfo?.email
              }?subject=Supportanfrage%20-%20neue%20Rufnummer%20f%C3%BCr%202FA%20Code&body=Liebes%20Edelmetallsparplan%20Team%2C%0D%0Aich%20habe%20eine%20neue%20Rufnummer%20und%20erhalte%20daher%20nicht%20mehr%20meinen%20Best%C3%A4tigungscode%20f%C3%BCr%20die%20Anmeldung%20am%20EMSP%20Kundenportal.%20Ich%20m%C3%B6chte%20die%20folgende%20neue%20Rufnummer%20hinterlegen%20(aktivieren)%20und%20bitte%20um%20Ihre%20Unterst%C3%BCtzung.%0D%0A%0D%0AViele%20Gr%C3%BC%C3%9Fe%0D%0A${
                userInfo?.firstName ?? ''
              }%20${userInfo?.lastName ?? ''}`}
            >
              {translate('twoFactorAuthCheck.newPhoneNumber')}
            </Link>
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

CodeInput.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  twoFactorAuthMode: PropTypes.string,
}

const mapStateToProps = state => ({
  phone: get(getBackendUserData(state), 'phone'),
  userInfo: getBackendLoginUserData(state),
  twoFactorAuthMode: getTwoFactorAuthMode(state)
})

const mapDispatchToProps = {
  sendCode: fetchSendSecurityCodeActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeInput)
