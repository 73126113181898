import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { Field, useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'

import { InfoTextField, SavingsPlanCard } from '..'
import { VOUCHER_SCREEN } from '../../config'
import { useTranslate } from '../../lib/translate'
import { required } from '../../lib/validation'
import { fetchVerifyVoucherActions } from '../../redux/actions'
import {
  getBackendVoucherDataAllSuccess,
  getFormCreatePlanPageData,
  isBackendVoucherFetching,
} from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  deleteButton: {
    borderRadius: 0,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  selectedCard: {
    boxShadow: '0 0 3px #86754F',
  },
}))

const CustomCardFormField = ({ input, meta, items }) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <>
      <Box my={2}>
        <Divider variant="middle" />
      </Box>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel component="legend" error={meta.touched && meta.invalid}>
              {translate('createSavingsPlan.selectVoucherPlan')}
            </FormLabel>
          </Grid>
          {items.map(itm => (
            <Grid item xs={6} key={`voucher-metal-itm-${itm}`}>
              <SavingsPlanCard
                onClick={() => input.onChange(itm)}
                className={input.value === itm ? classes.selectedCard : ''}
                content={{
                  metal: itm,
                }}
                isSelected={input.value === itm}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

const VoucherCard = ({ noLogin }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { mutators } = useForm()
  const [code, setCode] = useState('')
  const vouchers = useSelector(getBackendVoucherDataAllSuccess) ?? {}
  const getIsFetching = useSelector(isBackendVoucherFetching)
  const { gold, silver, platinum, palladium } =
    useSelector(getFormCreatePlanPageData('pageOne')) || {}

  const handleVoucherKeyUpPress = event => {
    if (code && event.key === 'Enter') {
      event.preventDefault()
      handleVerifyVoucher()
    }
  }

  const handleVerifyVoucher = () => {
    dispatch(
      fetchVerifyVoucherActions.requestAction({
        id: code.toLocaleLowerCase(),
        code,
        gold,
        silver,
        platinum,
        palladium,
        screen: VOUCHER_SCREEN.CREATE_PLAN,
        noLogin,
      })
    )
  }

  const handleRemove = id => () => {
    setCode('')
    dispatch(fetchVerifyVoucherActions.flushAction({ id }))
    mutators?.resetSelectedVoucherMetal(id)
  }

  return (
    <Box mb={2}>
      <Card>
        <CardContent>
          {Object.entries(vouchers).map(
            ([
              id,
              {
                data: { voucher, metals },
              },
            ]) => {
              return (
                <Grid container spacing={1} key={`voucher-grid-container-${id}`}>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <InfoTextField
                        value={voucher.code}
                        label={translate('createSavingsPlan.selected')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: voucher.text }} />
                  </Grid>
                  {!isEmpty(metals) && (
                    <Grid item xs={12}>
                      <Field
                        name={`metals.${id}`}
                        validate={required}
                        component={CustomCardFormField}
                        items={metals}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      className={classes.deleteButton}
                      fullWidth
                      onClick={handleRemove(id)}
                    >
                      {translate('actions.delete')}
                    </Button>
                  </Grid>
                </Grid>
              )
            }
          )}
          <Typography variant="h6" paragraph>
            {translate('createSavingsPlan.voucher')}
          </Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={9}>
              <TextField
                label={translate('createSavingsPlan.voucherCode')}
                onChange={event => setCode(event.target.value)}
                value={code}
                variant="outlined"
                onKeyPress={handleVoucherKeyUpPress}
                onKeyUp={handleVoucherKeyUpPress}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleVerifyVoucher}
                fullWidth
                disabled={getIsFetching(code) || !code}
              >
                {getIsFetching(code) ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  translate('actions.verify')
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box ml={1} mt={1}>
                <Typography variant="caption" color="textSecondary">
                  {translate('createSavingsPlan.voucherInfo')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default VoucherCard
