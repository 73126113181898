import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    width: '100%',
    height: '1rem',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
    borderRadius: 10,
  },
  root: {
    height: '100%',
    width: '100%',
    marginLeft: theme.spacing(0.4),
    marginRight: theme.spacing(0.4),
    backgroundColor: 'transparent',
    borderRadius: 10,
  },
  bar: {
    top: theme.spacing(0.25),
    bottom: theme.spacing(0.25),
    borderRadius: 20,
    backgroundColor: props => props.color,
  },
}))

const calculatePasswordSafety = (password, theme) => {
  if (!password) {
    return { color: theme.palette.error.main, value: 0 }
  }

  const strength =
    /.{8,}/.test(password) /* at least 8 characters */ *
    (/.{12,}/.test(password) /* bonus if longer */ +
      /[a-z]/.test(password) /* a lower letter */ +
      /[A-Z]/.test(password) /* a upper letter */ +
      /\d/.test(password) /* a digit */ +
      /[^A-Za-z0-9]/.test(password)) /* a special character */

  return {
    color:
      strength < 2
        ? theme.palette.error.main
        : strength < 4
        ? theme.palette.warning.main
        : theme.palette.success.main,
    value: strength * 19 + 5,
  }
}

const PasswordSafetyIndicator = ({ password, ...props }) => {
  const theme = useTheme()
  const { color, value } = calculatePasswordSafety(password, theme)
  const classes = useStyles({ color })

  return (
    <div className={classes.container}>
      <LinearProgress
        {...props}
        value={value}
        style={{ color: color }}
        classes={{
          root: classes.root,
          bar: classes.bar,
        }}
        variant="determinate"
      />
    </div>
  )
}

PasswordSafetyIndicator.propTypes = {
  password: PropTypes.string,
}

export default PasswordSafetyIndicator
