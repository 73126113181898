import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import useCurrency from '../../lib/customHooks/useCurrency'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'
import { formatGram } from '../../lib/miscellaneous'
import { useTranslate } from '../../lib/translate'
import { getBackendExchangeRatesData } from '../../redux/selectors'
import { METAL_ICON, PLAN_INACTIVE_STATUS, SAVINGS_PLAN_STATUS } from './../../config'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '10px',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    opacity: props => (props?.inactive ? 0.6 : 1),
    '& .MuiTypography-root > svg': {
      fontSize: '2rem',
      verticalAlign: 'middle',
    },
  },
  fixedHeight: {
    height: '11rem',
  },
  fixedHeightSm: {
    height: '9rem',
  },
  cardHeader: {
    padding: 0,
    position: 'relative',
    alignItems: 'flex-start',
  },
  cardContent: {
    padding: 0,
    display: 'flex',
    gap: '1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: props =>
      props.valueChange >= 0 ? theme.palette.success.main : theme.palette.error.main,
    [theme.breakpoints.down('xs')]: {
      gap: '0.5rem',
      flexWrap: 'wrap',
    },
  },
  noPadding: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonStyle: {
    width: '100% !important',
    maxWidth: '28rem',
    textAlign: 'unset',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: '0 0 3px #86754F',
    },
  },
  checkIcon: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(),
  },
  inactiveBanner: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) rotate(-15deg)',
  },
}))

const ValueChangeComponent = ({ valueChange, hasText = false }) => {
  const translate = useTranslate()
  if (!valueChange) {
    return null
  }
  return (
    <Box style={{ display: 'none' }}>
      {valueChange >= 0 ? (
        <Typography variant="h6" noWrap>
          <ArrowDropUpIcon />
          {`+${valueChange} % ${hasText ? translate('savingsPlanCard.valueIncrease') : ''}`}
        </Typography>
      ) : (
        <Typography variant="h6" noWrap>
          <ArrowDropDownIcon />
          {`${valueChange} % ${hasText ? translate('savingsPlanCard.valueDecrease') : ''}`}
        </Typography>
      )}
    </Box>
  )
}

const calcMetalPerformance = (metal, exchangeRates) => {
  const sortedArray = filter(exchangeRates, rate => rate.metalName?.toLowerCase() === metal).sort(
    (a, b) => new Date(b?.exchangeRateDate) - new Date(a?.exchangeRateDate)
  )
  const performance = (sortedArray[0]?.gramPriceCHF / sortedArray[1]?.gramPriceCHF - 1) * 100

  return isNaN(performance) ? '' : performance.toFixed(2)
}

const RequestSavingsPlanCard = ({ content, metalIcon, onClick, exchangeRates }) => {
  const translate = useTranslate()
  const { metal } = content || {}
  const isUnderaged = useIsUnderaged()

  const valueChange = isEmpty(exchangeRates)
    ? undefined
    : calcMetalPerformance(metal, exchangeRates)

  const classes = useStyles({ valueChange })

  return (
    <ButtonBase component="div" disableRipple className={classes.buttonStyle}>
      <Card className={`${classes.root} ${classes.fixedHeightSm}`}>
        <CardHeader
          className={classes.cardHeader}
          avatar={<Avatar aria-label="Asset" src={metalIcon} />}
          title={metal ? translate(`metals.${metal}`) : ''}
          titleTypographyProps={{ variant: 'h6' }}
        />
        <CardContent className={classes.cardContent}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={<AddIcon />}
            disabled={isUnderaged}
            onClick={onClick}
          >
            {translate('savingsPlanCard.openSavingsPlan')}
          </Button>
          <ValueChangeComponent valueChange={valueChange} />
        </CardContent>
      </Card>
    </ButtonBase>
  )
}

RequestSavingsPlanCard.propTypes = {
  content: PropTypes.object.isRequired,
  metalIcon: PropTypes.string,
}

RequestSavingsPlanCard.defaultProps = {
  metalIcon: undefined,
}

const ContentSavingsPlanCard = ({
  content,
  onClick,
  className,
  metalIcon,
  valueChange,
  hasPlan,
  isSelected,
  savingPlanLog,
}) => {
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const { value, metal, gram, name, customName, status, savingsRate } = content || {}
  const { newSavingPlanRate, changeDate } = savingPlanLog || {}
  const formattedChangeDate = moment(changeDate).format(translate('formFields.dateFormat'))
  const inactive = PLAN_INACTIVE_STATUS.includes(status)
  const classes = useStyles({
    valueChange,
    inactive,
  })
  const changePlanInfo =
    newSavingPlanRate !== undefined
      ? newSavingPlanRate === 0
        ? translate('dashboard.pausePlanned')
        : translate('dashboard.newRateValue', {
            value: displayCurrency(newSavingPlanRate),
          })
      : undefined

  const CustomCardHeader = () => (
    <CardHeader
      className={classes.cardHeader}
      disableTypography
      avatar={<Avatar aria-label="Asset" src={metalIcon} />}
      title={
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="caption" color="textSecondary">
              {`${hasPlan ? translate(`metals.${metal}`) : ''}${customName ? ` - ${name}` : ''}`}
            </Typography>
            {hasPlan && (
              <Typography color="textSecondary" variant="caption">
                {translate('createSavingsPlan.savingsRate')}
              </Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" fontWeight="fontWeightBold">
            <Typography noWrap>
              {hasPlan ? customName || name : translate(`metals.${metal}`)}
            </Typography>
            {hasPlan && (
              <Box textAlign="right">
                <Typography noWrap>{displayCurrency(savingsRate)}</Typography>
                {changePlanInfo && (
                  <>
                    <Typography noWrap color="textSecondary" variant="caption">
                      {changePlanInfo}
                    </Typography>
                    <Typography noWrap color="textSecondary" variant="caption" component="p">
                      {translate('dashboard.fromDate', { date: formattedChangeDate })}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      }
    />
  )

  return (
    <ButtonBase className={`${classes.buttonStyle} ${className}`} onClick={onClick}>
      <Card className={`${classes.root} ${classes.fixedHeight}`}>
        <CustomCardHeader />
        <CardContent className={classes.noPadding}>
          {hasPlan && (
            <Box display="flex" justifyContent="space-between">
              <Typography variant="caption" color="textSecondary">
                {translate('savingsPlanCard.depositValue')}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {translate('savingsPlanCard.gramValue')}
              </Typography>
            </Box>
          )}
          <Box className={classes.cardContent}>
            {hasPlan && (
              <>
                <Typography variant="h6" color="textPrimary" className={classes.boldText} noWrap>
                  {displayCurrency(value)}
                </Typography>
                <Typography variant="h6" color="textPrimary" noWrap>
                  {formatGram(gram)}
                </Typography>
              </>
            )}
            <ValueChangeComponent hasText={!hasPlan} valueChange={valueChange} />
          </Box>
          {isSelected && <CheckCircleOutlinedIcon className={classes.checkIcon} />}
        </CardContent>
        {inactive && (
          <Typography
            className={classes.inactiveBanner}
            variant={status === SAVINGS_PLAN_STATUS.PAUSED_NO_FEE ? 'h4' : 'h3'}
            color="textSecondary"
          >
            {translate(`planStatus.${status}`)}
          </Typography>
        )}
      </Card>
    </ButtonBase>
  )
}

ContentSavingsPlanCard.propTypes = {
  content: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  metalIcon: PropTypes.string,
}

const SavingsPlanCard = ({
  content,
  button,
  onClick,
  className,
  isSelected = false,
  exchangeRates,
  savingPlanLog,
}) => {
  const { id, metal } = content
  const metalIcon = METAL_ICON[metal]
  const hasPlan = !!id

  if (button) {
    return (
      <RequestSavingsPlanCard
        {...{
          content,
          onClick,
          metalIcon,
          hasPlan,
          exchangeRates,
        }}
      />
    )
  }
  return (
    <ContentSavingsPlanCard
      {...{ content, onClick, className, metalIcon, hasPlan, isSelected, savingPlanLog }}
      valueChange={content.valueChange}
    />
  )
}

SavingsPlanCard.propTypes = {
  content: PropTypes.shape({
    metal: PropTypes.string.isRequired,
    valueChange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  button: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

SavingsPlanCard.defaultProps = {
  button: false,
  onClick: null,
  className: null,
  content: {},
}

const mapStateToProps = state => ({
  exchangeRates: getBackendExchangeRatesData(state),
})

export default connect(mapStateToProps)(SavingsPlanCard)
