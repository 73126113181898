import { Box, Button, Card, CardContent, CardMedia, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'

import { CustomSaveButton, DownloadInstructions, FormLayout } from '..'
import cancelPreview from '../../assets/images/aenderungsantrag.png'
import { REQUEST_RECORD_TYPE, ROUTES } from '../../config'
import { useTranslate } from '../../lib/translate'
import useSnackbar from '../../lib/useSnackbar'
import {
  fetchCustomFilesActions,
  fetchDownloadFileActions,
  fetchRequestsActions,
} from '../../redux/actions'
import {
  getBackendCountryDepartmentData,
  getBackendCustomFilesData,
  getBackendDownloadFileData,
  getBackendRequestsData,
  isBackendDownloadFileFetching,
} from '../../redux/selectors'
import payoutPreview from './../../assets/images/auszahlungsantrag.png'
import createPreview from './../../assets/images/erstellungsantrag.png'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(5),
  },
  cardImage: {
    height: '15.625rem',
    objectFit: 'contain',
  },
  uploadBox: {
    border: '1px dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: '8px',
    height: '3.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadButton: {
    height: '100%',
    color: theme.palette.secondary.main,
  },
  deleteButton: {
    borderRadius: 0,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  marginRight: {
    marginRight: theme.spacing(),
  },
}))

const FILES_TO_DOWNLOAD = {
  PEP: 'PEP',
  REQUEST: 'request',
  BGB: 'BGB',
  PRICES: 'prices',
}

const getPageTitle = request => {
  switch (request?.recordTypeId) {
    case REQUEST_RECORD_TYPE.PAYOUT.id:
      return request?.physicalDelivery
        ? 'completeRequestPage.shippingRequestTitle'
        : 'completeRequestPage.transferRequestTitle'
    case REQUEST_RECORD_TYPE.CANCEL.id:
      return 'completeRequestPage.cancelTitle'
    default:
      return 'completeRequestPage.title'
  }
}

const getPreviewForRequestType = requestRecordTypeId => {
  switch (requestRecordTypeId) {
    case REQUEST_RECORD_TYPE.CANCEL.id:
      return cancelPreview
    case REQUEST_RECORD_TYPE.PAYOUT.id:
      return payoutPreview
    default:
      return createPreview
  }
}

const CompleteRequestPage = ({
  customFiles,
  requests,
  isDownloading,
  downloadSuccess,
  fetchDownloadFile,
  fetchCustomFiles,
  fetchRequests,
  countryDepartment,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const showSnackbar = useSnackbar()

  const { requestId } = useParams()

  const requestFileId = get(find(customFiles, { request: requestId }), 'id')
  const request = find(requests, { id: requestId })
  const isCreate = request?.recordTypeId === REQUEST_RECORD_TYPE.CREATE.id
  const isPayout = request?.recordTypeId === REQUEST_RECORD_TYPE.PAYOUT.id

  const handleDownload = (id, type) => () => {
    if (id) {
      switch (type) {
        case FILES_TO_DOWNLOAD.BGB:
          fetchDownloadFile({ id, name: translate('fileName.bgb') })
          break
        case FILES_TO_DOWNLOAD.PRICES:
          fetchDownloadFile({ id, name: translate('fileName.prices') })
          break
        case FILES_TO_DOWNLOAD.REQUEST:
          fetchDownloadFile({ id, request: requestId, name: translate('fileName.request') })
          break
        default:
          break
      }
    } else {
      showSnackbar('notification.pdfNotFound', 'error')
    }
  }

  const onSubmit = values => {
    if (isPayout) {
      history.push(ROUTES.DASHBOARD)
    }
  }

  useEffect(() => {
    fetchCustomFiles()
  }, [fetchCustomFiles])

  useEffect(() => {
    if (!find(requests, { id: requestId })) {
      fetchRequests()
    }
  }, [requests, requestId, fetchRequests])

  return (
    <FormLayout title={translate(getPageTitle(request))} wideLayout showClose isSignedIn>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Box my={10}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                  <Box mb={3}>
                    <CardMedia
                      component="img"
                      alt="philoro"
                      image={getPreviewForRequestType(request?.recordTypeId)}
                      title={translate('createSavingsPlan.pdfPreview')}
                      className={classes.cardImage}
                    />
                  </Box>
                  {isCreate && (
                    <>
                      <Box textAlign="center">
                        <CustomSaveButton
                          fullWidth
                          component={Link}
                          to={`${ROUTES.PRIVACY_INFO}?country=${countryDepartment?.countryCode}`}
                          target="_blank"
                          variant="text"
                        >
                          {translate('createSavingsPlan.privacyInfo')}
                        </CustomSaveButton>
                      </Box>
                      <Box my={2}>
                        <CustomSaveButton
                          fullWidth
                          component={Link}
                          to={`${ROUTES.BGB}?country=${countryDepartment?.countryCode}`}
                          target="_blank"
                          variant="text"
                          type="button"
                        >
                          {translate('createSavingsPlan.downloadBGB')}
                        </CustomSaveButton>
                      </Box>
                      <Box my={2}>
                        <CustomSaveButton
                          fullWidth
                          onClick={handleDownload(
                            countryDepartment?.priceListFileId,
                            FILES_TO_DOWNLOAD.PRICES
                          )}
                          loading={isDownloading}
                          variant="text"
                          type="button"
                        >
                          {translate('createSavingsPlan.downloadPrices')}
                        </CustomSaveButton>
                      </Box>
                    </>
                  )}
                  <CustomSaveButton
                    fullWidth
                    onClick={handleDownload(requestFileId, FILES_TO_DOWNLOAD.REQUEST)}
                    loading={isDownloading}
                    variant="text"
                    type="button"
                  >
                    {isPayout
                      ? translate('createSavingsPlan.confirmationDownload')
                      : translate('createSavingsPlan.requestDownload')}
                  </CustomSaveButton>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Card>
                    <CardContent className={classes.cardContentPadding}>
                      <DownloadInstructions />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" mb={5}>
              <Button variant="text" onClick={() => history.push(ROUTES.REQUESTS)}>
                {translate('actions.backToRequests')}
              </Button>
              <CustomSaveButton
                disabled={!isPayout && !downloadSuccess}
                onClick={() => history.push(ROUTES.DASHBOARD)}
              >
                {translate('actions.end')}
              </CustomSaveButton>
            </Box>
          </form>
        )}
      />
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  isDownloading: isBackendDownloadFileFetching(state),
  customFiles: getBackendCustomFilesData(state),
  requests: getBackendRequestsData(state),
  countryDepartment: getBackendCountryDepartmentData(state),
  downloadSuccess: !!getBackendDownloadFileData(state),
})

const mapDispatchToProps = {
  fetchDownloadFile: fetchDownloadFileActions.requestAction,
  fetchCustomFiles: fetchCustomFilesActions.requestAction,
  fetchRequests: fetchRequestsActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteRequestPage)
