import React, { useEffect, useState } from 'react'
import { Container, makeStyles, Paper } from '@material-ui/core'
import { getBackendCountryDepartmentByCountryCodeData } from '../../../redux/selectors'
import { fetchCountryDepartmentByCountryCodeActions } from '../../../redux/actions'
import { connect } from 'react-redux'
import { COUNTRY_DEPARTMENT } from '../../../config'

const useStyles = makeStyles(theme => ({
  pdf: {
    height: '100vh',
    width: '100%',
    border: 'none'
  },
  container: {
    padding: 0,
    overflow: 'hidden'
  },
  paper: {
    height: '100vh'
  }
}))

const PrivacyPolicyPage = ({
  fetchCountryDepartment,
  countryDepartment
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
      fetchCountryDepartment({ countryCode: COUNTRY_DEPARTMENT.SWITZERLAND.value })
      setIsLoading(false)
  }, [fetchCountryDepartment])

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper className={classes.paper}>
        {!isLoading && (<iframe title="BGB" src={countryDepartment?.BGBFileUrl} className={classes.pdf}></iframe>)}
      </Paper>
    </Container>
  )
}

const mapStateToProps = state => ({
  countryDepartment: getBackendCountryDepartmentByCountryCodeData(state),
})

const mapDispatchToProps = {
  fetchCountryDepartment: fetchCountryDepartmentByCountryCodeActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyPage)
