import { Grid, Table, TableBody, TableHead, TablePagination, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import filter from 'lodash/filter'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { SkeletonTable } from '../..'
import { METAL_TYPE, SAVINGS_PLAN_STATUS } from '../../../config'
import useCurrency from '../../../lib/customHooks/useCurrency'
import { formatGram } from '../../../lib/miscellaneous'
import { useTranslate } from '../../../lib/translate'
import { StyledTab, StyledTableCell, StyledTableContainer, StyledTabs } from './styled'

const useStyles = makeStyles(theme => ({
  tableRow: {
    '& > *': {
      border: 'unset',
    },
  },
  tableRowInActive: {
    opacity: 0.4,
  },
  customHeadShadow: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
    '& th:first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& th:last-child': {
      borderRadius: '0 10px 10px 0',
    },
  },
}))

const CustomTableRow = ({ item }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const {
    customName,
    name,
    metalName,
    savingsRate,
    savingsPlanStart,
    depositAmount,
    gram,
    currentValue,
    status,
  } = item || {}

  const nextDirectDebit =
    moment().diff(moment(savingsPlanStart)) > 0
      ? moment().add(1, 'M').startOf('month').format(translate('formFields.dateFormat'))
      : moment(savingsPlanStart).startOf('month').format(translate('formFields.dateFormat'))

  const isActive = status === SAVINGS_PLAN_STATUS.ACTIVE

  return (
    <TableRow className={`${classes.tableRow} ${!isActive ? classes.tableRowInActive : ''}`}>
      <StyledTableCell>{customName ?? name}</StyledTableCell>
      <StyledTableCell align="right">
        {translate(`metals.${metalName?.toLowerCase()}`)}
      </StyledTableCell>
      <StyledTableCell align="right">{displayCurrency(savingsRate)}</StyledTableCell>
      <StyledTableCell align="right">{nextDirectDebit}</StyledTableCell>
      <StyledTableCell align="right">{displayCurrency(depositAmount)}</StyledTableCell>
      <StyledTableCell align="right">{formatGram(gram)}</StyledTableCell>
      <StyledTableCell align="right">{displayCurrency(currentValue)}</StyledTableCell>
      <StyledTableCell align="right">{status && translate(`planStatus.${status}`)}</StyledTableCell>
    </TableRow>
  )
}

const SavingPlansTable = ({ data, loading }) => {
  const translate = useTranslate()
  const classes = useStyles()

  const [tableData, setTableData] = useState(data)
  const [tabValue, setTabValue] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleTabChange = (event, newValue) => {
    setPage(0)
    setTabValue(newValue)
    if (newValue === 0) {
      setTableData(data)
    } else if (newValue === 1) {
      setTableData(filter(data, plan => plan.metalName?.toLowerCase() === METAL_TYPE.GOLD))
    } else if (newValue === 2) {
      setTableData(filter(data, plan => plan.metalName?.toLowerCase() === METAL_TYPE.SILVER))
    }
  }

  useEffect(() => {
    setTableData(data)
  }, [data])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledTabs value={tabValue} onChange={handleTabChange}>
          <StyledTab label={translate('table.tabAll')} />
          <StyledTab label={translate('metals.gold')} />
          <StyledTab label={translate('metals.silver')} />
        </StyledTabs>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <StyledTableContainer>
              <Table>
                <TableHead className={classes.customHeadShadow}>
                  <TableRow>
                    <StyledTableCell>{translate('table.name')}</StyledTableCell>
                    <StyledTableCell align="right">{translate('table.metal')}</StyledTableCell>
                    <StyledTableCell align="right">{translate('table.rate')}</StyledTableCell>
                    <StyledTableCell align="right">
                      {translate('table.directDebit')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {translate('table.depositAmount')}
                    </StyledTableCell>
                    <StyledTableCell align="right">{translate('table.amountGram')}</StyledTableCell>
                    <StyledTableCell align="right">
                      {translate('table.currentValue')}
                    </StyledTableCell>
                    <StyledTableCell align="right">{translate('table.status')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(tableData) &&
                    tableData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, i) => <CustomTableRow key={`table-row-${i}`} item={item} />)}
                </TableBody>
              </Table>
            </StyledTableContainer>
            {tableData && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={tableData?.length || 0}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={translate('table.rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from} - ${to} ${translate('table.of')} ${count}`
                }
                page={page}
                onChangePage={(event, newPage) => setPage(newPage)}
                onChangeRowsPerPage={event => {
                  setRowsPerPage(event.target.value)
                  setPage(0)
                }}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default SavingPlansTable
